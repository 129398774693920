import * as React from 'react';

import { Feedback } from '../../model/feedback';
import Button from '../base/Button';
import './FoundIdeaBelowDeck.scss';
import { UserSex } from '../../model/gender';
import { useSimpleTranslation } from '../hooks/Translator';
import { LocaleContent } from '../../i18n/locales/types';

type IsGoodAnswer = 'yes' | 'no' | 'almost';

type Props = {
    searchSex: UserSex,
    asQuestion: boolean,
    onPickFirstAnswer: (v: IsGoodAnswer | null) => void,
    onPickFeedback: (f: Feedback) => void,
    onRequireLargeHeight: (isLarge: boolean) => void,
};

function getFeedbackLabel(t: LocaleContent, sex: UserSex, k: Feedback, firstAnswer: IsGoodAnswer, ) {
    if (k === 'HasAlready') {
        const f = t.search.foundIdea.feedback.hasAlready;
        switch (firstAnswer) {
            case 'almost': return f.afterClose[sex];
            case 'yes': return f.afterGood[sex];
            case 'no': return f.afterBad[sex];
        }
    } else {
        return t.search.foundIdea.feedback.labels[k];
    }
}

function renderFeedback(t: LocaleContent, sex: UserSex, onPickFeedback: (f: Feedback) => void, f: Feedback, firstAnswer: IsGoodAnswer) {
    return <Button key={f} label={getFeedbackLabel(t, sex, f, firstAnswer)} type="neutral" filled={true} onClick={() => onPickFeedback(f)}/>;
}

function renderFirstStep(t: LocaleContent, setFirstAnswer: (v: IsGoodAnswer) => void) {
    // We use a non breaking-space in the translated text, it comes from the translation and not a user input so it is safe to use dangerouslySetInnerHTML.
    return <>
        <div className="idea-feedback-explanation" dangerouslySetInnerHTML={{__html: t.search.foundIdea.feedback.header}}/>
        <div className="feedback-button-row">
            <Button label={t.search.foundIdea.feedback.bad.label} type={'negative'} filled={true} onClick={() => {setFirstAnswer('no')}} />
            <Button label={t.search.foundIdea.feedback.close.label} type={'neutral'} filled={true} onClick={() => {setFirstAnswer('almost')}} />
            <Button label={t.search.foundIdea.feedback.good.label} type={'positive'} filled={true} onClick={() => {setFirstAnswer('yes')}} />
        </div>
        <div className="idea-feedback-explanation">{t.search.foundIdea.feedback.footer}</div>
    </>
}

function getSecondStepHeader(t: LocaleContent, firstAnswer: IsGoodAnswer): string {
    switch (firstAnswer) {
        case 'yes': return t.search.foundIdea.feedback.good.header;
        case 'almost': return t.search.foundIdea.feedback.close.header;
        case 'no': return t.search.foundIdea.feedback.bad.header;
    }
}

function getSecondStepFeedbackList(firstAnswer: IsGoodAnswer): Feedback[] {
    switch (firstAnswer) {
        case 'yes': return ['YouCanDoBetter', 'HasAlready', 'GoodIdea'];
        case 'almost': return ['YouAreClose', 'HasAlready'];
        case 'no': return ['BadIdea', 'InconsistentIdea', 'HasAlready'];
    }
}

function renderSecondStep(t: LocaleContent, sex: UserSex, onPickFeedback: (f: Feedback) => void, setFirstAnswer: (v: IsGoodAnswer | null) => void, firstAnswer: IsGoodAnswer) {
    return <>
    <div>{getSecondStepHeader(t, firstAnswer)}</div>
    <div className="buttons-column">
        {getSecondStepFeedbackList(firstAnswer).map(f => renderFeedback(t, sex, onPickFeedback, f, firstAnswer))}
    </div>
    <div className="link" onClick={() => setFirstAnswer(null)}>{t.search.foundIdea.feedback.back}</div>
    </>;
}

function renderAsAQuestionFeedback(t: LocaleContent, onPickFeedback: (f: Feedback) => void) {
    return <>
        <div>{t.search.foundIdea.feedback.aaq.header}</div>
        <div className="buttons-row">
            <Button label={t.search.foundIdea.feedback.aaq.bad} type="negative" filled={true} onClick={() => onPickFeedback('BadIdea')}/>
            <Button label={t.search.foundIdea.feedback.aaq.iDontKnow} type="neutral" filled={true} onClick={() => onPickFeedback('IDontKnow')}/>
            <Button label={t.search.foundIdea.feedback.aaq.good} type="positive" filled={true} onClick={() => onPickFeedback('GoodIdea')}/>
        </div>
    </>;
}

const FoundIdeaFeedback: React.FC<Props> = ({onPickFirstAnswer, onRequireLargeHeight, searchSex, onPickFeedback, asQuestion}) => {
    const [firstAnswer, setFirstAnswerInState] = React.useState<IsGoodAnswer | null>(null);
    const t = useSimpleTranslation();
    const setFirstAnswer = (v: IsGoodAnswer | null) => {
        setFirstAnswerInState(v);
        onPickFirstAnswer(v);
        const isLarge = v !== null;
        onRequireLargeHeight(isLarge);
    };
    if (asQuestion) {
        return renderAsAQuestionFeedback(t, onPickFeedback);
    }else if (firstAnswer === null) {
        return renderFirstStep(t, setFirstAnswer);
    } else {
        return renderSecondStep(t, searchSex, onPickFeedback, setFirstAnswer, firstAnswer);
    }
}

export default FoundIdeaFeedback;