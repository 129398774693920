import * as React from 'react';
import {ComponentType, ComponentSpec} from 'react';
import createReactClass from 'create-react-class';

import translator, { TranslatePath } from './Translator';
import { ShortCutMap } from '../model/question';
import { Subtract, getDisplayName } from '../util/HOC';

export type TranslateSimpleFn = (path: TranslatePath) => string;
export type TranslateFn = (path: TranslatePath, data: any) => string;

export type PropsFromI18N = {
    translate: TranslateSimpleFn,
    translateFull: TranslateFn,
    formatDate: (d: Date, options?: Intl.DateTimeFormatOptions) => string,
    getShortcuts: () => ShortCutMap,
}

function wrapI18N<P extends object>(Component: ComponentType<P>) : ComponentType<Subtract<P, PropsFromI18N>>{
    const translate = (path: TranslatePath) => translator.translate(path);
    const translateFull = (path: TranslatePath, data: any) => translator.translateData(path, data);
    const formatDate = (d: Date, options: Intl.DateTimeFormatOptions) => translator.formatDate(d, options);
    const getShortcuts = () => translator.getData(l => l.question.shortcuts);
    const spec: ComponentSpec<Subtract<P, PropsFromI18N>, {}> = {
        displayName: `TranslatorHOC(${getDisplayName(Component)})`,

        // LifeCycle
        componentDidMount() {
            translator.addListener(this.onLocaleChange);
        },
        componentWillUnmount() {
            translator.removeListener(this.onLocaleChange);
        },

        // Event handling

        onLocaleChange() {
            this.forceUpdate();
        },

        // Rendering
        render() {
            return <Component {...this.props}
            translate={translate}
            translateFull={translateFull}
            formatDate={formatDate}
            getShortcuts={getShortcuts}/>;
        }
    };
    return createReactClass<Subtract<P, PropsFromI18N>, {}>(spec);
};

export default wrapI18N;