import { TranslateFn, TranslateSimpleFn } from "../i18n/TranslatorHOC";

// Ages always contain durations in months.
export const monthsInAYear = 12;
export const ideaAgeMin = 0;
export const ideaAgeMax = 100 * monthsInAYear;
export const allowSearchChildrenGifts = false;
const searchAgeMinForExplanation = 15 * monthsInAYear;
export const searchAgeMin = allowSearchChildrenGifts ? 0 : 16 * monthsInAYear;
export const searchAgeMinReachable = allowSearchChildrenGifts ? 0 : searchAgeMinForExplanation;
export const searchAgeMax = 100 * monthsInAYear;
export const searchDefaultAge = 30 * monthsInAYear;

const yearsMarks = [1, 2, 6, 12, 18, 25, 30, 40, 50, 60, 70];
const monthsMarks = [1, 6, 18];

export function getAgeStr(age: number, translate: TranslateFn, translateSimple: TranslateSimpleFn) {
    if (age === 0) {
        return translateSimple(l => l.search.age.notBorn);
    }
    const isYear = age % monthsInAYear === 0;
    if (isYear) {
        return translate(l => l.search.age.years, {n: age / monthsInAYear});
    } else {
        return translate(l => l.search.age.months, {n: age});
    }
}

export function getAgeMark(age: number, translate: TranslateFn,) {
    if (age === 0) {
        return '0';
    }
    const isYear = age % monthsInAYear === 0;
    if (isYear) {
        return translate(l => l.search.age.yearMark, {n: age / monthsInAYear});
    } else {
        return translate(l => l.search.age.monthMark, {n: age});
    }
}

// From pos 0 to 24 (inclusive) we use months and after years
// After year 50 we increase years 5 by 5.
const posLimitMonth = 24;
const yearLimitMonth = 2;
const yearLimitYear = 55;
const posLimitYear = posLimitMonth + yearLimitYear - yearLimitMonth;
const factorAbove = 5;
export function posToAge(pos: number) {
    if (pos <= posLimitMonth) {
        return pos;
    }
    if (pos <= posLimitYear) {
        return (pos - posLimitMonth + yearLimitMonth) * monthsInAYear;
    } else {
        return ((pos - posLimitYear) * factorAbove + yearLimitYear) * monthsInAYear;
    }
}

export function ageToPos(age: number) {
    if (age <= posLimitMonth) {
        return age;
    }
    const years = Math.floor(age / monthsInAYear);
    if (years <= yearLimitYear) {
        return years - yearLimitMonth + posLimitMonth; 
    }
    return Math.floor((years - yearLimitYear) / factorAbove) + posLimitYear;
}

export function getSliderAgeMarks(ageMin: number, translate: TranslateFn) {
    const all: {[key: number]: string} = {};
    if (ageMin < monthsInAYear ) {
       monthsMarks.forEach(month => {
           all[ageToPos(month)] = translate(l => l.search.age.monthMark, {n: month});
       })
    }
    yearsMarks.forEach(year => {
        if (year * monthsInAYear >= ageMin) {
            all[ageToPos(year * monthsInAYear)] = `${year}`;
        }
    });
    return all;
}


export function ageTipFormatter(agePos: number, maxAge: number, translateFull: TranslateFn, translateSimple: TranslateSimpleFn) {
    const age = posToAge(agePos);
    if (age === searchAgeMax) {
        return translateFull(l => l.search.start.ageMaxFormatter, {years: age /monthsInAYear});
    } else {
        return getAgeStr(age, translateFull, translateSimple);
    }
}
if (false) {
    for (let age of [...[0, 1, 2, 6, 23, 24], ...[10, 40, 54, 55, 60, 70, 80, 100].map(y => y * monthsInAYear)]) {
        const check = posToAge(ageToPos(age));
        if (check !== age) {
            console.log('age', age, 'check', check, 'pos', ageToPos(age));
        }
    }
}
