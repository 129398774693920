import * as React from 'react';
import { coalesce } from '../../util/common';
import './AnimatedPlaceholderInput.scss';
import If from './If';

type Stage = 'begin' | 'mid' | 'end';

let effectTimeout: NodeJS.Timeout | null = null;

const fractionPeriodInTransition = 0.125;

const AnimatedPlaceholderInput: React.FC<{
    value: string,
    onChange: (s: string) => void,
    placeholders: string[],
    periodMs: number,
    innerRef?: React.Ref<HTMLInputElement>,
    className?: string,
    style?: React.CSSProperties,
    inputStyle?: React.CSSProperties,
}> = ({placeholders, periodMs, value, className, onChange, style, inputStyle, innerRef}) => {
    const [indexAndStage, setIndexAndStage] = React.useState<[number, Stage]>([0, 'begin']);
    React.useEffect(() => {
        if (effectTimeout != null) {
            clearTimeout(effectTimeout);
        }
        const [index, stage] = indexAndStage;    
        if (stage === 'end') {
            effectTimeout = setTimeout(() => {
                setIndexAndStage([(index + 1) % placeholders.length, 'begin']);
            }, periodMs * fractionPeriodInTransition);
        }
        if (stage === 'mid') {
            effectTimeout = setTimeout(() => {
                setIndexAndStage([index, 'end']);
            }, periodMs * ( 1 - 2 * fractionPeriodInTransition));
        }
        if (stage === 'begin') {
            effectTimeout = setTimeout(() => {
                setIndexAndStage([index, 'mid']);
            }, periodMs * fractionPeriodInTransition );
        }
        return () => {
            if (effectTimeout != null) {
                clearTimeout(effectTimeout);
            }
        }
    }, [placeholders, indexAndStage, periodMs]);
    const [index, stage] = indexAndStage;   
    return <div className={`animated-placeholder-input ${coalesce(className, '')}`} style={style}>
    <input type="text" className={`animated-placeholder-input-input`} value={value} onChange={e => onChange(e.target.value)} style={inputStyle} ref={innerRef}/>
    <If test={value === ''}>
        <span className={`animated-placeholder-input-placeholder animated-placeholder-input-placeholder-${stage}`}>{placeholders[index]}</span>
    </If>
    </div>;
};

export default AnimatedPlaceholderInput;