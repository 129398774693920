import { LocaleContent } from './types';

const fr: LocaleContent = {
    common: {
        dateLocaleBcp47: 'fr-FR',
        yes: 'Oui',
        no: 'Non',
        loading: 'Chargement…',
        errors: {
            applicationError: 'Erreur server. Réessaye plus tard.',
            accessDenied: 'Accès refusé',
            networkError: 'Impossible de contacter le serveur, as-tu internet ?',
            genericUserError: 'Oups il y a eu un petit souci, peux-tu recommencer ?',
        },
        refreshTitle: 'Rafraîchir',
        durations: {
            shortMilliSecond: 'ms',
            shortSecond: 's',
            shortMinute: 'mn',
            shortHour: 'h',
            shortDay: 'j',
        },
        submit: 'Envoyer',
    },
    account: {
        creation: {
            emailHeader: 'Adresse e-mail',
            header: 'Création de compte',
            passwordHeader: 'Mot de passe',
            passwordPlaceholder: 'Min 8 caractères',
            submitLabel: 'Créer le compte',
            validation: {
                failureHeader: 'Oops, impossible de créer le compte.',
                pendingText: 'Création en cours…',
                successText: 'Compte créé ! Redirection…',
                goToCreationLabel: 'Recommencer',
            },
            emailSentMessage: 'Parfait ! Un email t\'a été envoyé pour que tu confirmes ton adresse.',
        },
        signin: {
            linkLabel: 'Se connecter',
            emailHeader: 'E-mail',
            failed: 'Identifiants incorrects, merci de réessayer',
            header: 'Connexion',
            passwordHeader: 'Mot de passe',
            submitLabel: 'Se connecter',
            waitingForServerResponse: 'Connexion en cours…',
        },
        exitLabel: 'Se déconnecter',
    },
    blog: {
        linkToLabel: 'Blog',
    },
    contrib: {
        home: {
            presentation: 'Tu as des idées de cadeaux que tu veux partager ? Des idées de questions à poser ? Ou tu sais juste quelle idée de cadeau plairait en fonction des réponses ? Aide nous à améliorer nos recommendations ici.',
            accountExplanation: 'Cette section du site requiert la création d\'un compte de façon à assurer la qualité des contributions.',
        },
        reactions: {
            add: {
                linkPresentation: 'Tu sais à qui quelle idée de cadeau plaît ou tu es expert(e) sur certaines questions ? Aide nous à classifier les idées de cadeaux.',
                linkLabel: 'Donner des réactions',
                title: 'Donner des réactions',
                questionPickHeader: 'Cherches une question et clique sur celle pour laquelle tu as le plus de connaissances. On te présenteras ensuite plusieurs idées de cadeaux et tu nous dira si elles plairaient ou non en fonction de la réponse à cette question.',
                questionHeader: 'La question étant: ',
                reactionsCountSentence: '{countGiven} réactions données, {countShown} affichées',
                displayWithReactionToggleLabel: 'Afficher les idées pour lesquelles j\'ai déjà réagi.',
                ideasSource: {
                    header: 'Voir les idées:',
                    switchToLeastReactionsLabel: 'Peu renseignées',
                    switchToSuggestionsLabel: 'Suggérées',
                    switchToSearchLabel: 'Par mot clé',
                    searchLabelPrefix: 'Mot clé:',
                }
            },
            label: {
                NoIsBetter: 'Non est mieux',
                NoIsDesired: 'Non est demandé',
                NoIsNeeded: 'Non est nécessaire',
                None: 'Aucun lien',
                YesIsBetter: 'Oui est mieux',
                YesIsDesired: 'Oui est demandé',
                YesIsNeeded: 'Oui est nécessaire'
            },
            strenghts: {
                Normal: 'normal',
                Low: 'faible',
                High: 'fort',
                None: 'nul',
            },
            suggestionExplanationBeforeReaction: 'Est-ce que cette idée devrait être proposée lorsque l\'utilisateur répond',
            suggestionExplanationBeforeReactionForTag: 'Est-ce que cette idée doit être marquée',
            suggestionExplanationBetweenReactionAndQuestion: 'à la question',

        },
        ideas: {
            add: {
                linkPresentation: 'Tu connais des idées de cadeaux que tu as déniché sur un site ou dans ton imagination ?',
                linkLabel: 'Ajouter des idées de cadeaux',
                presentation: 'Tu peux ajouter des idées de cadeaux ici. Ces idées seront triées automatiquement et manuellement avant d\'être accessible à tous les utilisateurs.',
                generics: {
                    ageHeader: 'Pour quel âge ?',
                    contentHeader: 'Description',
                    linkPresentation: 'Tu veux ajouter une idée de cadeau que tu peux décrire mais qui ne correspond pas forcément à un produit précis ? Comme par exemple "un dîner dans un train" ?',
                    linkLabel: 'Alors va ici',
                    title: 'Idée de cadeau',
                    priceHeader: 'Prix',
                    priceTipFormatter: '{price} €',
                    submitLabel: 'Soumettre'
                },
                merchants: {
                    linkPresentation: 'Cette idée de cadeau est un produit disponible sur un site en ligne ?',
                    linkLabel: 'Entre l\'adresse de ce produit ici',
                    title: 'Ajouter une idée via son URL',
                    disclaimer: 'Le contenu de cette adresse sera analysée automatiquement et manuellement. Cette opération peut prendre du temps notamment si nous ne connaissions pas auparavant le site ajouté. Ce site doit effectuer des livraisons dans les régions couvertes par Nebuly pour être valide. Tu peux très bien ajouter ton propre site si tu proposes des produits pouvant être de bonnes idées de cadeaux.',
                    submitLabel: 'Soumettre ce produit',
                    urlHeader: 'Url du produit',
                    idHeader: 'Id',
                    merchantHeader: 'Marchand',
                    titleHeader: 'Titre',
                    descriptionHeader: 'Description',
                    imageHeader: 'Image',
                },
            },
            search: {
                linkLabel: 'Chercher',
                header: 'Recherche d\'idée',
                resultsHeader: 'Résultats',
                text: {
                    placeholder: 'Contenant',
                    includeDescriptionLabel: 'Desc',
                    includeDescriptionTitle: 'Aussi chercher dans la description',
                    revertLabel: 'Inv',
                    revertTitle: 'Ne montrer que les idées qui ne contiennent pas ce texte',
                    addLabel: 'texte',
                },
                availability: {
                    notAvailablePrefix: 'In',
                    availableSuffix: 'Disponibles',
                },
                affiliated: {
                    addLabel: 'affiliation',
                    header: 'Affiliation',
                },
                questions: {
                    addLabel: 'Ajouter question',
                    searchLabel: 'Chercher',
                    reaction: {

                    }
                },
                reactionsCount: {
                    label: '#Réactions',
                    addLabel: '#réactions',
                },
                merchant: {
                    label: 'Marchand',
                    addLabel: 'marchand',
                },
                doesntHaveNeededWeight: {
                    label: 'NA!',
                    addLabel: 'NA!',
                },
                addedDate: {
                    label: 'Ajouté',
                    addLabel: 'Date',
                },
                hasCloseIdea: {
                    addLabel: 'CI',
                    beforeRange: 'Idées ayant [',
                    betweenRange: ',',
                    betweenRangeAndDistance: '] idées proche de',
                    betweenDistanceAndPrefilter: 'de cette idée et contenant dans leur titre',
                },
                relation: {
                    addLabel: 'Relations',
                    any: 'Oui',
                    none: 'Non',
                    header: 'Relation',
                },
                appearedInSessions: {
                    addLabel: 'Apparence',
                    header: 'Apparu',
                    between: 'fois les',
                    after: 'jours',
                },
                showRatingCriteriaLabel: 'note',
                showPriceCriteriaLabel: 'prix',
                limitLabel: 'limite',
            },
            enrich: {
                title: 'Idée',
                header: 'Amélioration d\'idée',
                linkLabel: 'Améliorer une idée',
                linkPresentation: 'Tu veux ajouter des informations sur une idée de cadeau ?',
                descriptionHeader: 'Description',
                priceHeader: 'Prix',
                nameHeader: 'Nom',
                contentHeader: 'Contenu',
                ageHeader: 'Tranche d\'âge',
                feedbackHeader: 'Feedback',
                availableHeader: 'Disponible',
                idHeader: 'Id',
                deleteLabel: 'Supprimer',
                recrawlLabel: 'Recrawl',
                missing: 'Aucune idée trouvée',
                ratingHeader: 'Note',
                reactionsHeader: 'Réactions',
                nextIdeas: {
                    header: 'Idées suivantes',
                    unselectAllLabel: 'Déselectionner',
                    selectAllLabel: 'Tout sélectionner',
                    removeFromNextLabel: 'Retirer de la liste',
                    openSelectedAsCohortLabel: 'Cohorte des séléctionnés',
                },
                addHeader: 'Ajouter',
                searchQuestionLabel: 'Chercher les questions contenant',
                createQuestionLabel: 'Créer',
                questionsTable: {
                    typeHeader: 'Type',
                    questionHeader: 'Question',
                    reactionHeader: 'Réaction',
                    actionsHeader: 'Actions',
                    contribHeader: 'Existantes',
                    deducedHeader: 'Déduies',
                    searchResultHeader: 'Résultats recherche',
                    suggestionsHeader: 'Suggestions',
                },
                goToEnrichLabel: 'Enrichir',
                nextLabel: 'Suivante',
                sameLabel: '#',
                openInProductionLabel: 'Voir en prod',
            },
        },
        label: 'Contribuer',
        questions: {
            home: {
                title: 'Questions',
                overview: {
                    modeLabel: 'Mode',
                    modes: {
                        forceChart: 'Graphique forces',
                        list: 'Liste',
                        scatterChart: 'Graphique XY',
                        orderer: 'Trieur',
                    },
                },
                chart: {
                    projection: {
                        id: 'Id',
                        sumReactions: {
                          all: 'CRΣ',
                          mandatory: 'CR Nécessaires',
                          no: 'CR Non',
                          relevant: 'CR Pertinentes',
                          yes: 'CR Oui',
                          none: 'CR None',
                        },
                        sumAnswerTotal: 'CAΣ',
                        sumAnswer: {
                            Bad: 'CA :(',
                            Good: 'CA :)',
                            IDontKnow: 'CA ~',
                            ProbablyYes: 'CA ~Oui',
                            ProbablyNo: 'CA ~Non',
                            Yes: 'CA "Oui"',
                            No: 'CA "Non"',
                        },
                        conversions: {
                            toClick: {
                                asked: 'Asked Click',
                                yes: 'Yes Click',
                                no: 'No Click',
                                iDontKnow: 'IDontKnow Click',
                                best: 'Best Click',
                            },
                            toConversion: {
                                asked: 'Asked Converted',
                                yes: 'Yes Converted',
                                no: 'No Converted',
                                iDontKnow: 'IDontKnow Converted',
                                best: 'Best Converted',
                                total: 'Conversions',
                            },
                        },
                        sumAnswerNeitherYesNorNo: 'CA ni Oui ni Non',
                        countModels: '#modèles',
                        impactedIdeas: '#idées',
                        filling_pos: 'suggestion°',
                    },
                    filter: {
                        header: 'Filtre',
                        showAll: 'Tous',
                        showOnlyWihtoutRelations: 'Sans relation',
                        showOnlyWithRelations: 'Avec relation',
                        showOnlyAskStart: '1ères',
                        showOnlyTag: 'Étiquettes',
                        showOnlyMiddle: 'Moyennes',
                        showOnlyGoodToAsk: 'Bonnes',
                        showOnlyAskOnlyIfNeeded: 'Physiques',
                    },
                    color: {
                        header: 'Couleur',
                        category: 'Catégorie',
                        sumRelevant: 'Réactions pertinentes',
                    },
                    highlight: {
                        header: 'Important',
                    },
                    showRelationsLabel: 'Afficher relations',
                },
                relations: {
                    title: 'Relations',
                }
            },
            add: {
                linkPresentation: 'Tu as des idées de questions qui aident à mieux cerner quelqu\'un ou à trouver plus facilement une idée de cadeau ?',
                linkLabel: 'Ajouter des questions',
                contentLabel: 'Question',
                footer: {
                    pickIdeas: 'Choisis les idées correspondant à cette question pour pouvoir valider',
                    summary: '{n} idées sélectionnées correspondant à la question "{question}"',
                    writeQuestion: 'Écris une question pour pouvoir valider',
                },
                header: 'Voici quelques idées de cadeaux avec peu de questions associées. Peux tu trouver une question correspondant à certain d\'entre eux ? Formule la question et choisis les cadeaux qui plairaient à quelqu\'un qui répondrait "Oui" à cette question.',
                title: 'Ajouter une question',
                submitLabel: 'Ajouter cette question',
            },
            enrich: {
                header: 'Enrichir question',
                categoryHeader: 'Catégorie',
                categoryLabel: {
                    Unknown: 'Inconnue',
                    AskOnlyIfNeeded: 'Si nécessaire',
                    GoodToAsk: 'Bonne',
                    AverageQuality: 'Moyenne',
                    TagMain: 'Etiq. Principale',
                    TagSecondary: 'Etiq. Secondaire',
                    AskStart: '1ère',
                },
                keywordsLabel: 'Mots-clés',
                allowDoubtLabel: 'Autoriser doute',
                fillingDescriptionLabel: 'Instructions remplissage',
                fillingPosLabel: 'Remplissage °',
                reactionsCount: '{n} réactions',
                reactionsFilterLabel: 'Ne voir que:',
                content: {
                    inputPrefix: 'Contenu',
                    changeButtonLabel: 'Changer',
                    formsHeaders: {
                        affirmative: 'Affirmatif',
                        interrogative: 'Interrogatif',
                        negative: 'Négatif',
                    },
                    subjectsHeaders: {
                        Couple: 'Couple',
                        Female: 'Femme',
                        Male: 'Homme',
                        Others: 'Les autres',
                        Unknown: 'Inconnu',
                    }
                },
                openInDiffLabel: 'Ouvrir en diff',
                addReactionsLabel: 'Ajouter',
                seeReactionsLabel: 'Voir',
                relations: {
                    title: 'Relations',
                    none: 'Aucune',
                    implies: 'Implique',
                    impliesNot: 'Implique non',
                    isImpliedBy: 'Impliqué par',
                    isImpliedNotBy: 'Impliqué non par',
                    impliesCanAsk: 'Implique peut demander',
                    isImpliedCanAsk: 'Nécessite réponse positive à',
                    impliesCannotAsk: 'Implique ne peut pas demander',
                    isImpliedCannotAsk: 'Nécessite réponse négative à',
                    noImplies: 'Non implique',
                    isNoImpliedBy: 'Impliqué par non',
                    refines: 'Raffine',
                    isRefinedBy: 'Raffiné par',
                    makesLikely: 'Rend probable',
                    isMadeLikelyBy: 'Est rendu probable par',
                    createsReaction: 'Crée la réaction {reaction}',
                    isReactionCreatedBy: 'Réaction {reaction} créée par',
                    addLabel: 'Ajouter',
                },
                learning: {
                    title: 'Apprentissage',
                    retrainAllLabel: 'Réentrainer tous',
                },
                ideas: {
                    title: 'Idées correspondantes',
                    loadLabel: ' les charger',
                },
                answers: {
                    title: 'Réponses',
                    totalLabel: 'Total',
                }
            }
        },
        ideasCohort: {
            linkLabel: 'Éditer Cohorte',
            header: 'Édition de cohorte',
            setRatingLabel: 'Mettre note ',
            resetRatingLabel: 'Réinitialiser note',
            sortBy: {
                reactions: 'Trier par # réactions',
                name: 'Trier par nom',
            },
            showQuestionsLabel: 'Voir questions',
        },
        suggestions: {
            linkLabel: 'Suggestions',
            header: 'Suggestions',
            weightPrefix: 'Poids',
            decision: {
                accept: 'Oui',
                reject: 'Non',
                rejectAll: 'Non à tout',
                reactionNeeded: 'Oui, et surtout ne pas proposer si la réponse est {answer}',
                reactionBetter: 'Oui, et ce n\'est pas grave si la réponse est {answer}',
            },
            groupSize: '{n} idées',
            othersGroupsCount: '{n} autres groupes',
            hideDecisionLabel: 'Cacher ?',
            sortedByRatingLabel: 'par score',
            sortedByTitleLabel: 'par titre',
            sortedByFeedLabel: 'par source',
            questionChangeLabel: 'La question a changé !',
            instructions: {
                title: 'Instructions étiquetage',
                generalHeader: 'En général',
                generalText: 'Tu es en train de relier une questions à des idées de cadeaux (ce qu\'on appelle étiquetage). Il faut indiquer pour une idée de cadeau ce qui se passe en fonction de la réponse du chercheur de cadeau à cette question.',
                detailHeader: 'Pour cette question',
                relationsHeader: 'Questions plus précises',
                relationsText: 'Les questions ci-dessous sont plus précises, si l\'une d\'entre elles correspond aussi à l\'idée de cadeau à étiqueter il est inutile de relier la question actuelle à cette idée.',
            }
        }
    },
    home: {
        linkLabel: 'Accueil',
        createAccountLabel: 'Créer un compte',
        name: 'nebuly',
        logInLabel: 'Se connecter',
    },
    about: {
        header: 'A propos',
        contributeHeader: 'Contribuer',
        contributePresentation: 'Tout le monde peut contribuer à améliorer les suggestions de cadeaux. Que ce soit en donnant de nouvelles idées de questions, de cadeaux, ou en aidant à faire le lien entre les questions et les idées.',
        legal: {
            linkLabel: 'Informations Légales',
            header: 'Informations Légales',
            socialLine: 'Le propriétaire et éditeur de ce site web est la société Pianoctal, SARL au capital de 1024 EUR',
            addressLine: 'Ayant son siège au 69, rue du Temple, 75003 Paris',
            emailLine: 'Nous contacter: contact@pianoctal.com',
            tvaLine: 'Numéro de TVA FR51852275866',
            sirenLine: 'Inscrit au RCS de Paris au numéro 852 275 866',
        },
        bot: {
            header: 'Bot Nebuly',
            presentationLine: 'Le bot de Nebuly scanne les sites marchands dans le but de collecter les idées de cadeaux qu\'ils contiennent. Les titres, descriptions, images, disponibilité et prix des produits sont récupérés quotidiennement. Nous mettons tout en oeuvre pour que ce bot n\'impacte pas les performances de votre site en :',
            performanceList: 'L\'exécutant en dehors des heures de pointes d\'utilisation de vos sites webs;Mettant en cache les requêtes;N\'effectuant qu\'une seule requête à la fois',
            questionLine: 'Pour toute question ou remarque sur le fonctionnement du bot vous pouvez nous contacter sur bot@nebuly.fr .',
        },
        privacyPolicy: {
            linkLabel: 'Utilisation des données personnelles',
            header: 'Données personnelles',
        },
    },
    question: {
        shortcuts: {
            // Base first
            start: '[I:est-ce qu][IO:e ][IM:\'][IF:\'][IC:\'][IU:\']',
            subject: '[M:il][F:elle][C:ils][U:il ou elle][O:ceux qui]',
            // Verbs with negation around verb + suffix
            is: '{start}{subject} [SN:n\'][PN:ne ][S:est][P:sont][N: pas]',
            has: '{start}{subject} [N:n\'][S:a][P:ont][N: pas]',
            likes: '{start}{subject} [N:n\']aime[C:nt][O:nt][N: pas]',
            lives: '{start}{subject} [N:ne ]vi[P:vent][S:t][N: pas]',
            adores: '{start}{subject} [N: n\']adore[P:nt][N: pas]',
            prefers: '{start}{subject} [N:ne ]préfère[P:nt][N: pas]',
            lacksOf: '{start}{subject} [N:ne ]manque[P:nt][N: pas] de',
            believes: '{start}{subject} [N:ne ]croi[S:t][P:ent][N: pas]',
            doesOften: '{start}{subject} [N:ne ][S:fait][P:font][N: pas] souvent',
            spendsTime: '{start}{subject} [N:ne ]passe[P:nt][N: pas] [S:son][P:leur] temps à',
            takesCareOf: '{start}{subject} [N: ne ]pren[S:d][P:nent][N: pas] soin de',
            // Simple ones built on top of others or not
            hasRecently: '{has} récemment',
            isOften: '{is} souvent',
            hasOften: '{has} souvent',
        },
        subject: {
            Female: 'elle',
            Male: 'il',
            Unknown: 'il ou elle',
            Couple: 'ils'
        },
        answers: {
            Yes: 'oui',
            No: 'non',
            ProbablyNo: 'probablement pas',
            IDontKnow: 'je ne sais pas',
            ProbablyYes: 'probablement',
            Good: ':)',
            Bad: ':(',
        },
        deleter: {
            label: 'Supprimer avec ses {n} réponses',
            deletedLabel: 'Supprimée',
        },
        keyMapping: {
            yes: 'o',
            no: 'n',
            dontKnow:' ',
        },
        mark: '&nbsp;?',
    },
    search: {
        start: {
            header: 'Pour qui est-ce',
            ageHeader: {
                Male: 'Quel âge a-t-il',
                Female: 'Quel âge a-t-elle',
                Couple: 'Quel âge ont-ils',
                Unknown: 'Quel âge a-t-il',
            },
            ageMaxFormatter: '{years} ans ou plus',
            lowAgeExplanation: 'Nebuly ne propose pas encore de cadeaux pour enfants.',
            budgetHeader: 'Quel est ton budget',
            hintsHeader: 'Un indice',
            hintsPlaceholder: '(Facultatif) Si tu as déjà des idées ou pistes de cadeaux qui peuvent me guider tu peux les donner ici. Par exemple: sportive geek photographie.',
            startMultiPartLabel: 'C\'est parti !',
            startLabel: 'Commencer',
            nextPartLabel: 'Suivant',
            previousPartLabel: 'Précédent',
        },
        questionFeedback: {
            header: 'Trouves-tu cette question pertinente ?',
            thanks: 'Merci d\'avoir donné ton avis sur cette question, cela me permet de m\'améliorer.',
        },
        givenUpContent: 'Oups, je n\'ai rien trouvé qui puisse plaire ! Il te reste les chocolats ou les fleurs !',
        foundIdea: {
            linkButtonLabel: 'Plus d\'informations',
            goodIdeaFooter: 'Parfait ! Content d\'avoir aidé !',
            startNewSearchLabel: 'Chercher un autre cadeau',
            feedback: {
                header: 'Je te propose le cadeau ci-dessus, est-ce une bonne idée&nbsp;?',
                help: 'Tu peux cliquer sur l\'image pour plus d\'informations',
                footer: 'Ta réponse va me permettre de continuer à chercher dans la bonne direction.',
                back: 'Retour',
                labels: {
                    SawIdea: 'Vu', // Should only be rendered in search sessions analysis
                    ClickedOnIdea: 'Clic', // Should only be rendered in search sessions analysis
                    GoodIdea: 'On s\'arrête là je suis satisfait',
                    InconsistentIdea: 'Ca ne correspond pas à mes réponses',
                    BadIdea: 'Ca ne va pas plaire',
                    YouAreClose: 'Oui, propose moi une idée similaire',
                    YouCanDoBetter: 'Je veux voir d\'autres idées',
                    Reset: 'On s\'est égaré, on recommence',
                    HasAlready: 'A déjà', // Used in session analysis.
                    IDontKnow: 'Je ne sais pas',
                },
                hasAlready: {
                    afterBad: {
                        Male: 'Il l\'a déjà',
                        Female: 'Elle l\'a déjà',
                        Couple: 'Ils l\'ont déjà',
                        Unknown: 'Il ou elle l\'a déjà',
                    },
                    afterGood: {
                        Male: 'On continue car il l\'a déjà',
                        Female: 'On continue car elle l\'a déjà',
                        Couple: 'On continue car ils l\'ont déjà',
                        Unknown: 'On continue car il ou elle l\'a déjà',
                    },
                    afterClose: {
                        Male: 'Oui, mais il l\'a déjà',
                        Female: 'Oui, mais elle l\'a déjà',
                        Couple: 'Oui, mais ils l\'ont déjà',
                        Unknown: 'Oui, mais il ou elle l\'a déjà',
                    },
                },
                good: {
                    label: 'Oui',
                    header: 'Je suis content que cette idée te plaise ! Que fait on maintenant ?',
                },
                close: {
                    label: 'Presque',
                    header: 'Ah, je ne suis pas loin ?'
                },
                bad: {
                    label: 'Non',
                    header: 'Nebuly est tout nouveau et tes retours m\'aident à m\'améliorer. Qu\'est-ce qui ne va pas ?',
                },
                aaq: {
                    header: 'Est-ce que l\'idée ci-dessus te parait bien ?',
                    good: 'Oui',
                    bad: 'Non',
                    iDontKnow: 'Je ne sais pas',
                },
            },
        },
        age: {
            notBorn: 'Bientôt parmi nous',
            months: '{n} mois',
            years: '{n, plural, =1 {1 an} other {# ans}}',
            monthMark: '{n}M',
            yearMark: '{n}A'
        },
        alert: {
            linkToLabel: 'Créer une alerte',
            presentation: 'Nebuly peut te prévenir lorsqu\'une nouvelle idée de cadeau correspond à tes réponses actuelles. Tu peux trier tes réponses par importance et laisser une adresse e-mail à laquelle t\'envoyer des idées de cadeau qui plairaient à cette personne dans un futur plus ou moins lointain.',
            title: 'Création d\'alerte',
            submitLabel: 'Créer',
            cancelLabel: 'Annuler',
            emailLabel: 'Notifier',
            emailPlaceholder: 'mon.adresse@email.fr',
            importantQuestionsHeader: 'Glisse les réponses importantes en haut',
            ignoredQuestionsHeader: 'Les réponses ci-dessous seront ignorées',
            addIgnoredQuestionsInstruction: 'Glisse des réponses ci-dessous pour les ignorer',
            moveToSorterLabel: 'Trier réponses',
            moveToSubmitLabel: 'Valider',
            emailExplanationMono: 'Entre ici l\'adresse e-mail à laquelle Nebuly t\'enverra des alertes lorsqu\'il trouve une idée de cadeau qui correspond à tes réponses actuelles.',
            emailValidation: {
                title: 'Validation e-mail alerte',
                success: 'Ton adresse e-mail a été validée. Ton alerte est maintenant active !',
                failure: 'Oups, je n\'ai pas pu valider ton adresse e-mail, il faudra recréer ton alerte :(',
                notNeeded: 'Ton alerte est maintenant active !',
                needed: 'Nebuly t\'a envoyé un email pour confirmer ton e-mail et démarrer l\'alerte',
            },
        },
        share: {
            linkToLabel: 'Partager',
            title: 'Partage',
            text: 'Partage l\'idée de cadeau trouvée par Nebuly via le moyen de ton choix',
            cancelLabel: 'Annuler',
            email: 'E-mail',
            gmail: 'Gmail',
            native: 'Autre',
            whatsapp: 'WhatsApp',
            payload: {
                title: 'Idée cadeau',
                text: 'J\'ai trouvé cette idée de cadeau {url} grâce à Nebuly, qu\'en penses-tu ?',
            },
            square: {
                copyLabel: 'Copier le lien',
                linkHeader: 'Lien à partager',
            },
        },
        ideasHighlight: {
            header: 'Tu verras ici les pistes les plus prometteuses que tes réponses m\'auront inspirées.',
        },
        debugInfo: {
            header: 'Infos Débug',
            ideasCountHeader: 'Idées: ',
            ideasScoreHistTitle: 'Idées par score',
            questionsScoreTitle: 'Questions par score',
            openBestInCohortLabel: 'Voir les meilleures',
            overrideQuestionLabel: 'Changer question',
        },
        square: {
            label: 'Je cherche un cadeau pour',
            placeholders: [
                'ma copine fan de photographie et de chats',
                'mon copain qui adore le vin et le golf',
                'un collègue qui boit beaucoup de bière',
                'ma soeur fan de Harry Potter et de musique',
                'ma copine qui adore les avocats et le rose',
                'un ami qui aime les maths et le théâtre',
                'un collègue du côté obscur de la force',
                'un ami paranoïaque',
                'mon frêre vegan qui vit dans la matrice',
                'mon père qui joue encore à mario',
                // raleuse
                // couple
                // généreux
            ],
            suggestUseGuidePrefix: 'Tu ne sais pas quoi chercher ?',
            useGuideAction: 'Laisse-toi guider',
            suggestUseGuideSuffix: '.',
            interruptHeader: 'Voici une sélection d\'idées correspondant à tes indices. Je peux approfondir en te posant des questions.',
            interruptFooder: 'Répondre aux questions',
            linkFast: 'Recherche rapide',
            share: {
                title: 'Nebuly',
                text: 'Recherche cadeau',
            },
        },
    },
    admin: {
        header: 'Administration',
        maintenanceTasksHeader: 'Maintenance',
        maintenanceTasksLabels: {
            processContribs: 'Appliquer contributions',
            correctImages: 'Réparer images corrompues',
            clearCrawlerCache: 'Nettoyer cache requêtes',
            reloadAutomatedAnswersRules: 'Recharger règles réponses auto',
            deleteOrphanedImages: 'Supprimer images orphelines',
            deleteOldDebugInfo: 'Supprimer vieilles info debug',
            tagObjectsNotImmediate: 'Marquer non-instantanés comme objets',
            tagDepartments: 'Départements',
            correctTagging: 'Correction tags',
            updateRatings: 'MàJ notes',
        },
        statsHeader: 'Statistiques',
        statsLabels: {
            discovery: 'Découverte',
            overview: 'Général',
            performance: 'Performance',
            quality: 'Qualité',
            olap: 'Olap',
        },
        crawl: {
            home: {
                header: 'Crawlers',
                linkToLabel: 'Crawlers',
                startLabel: 'Démarrer',
                limitLabel: 'Limite',
                stopLabel: 'Arrêter',
                runningHeader: 'Crawl en cours',
                endedHeader: 'Crawl terminé',
                numberOfCrawlersHeader: 'Crawlers',
                progressionHeader: 'Progression',
            },
            reports: {
                header: 'Rapports',
                linkToLabel: 'Rapports',
            },
            issues: {
                noIssuesLabel: 'Aucune erreur rencontrée',
                causeHeader: 'Cause',
                merchantHeader: 'Marchand',
                stepHeader: 'Étape',
                urlHeader: 'Url',
                countHeader: '{n} erreurs rencontrées',
                detailHeader: 'Détail',
            },
            progress: {
                title: {
                    crawledAlreadyKnown: 'Retrouvés',
                    errors: 'Erreurs',
                    expected: 'Attendus',
                    unavailable: 'Indisponibles',
                    newIds: 'Nouveaux',
                    queriesCacheHit: 'Requêtes via cache',
                    queriesCacheMiss: 'Requêtes réelles',
                    collections: 'Collections',
                    collectionsEnded: 'Collections terminées',
                }
            },
            tester: {
                header: 'Testeur',
                linkToLabel: 'Testeur',
            },
            auto: {
                header: 'AutoVar',
                linkToLabel: 'Auto',
                start: 'Démarrer',
            },
        },
        analytics: {
            header: 'Analyse',
            linkToLabel: 'Analyse',
            podium: {
                linkToLabel: 'Podium',
                header: 'Podium idées',
            },
            redundantContribs: {
                header: 'Contributions redondantes et conflits',
                startLabel: 'Lancer',
            },
            twins: {
                home: {
                    linkToLabel: 'Jumeaux',
                    header: 'Jumeaux',
                },
                fromContent: {
                    header: 'Depuis contenu',
                    sizeAfterLabel: 'Taille'
                },
                fromTags: {
                    header: 'Depuis tags',
                    loadLabel: 'Charger',
                    minChainLengthLabel: '# questions minimal',
                    ideasCountLabel: '# idées',
                    singleMerchantLabel: 'Marchand unique',
                    limitLabel: 'Limite',
                    orderByPrefix: 'Trier par',
                    orderByChainLengthFirstLabel: '# questions',
                    orderByIdeasCountFirstLabel: '# idées',
                    ideasHeader: 'Idées',
                    countsHeader: 'Taille',
                    questionsIndicator: '?',
                    ideasIndicator: 'idées',
                    questionsHeader: 'Questions',
                    actionsHeader: 'Marqer',
                    relationsActions: {
                        Identical: 'Identiques',
                        OnlyQualityDifference: 'Uniquement ≠ qualité',
                        OnlySizeDifference: 'Uniquement ≠ taille',
                        CouldBeDistinguishedLater: 'Distinguable plus tard',
                        OnlyColorDifference: 'Uniquement ≠ couleur',
                        Distinct: 'Différent',
                        SameSpectacle: 'Même spectacle',
                    },
                },
            },
            spreadTest: {
                header: 'Test diversité',
                iterationsLabel: 'Itérations',
                firstIdeasHeader: 'Premières idées',
                countHeader: '#',
                yesProbaLabel: '% Oui',
                noProbaLabel: '% Non',
                startLabel: 'Démarrer',
            },
            searchSessions: {
                sectionTitle: 'Recherches',
                analysis: {
                    header: 'Analyse recherches',
                    linkToLabel: 'Analyse sessions',
                    collectionHeader: 'Type',
                    badFeedbackLabel: 'Mauvais feedback',
                    gaveUpLabel: 'Abandon algo',
                    todayLabel: 'Aujourd\'hui',
                    noDecisiveQuestionLabel: '!Decisive',
                    noRelatedQuestionLabel: '!Related',
                    club20: '20club',
                },
                feedbackAnalysis: {
                    header: 'Analyse feedback',
                    linkToLabel: 'Feedback',
                    projections: {
                        clicks: 'Clics',
                        merchantId: 'id',
                        price: 'Prix',
                        rating: 'Note',
                        sessionsCount: 'Sessions',
                        titleLength: 'titre',
                        negativeFeedback: 'Négatif',
                        commission: 'Commission',
                        conversions: 'Conversions',
                    }
                },
                detailHeader: 'Session',
                backToSummaryLabel: 'Retour aux sessions',
                ageHeader: 'Âge',
                dateHeader: 'Date',
                genderHeader: 'Genre',
                priceHeader: 'Prix',
                hintsHeader: 'Indice',
                locationHeader: 'URL',
                answersCountHeader: '# réponses',
                feedbackCountHeader: '# feedback',
                actionsHeader: 'Actions',
                openLabel: 'Ouvrir',
                deleteLabel: 'Supprimer',
            }
        },
        merchants: {
            linkToLabel: 'Marchands',
            title: 'Marchands',
        },
        serviceWorker: {
            offlineMode: 'Hors-ligne',
            ready: 'Prêt',
            registered: 'Enregistré',
            registrationError: 'Erreur d\'enregistrement: {error}',
            updatedWhileControlled: 'Mise à jour disponible',
            updatedWhileUncontrolled: 'Mise à jour réussie',
            unregisterFailed: 'Échec mise à jour',
            update: 'Râfraichir',
        },
        automatedAnwers: {
            linkToLabel: 'Réponses automatiques',
            header: 'Réponses automatiques',
            beforeCondition: 'Quand',
            betweenConditionAndAnswer: 'répondre',
            betweenAnswerAndQuestion: 'à',
            opposeOtherwise: 'sinon opposer',
            addHeader: 'Ajouter',
        },
        performance: {
            linkToLabel: 'Performance',
            header: 'Performance',
            responseTime: {
                header: 'Temps de réponse',
            },
            memory: {
                header: 'Mémoire',
            }
        },
        learning: {
            linkToLabel: 'Apprentissage',
            header: 'Apprentissage',
            overview: {
                header: 'Vue d\'ensemble',
                chart: {
                    projection: {
                        question: 'Question',
                        primary: 'Primaire',
                        key: 'Clé',
                        learningSize: 'Taille apprentissage',
                        learningSizeMatch: 'TA+',
                        learningSizeNoMatch: 'TA-',
                        keywordsFlags: 'KF',
                        svm: {
                            dimensions: 'Dimensions',
                            margin: 'Marge',
                            contraries: 'Contraires',
                            crossValidation: 'Validation croisée',
                            regularization: 'λ',
                            iterations: 'Iterations',
                            miniBatchSize: 'Taille batch',
                            titleFactor: 'Facteur titre',
                        },
                        tree: {
                            depth: 'Profondeur',
                            nodes: 'Noeuds',
                            idfsFactor: 'Idfs*',
                            margin: 'Marge',
                        },
                        trainDuration: 'Durée ms',
                    }
                }
            },
            editor: {
                header: 'Éditeur',
                questionHeader: 'Question',
                primaryHeader: 'Réaction',
                learningSizeHeader: '|SA|',
                marginHeader: 'Marge',
                contrariesHeader: 'Contraires',
                dimensionsSizeHeader: 'Dimensions',
                regularizationHeader: 'λ',
                iterationsHeader: 'Itérations',
                miniBatchSizeHeader: 'Mini batch size',
                titleFactorHeader: 'Facteur titre',
                durationMsHeader: 'Durée (ms)',
                crossValidationErrorRateHeader: 'XValidation',
                suggestionsHeader: 'Suggestions',
                retrainLabel: 'Entraîner',
                retrainTreeLabel: 'Arbre',
                retrainAndResuggestLabel: 'et resuggérer',
                factorSearchHeader: 'Rech. poids',
            },
        }
    },
    moderation: {
        home: {
            linkToLabel: 'Modération',
            header: 'Modération',
            userHeader: 'Utilisateur',
            actionsHeader: 'Actions',
            acceptLabel: 'Accepter',
            editLabel: 'Remplir',
            openEnrichLabel: 'Enrichir',
            deleteLabel: 'Supprimer',
            submitLabel: 'Envoyer',
            ideas: {
                header: 'Idées',
                empty: 'Aucune contribution d\'idée.',
                generic: {
                    header: 'Génériques',
                    descriptionHeader: 'Description',
                    ageHeader: 'Âge',
                    priceHeader: 'Budget',
                },
                merchant: {
                    header: 'Marchands',
                    urlHeader: 'URL',
                }
            },
            questions: {
                header: 'Questions',
                contentHeader: 'Contenu',
                empty: 'Aucune contribution de question.',
            },
            reactions: {
                header: 'Réactions',
                empty: 'Aucune contribution de réaction',
                countHeader: '# réactions',
            },
        },
        userReactions: {
            header: 'Réactions utilisateur',
            ideaHeader: 'Idée',
            reactionHeader: 'Réaction',
        }
    },
    genderPicker: {
        Female: 'Une femme',
        Male: 'Un homme',
        Couple: 'Un couple',
        Unknown: 'Qu\'importe',
    }
}

export default fr;