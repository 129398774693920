import React from "react";


function useHoverSideEffect<T>(f: (arg: T | undefined) => void, arg: T) {
    React.useEffect(() => {
        return () => {
            // TODO do this only when this component is hovered. This is not trivial
            // as we cannot rely on the state without having it as dependency of this
            // effect which creates ugly glitches. We need an external map to keep
            // track of this. This is expensive and for now not needed in our use
            // cases.
            f(undefined);
        };
        // We want just to trigger the effect on unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        onMouseEnter: () => {
            f(arg);
        },
        onMouseLeave: () => {
            f(undefined);
        }
    };
}


function useHover() {
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    return {
        isHovered,
        onMouseEnter: () => {
            setIsHovered(true)
        },
        onMouseLeave: () => {
            setIsHovered(false);
        }
    };
}

export {
    useHover,
    useHoverSideEffect,
}