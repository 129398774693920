

export type ServerAccountCreationPayload = {
    readonly locale: string,
    readonly email: string,
    readonly password: string,
}

export type ServerAccountValidationPayload = {
    readonly locale: string,
    readonly token: string,
}
export type ServerAuthenticationPayload = {
    readonly email: string,
    readonly password: string
}

export type ServerAccountInfo = {
    readonly email: string,
    readonly account_type: number
}

export type AccountInfo = {
    readonly email: string,
    readonly type: AccountType,
}

export enum AccountType {
    Admin = 0,
    Moderator = 1,
    Contributor = 2,
    NonLogged = 8,
}

export type ServerContributor = {
    readonly id: number,
    readonly email: string,
    readonly account_type: string,
}

export function createAccountTypeFrom(serverType: string): AccountType {
    switch (serverType) {
        case '0': return AccountType.Admin;
        case '1': return AccountType.Moderator;
        case '2': return AccountType.Contributor;
        default: return AccountType.NonLogged;
    }
}

export function isModerator(type: AccountType): boolean {
    return type === AccountType.Admin || type === AccountType.Moderator;
}