

export function allWithLimit<T>(inputLimit: number, promises: Array<() => Promise<T>>, progressReporter?: (countFinished: number) => void): Promise<T[]> {
    const limit = inputLimit < 1 || inputLimit > promises.length ? promises.length : inputLimit;
    const results: T[] = [];
    return new Promise((resolve, reject) => {
        let indexOfNextToStart = limit;
        let countFinished = 0;
        const onPromiseSuccess = (index: number) => (result: T) => {
            results[index] = result;
            if (indexOfNextToStart < promises.length) {
                promises[indexOfNextToStart]().then(onPromiseSuccess(indexOfNextToStart), onPromiseFailure);
            }
            indexOfNextToStart++;
            countFinished++;
            if (progressReporter !== undefined) {
                progressReporter(countFinished);
            }
            if (countFinished === promises.length) {
                resolve(results);
            }
        }
        const onPromiseFailure = (failure: any) => reject(failure);
        for (let i = 0; i < limit; i++) {
            promises[i]().then(onPromiseSuccess(i), onPromiseFailure)
        }
    });
}

export function wait(ms: number): Promise<void> {
    return new Promise((resolve, _reject) => {
        setTimeout(resolve, ms);
    });
}