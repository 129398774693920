import {ComponentType} from 'react';

// Omit taken from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type Subtract<T, K> = Omit<T, keyof T & keyof K>;

const getDisplayName = (c: ComponentType<any>) => {
    return c.name === undefined || c.name === '' ? c.displayName : c.name;
}

export {
    getDisplayName,
}
