import { Action } from '../../../actions';
import * as cq from '../../../actions/contrib/questions/add';
import { ContribAddQuestionsState } from '../../../store';

const defaultState = {
    question: {content: ''},
};

const reducer = (state: ContribAddQuestionsState = defaultState, action: Action = {type: ''}): ContribAddQuestionsState => {
    switch (action.type) { 
        case cq.SET_QUESTION:
            return {...state, question: action.question};
        case cq.RESET_USER_CHOICES:
            return {...state, question: {content: ''}};
        default:
            return state;
    }
};
export default reducer;