import React from 'react';
import refresh from '../../images/refresh.svg';
import { useSimpleTranslation } from '../hooks/Translator';

type Props = {
    onClick: () => void,
    style ?: React.CSSProperties,
}

const Refresh: React.FC<Props> = ({onClick, style}) => {
    const t = useSimpleTranslation();
    const title = t.common.refreshTitle;
    return <img className="clickable refresh" style={style} src={refresh} alt={title} title={title} onClick={onClick}/>
};

export default Refresh;