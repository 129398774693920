import React from 'react';
import classNames from 'classnames';
import { useDrag, useDrop, DragPreviewImage } from 'react-dnd';

import { ServerAlertCreationInitAnswer } from '../../../model/alert';
import { mapNumberToAnswer } from '../../../model/answer';
import QuestionComponent from '../../Question';
import { AnswerComponent } from '../AnswerPicker';
import { UserSex } from '../../../model/gender';

import './AlertCreator.scss';
import { SentenceForm } from '../../../model/question';

export const DND_TYPE = 'alert-question';

type PropsFromParent = {
    answer: ServerAlertCreationInitAnswer,
    gender: UserSex,
    ignored: boolean,
    y: number,
    height: number,
    onDragStart: () => void,
    onIsOver: () => void,
    onDrop: (valid: boolean) => void,
}

type PropsFromCollector = {
    isDragging: boolean,
}

export type DraggableAnswerbject = {
    type: 'alert-question',
}

type DropResult = {

}

type Props = PropsFromParent;

const transparentPixel = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const DraggableAnswer: React.FC<Props> = (props: Props) => {

    const {answer, gender, y, height, ignored, onDragStart, onIsOver, onDrop} = props;

    const [{isDragging}, drag, preview] = useDrag<DraggableAnswerbject, DropResult, PropsFromCollector>({
        item: {type: DND_TYPE},
        begin: monitor => {
            const item: DraggableAnswerbject = {type: DND_TYPE};
            onDragStart();
            return item;
        },
        end: monitor => {
            onDrop(false);
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const drop = useDrop({
        accept: DND_TYPE,
        hover: () => onIsOver(),
        drop: () => onDrop(true),
      })[1];

    let form: SentenceForm = 'affirmative';
    let answerComponent = null;
    if (mapNumberToAnswer(answer.a) === 'Yes') {
        form = 'affirmative';
    } else if (mapNumberToAnswer(answer.a) === 'No') {
        form = 'negative';
    } else {
        form = 'interrogative';
        answerComponent = <div className="answer-alone"><AnswerComponent a={mapNumberToAnswer(answer.a)}/></div>;
    }

    const className = classNames({
        'draggable-answer': true,
        'dragging-answer': isDragging,
        'answer-ignored': ignored,
        'answer-not-ignored': !ignored,
        'as-sentence': answerComponent === null,
        'as-question-and-answer': answerComponent !== null
    });

    // We disable the drag preview because the HTML5 backend shows it but not the touch backend.
    return <>
        <DragPreviewImage connect={preview} src={transparentPixel}/>
        <div className="draggable" style={{top: y, height}} ref={drop}>
            <div ref={drag} className={className}>
                <QuestionComponent form={form} asSentence={true} subject={gender} content={answer.question_content}/>
                {answerComponent}
            </div>
        </div>
    </>;
}

export default DraggableAnswer;