import * as React from 'react';

import './Deck.scss';
import Card, {CardContent, isSameContent} from './Card';
import { PageSize } from '../ResponsiveHOC';
import { Point, addPoints } from '../../util/geometry';
import useResponsive from '../hooks/Responsive';
import { animateEaseIn, animateEaseInOut, animate2EaseInOut } from '../AnimationHOC';
import { sizes, DeckLayout, getDeckLayout } from './Layout';

export const slow = 1; // Slow animations for debug

const revealDurationMs = 800 * slow;

type FCProps = {
    topDx: number,
    topContent: CardContent,
    belowContent: CardContent,
    topRevealT: number,
    belowRevealT: number,
    isBelowRevealOnLeftSide: boolean,
    drawWavesOnCard: boolean,
}

function getRevealTransform(t: number, cardSize: Point, onLeftSide: boolean) {
    if (t === 0 || t === 1) {
        return '';
    }
    const sign = onLeftSide ? 1 : -1;
    const translation = `translate3d(${animate2EaseInOut(t, 0, 0.5, 0.5, 1, 0, -cardSize[0] * sign / 2, 0)}px, 0px, ${animate2EaseInOut(t, 0, 0.5, 0.5, 1, 0, cardSize[0], 0)}px)`;
    const rotation = `rotate3d(0, 1, 0, ${animateEaseInOut(t, 0, 1, 0, 0.5 * sign)}turn)`;
    const symetry = t > 0.5 ? 'scaleX(-1)' : '';
    return `${translation} ${rotation} ${symetry}`;
}

function getTouchTransformTxTyTzR(touchDx: number, cardSize: Point): [number, number, number, number] {
    return [touchDx * 2, -animateEaseIn(Math.abs(touchDx), 0, 200, 0, 200), Math.abs(Math.min(touchDx * 4, cardSize[0] / 2)), touchDx / 6];
}

function getCardTransform(pageSize: PageSize, deckLayout: DeckLayout, topDx: number, revealT: number, revealOnLeftSide: boolean): string {
    const {cardSize, marginTop} = deckLayout;
    const centerOffset: Point = [pageSize.x / 2 - cardSize[0] / 2, marginTop];
    const [touchOffsetX, touchOffsetY, touchOffsetZ, touchRotate] = getTouchTransformTxTyTzR(topDx, cardSize);
    const rotateTransform = touchRotate === 0 ? '' : ` rotate(${touchRotate}deg)`;
    const touchOffset: Point = [touchOffsetX, touchOffsetY];
    const translate = addPoints(centerOffset, touchOffset);
    const revealTransform = getRevealTransform(revealT, cardSize, revealOnLeftSide);
    return `translate3d(${translate[0]}px, ${translate[1]}px, ${touchOffsetZ}px) ${rotateTransform}${revealTransform}`;
}

const DeckFC: React.FC<FCProps> = ({topDx, topContent, belowContent, topRevealT, belowRevealT, isBelowRevealOnLeftSide, drawWavesOnCard}) => {
    const {pageSize, dpi} = useResponsive();
    const deckLayout = getDeckLayout(pageSize, dpi);
    const {cardSize, marginTop} = deckLayout;
    const transformTop = getCardTransform(pageSize, deckLayout, topDx, topRevealT, true);
    const belowVisibleContent = belowRevealT > 0.5 ? belowContent : undefined;
    const topVisibleContent = topRevealT > 0.5 ? topContent : undefined;
    return <div className="deck" style={{height: cardSize[1] + marginTop + sizes.marginBottom + sizes.overflowBottom3d}}>
        <div className="below-card deck-remaining" style={{transform: getCardTransform(pageSize, deckLayout, 0, 0, true), width: cardSize[0], height: cardSize[1]}}>
            <Card recto={undefined} showVerso={true} size={cardSize}/>
        </div>
        <div className="below-card second-card transformable-card" style={{transform: getCardTransform(pageSize, deckLayout, 0, belowRevealT, isBelowRevealOnLeftSide), width: cardSize[0], height: cardSize[1]}}>
            <Card recto={belowVisibleContent} size={cardSize} showVerso={belowRevealT < 0.5}/>
        </div>
        <div className="top-card transformable-card" style={{transform: transformTop, width: cardSize[0], height: cardSize[1]}}>
            <Card recto={topVisibleContent} size={cardSize} showVerso={topRevealT < 0.5}/>
        </div>
    </div>;
}

export type SwipeAnimation = {
    duration: number,
    effect: 'swipe',
    toLeft: boolean,
    startDx: number,
    halfPageWidth: number,
    contentBelow: CardContent,
}

export type CancelAnimation = {
    duration: number,
    effect: 'cancel',
    startDx: number,
}

export type RevealAnimation = {
    effect: 'reveal',
    duration: number,
    top: boolean,
    onLeftSide: boolean,
}

export type Animation = SwipeAnimation | CancelAnimation | RevealAnimation;

type RunningAnimation = {
    animation: Animation,
    start: number,
}

type Props = {
    touchStartX: number | undefined,
    touchCurrentX: number | undefined,
    animation: Animation | undefined,
    content: CardContent,
    onAnimationEnd: (animation: Animation) => void,
}

type State = {
    topDx: number | undefined,
    topContent: CardContent,
    belowContent: CardContent,
    topRevealT: number,
    belowRevealT: number,
    belowRevealOnLeftSide: boolean,
    enableAnimations: boolean,
}

let lastAnimateFrame = -1;

/**
 * Adds state information to handle transitions.
 */
class Deck extends React.Component<Props, State> {

    // Outside of state because state is async and we need to react to consecutive componentDidUpdate.
    private isTopTransitioning = false;
    private isBelowTransitioning = false;

    // Business Logic

    animateTick(running: RunningAnimation, t: number) {
        const animation = running.animation;
        switch (animation.effect) {
            case 'swipe': {
                const sign = animation.toLeft ? -1 : 1;
                if (animation.startDx === 0) {
                    this.setState({topDx: animateEaseIn(t, 0, 1, 0, (animation.halfPageWidth + sizes.rotationHMargin) * sign)});
                } else {
                    this.setState({topDx: animation.startDx + t * (animation.halfPageWidth + sizes.rotationHMargin) * sign});
                }
                break;
            }
            case 'cancel': {
                this.setState({topDx: animateEaseInOut(t, 0, 1, animation.startDx, 0)});
                break;
            }
            case 'reveal': {
                if (animation.top) {
                    this.setState({topRevealT: t});
                } else {
                    this.setState({belowRevealT: t});
                }
            }
        }
    }

    animateLoop(running: RunningAnimation) {
        const {animation, start} = running;
        requestAnimationFrame(() => {
            let now = Date.now();
            if (lastAnimateFrame > 0 && this.state.enableAnimations) {
                const durationSinceLastFrame = now - lastAnimateFrame;
                if (durationSinceLastFrame > 1000) {
                    this.setState({enableAnimations: false});
                }
            }
            lastAnimateFrame = now;
            const t = Math.min(1, (now - start) / animation.duration);
            this.animateTick(running, t);
            if (t === 1) {
                lastAnimateFrame = -1;
                const {belowContent, belowRevealT} = this.state;
                const stateChange: Partial<State> = {};
                stateChange.topDx = undefined;
                this.props.onAnimationEnd(animation);
                if (animation.effect === 'swipe') {
                    this.isTopTransitioning = false;
                    // If below card content was ready before the animation start we just transfer its content without animating.
                    // If it contains content that was ready while animating, its reveal animation is already running.
                    // If the below card is still animating we keep the top card away to show the animation of the below card
                    // If it has no ready content, we hide the top card.
                    if (belowContent !== undefined && !this.isBelowTransitioning && belowRevealT === 1) {
                        // Was ready before the animation ended, just show
                        stateChange.belowRevealT = 0;
                        stateChange.topContent = belowContent;
                        stateChange.belowContent = undefined;
                    } else if (belowContent !== undefined && this.isBelowTransitioning) {
                        // Below is revealing, move away top until animation end
                        stateChange.topDx = animation.halfPageWidth * 4;
                    } else {
                        // Below is not ready
                        stateChange.topContent = undefined;
                        stateChange.topRevealT = 0;
                    }
                } else if (animation.effect === 'reveal') {
                    // If the reveal ends after the swipe end, transfer the card from below to top:
                    if (!this.isTopTransitioning) {
                        stateChange.topRevealT = 1;
                        stateChange.belowRevealT = 0;
                        stateChange.topContent = belowContent;
                        stateChange.belowContent = undefined;
                    }
                    if (animation.top) {
                        this.isTopTransitioning = false;
                    } else {
                        this.isBelowTransitioning = false;
                    }
                }
                this.setState(stateChange as State);
            } else {
                requestAnimationFrame(() => this.animateLoop(running));
            }
        })
    }

    startAnimation(animation: Animation) {
        if (animation.effect === 'swipe') {
            this.isTopTransitioning = true;
            this.setState({belowContent: animation.contentBelow, belowRevealT: animation.contentBelow === undefined ? 0 : 1});
        }
        if (animation.effect === 'reveal') {
            this.isBelowTransitioning = !animation.top;
            this.isTopTransitioning = this.isTopTransitioning || animation.top;
            this.setState({belowRevealOnLeftSide: !animation.top && animation.onLeftSide});
        }
        if (this.state.enableAnimations) {
            this.animateLoop({
                start: Date.now(),
                animation,
            });
        } else {
            // Only draw the last tick of all animations
            this.animateLoop({
                start: Date.now() - animation.duration * 2,
                animation
            });
        }

    }

    updateContentFromProps() {
        const {content: contentFromProps} = this.props;
        const {topContent: topContentInState, belowContent: belowContentInState, topDx} = this.state;
        if (contentFromProps !== undefined && !isSameContent(contentFromProps, topContentInState) && !isSameContent(contentFromProps, belowContentInState)) {
            if (this.isTopTransitioning) {
                if (!this.isBelowTransitioning) {
                    this.startAnimation({effect: 'reveal', top: false, duration: revealDurationMs, onLeftSide: topDx !== undefined && topDx > 0});
                }
                this.setState({belowContent: contentFromProps, belowRevealT: 0});
            } else {
                this.setState({topContent: contentFromProps});
                this.startAnimation({effect: 'reveal', top: true, duration: revealDurationMs, onLeftSide: true});
            }
        }
    }

    // Life Cycle

    constructor(props: Props) {
        super(props);
        this.state = {
            topDx: undefined,
            topContent: '',
            belowContent: '',
            topRevealT: 0,
            belowRevealT: 0,
            belowRevealOnLeftSide: true,
            enableAnimations: true,
        };
    }

    componentDidMount() {
        this.updateContentFromProps();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.animation === undefined && this.props.animation !== undefined) {
            this.startAnimation(this.props.animation);
        }
        if (!isSameContent(this.props.content, prevProps.content)) {
            this.updateContentFromProps();
        }
    }

    // Rendering

    render() {
        let topDx = 0;
        const {topRevealT, belowRevealT, topDx: topDxFromAnimation, belowRevealOnLeftSide, enableAnimations} = this.state;
        if (topDxFromAnimation !== undefined) {
            topDx = topDxFromAnimation;
        } else if (this.props.touchStartX !== undefined && this.props.touchCurrentX !== undefined) {
            topDx = this.props.touchCurrentX - this.props.touchStartX;
        }
        return <DeckFC
        topDx={topDx}
        topContent={this.state.topContent}
        belowContent={this.state.belowContent}
        topRevealT={topRevealT}
        belowRevealT={belowRevealT}
        isBelowRevealOnLeftSide={belowRevealOnLeftSide}
        drawWavesOnCard={enableAnimations}
        />;
    }
}

export default Deck;