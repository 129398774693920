import * as React from 'react';
import useResponsive from './hooks/Responsive';
import './Footer.scss';
import { Circle, randomF } from './Clouds'; 
import { colors } from '../identity';
import { Point } from '../util/geometry';
import { startQuestionsId } from './search/StartQuestions';
import { squareLinkId } from './Home';

const topFooter = 120;
const separationHeight = 100;
// If we take pageSize.x as width this does not take into account the vertical scrollbars that reduce the actual page width, creating ugly additional horizontal scrollbars.
const scrollBarProtection = 10;
const cloudShadowSize = 4;

function generateSeparationCloud(size: Point): Circle[] {
    const circles: Circle[] = [];
    if (size[0] === 0) {
        return circles;
    }
    let x = 0;
    while (true) {
        // t is 0 at the middle of the separation, 1 at the extremes
        const t = Math.abs(x - size[0] / 2) / (size[0] / 2);
        const radius = 50 + (1 - t) * size[1] + randomF(20);
        const y = size[1] * t / 2 + radius + cloudShadowSize + Math.random() * 20;
        const center: Point = [x, y];
        if (radius <= 0 ) {
            // Radius can be negative on very small sizes, abort instead of creating invalid circles
            break;
        }
        circles.push({
            center,
            radius,
        });
        x += radius * 1.1;
        if (x > size[0] + 100) {
            break;
        }
        if (circles.length > 1000) {
            // Prevention against some sizes that could trigger inifinite loops
            break;
        }
    }
    return circles;
}

const FooterSeparation: React.FC<{color: string}> = ({color}) => {
    const {pageSize} = useResponsive();
    const height = separationHeight;
    const circles = React.useMemo(() => generateSeparationCloud([pageSize.x, separationHeight]), [pageSize.x]);
    return <svg id="footer-separation" viewBox={`0 0 ${pageSize.x - scrollBarProtection} ${height}`} style={{height, width: '100%'}}>
        <g className="footer-separation-clouds">
            {circles.map((c, i) => <g key={i}>
                <circle key={i} cx={c.center[0]} cy={c.center[1]} r={c.radius} fill={color}/>
                <rect x={c.center[0] - c.radius} y={c.center[1]} width={c.radius * 2} height={height - c.center[1]} fill={color} />
            </g>)}
        </g>
        </svg>;
}

const Footer: React.FC<{visible: boolean}> = ({children, visible}) => {
    const {pageSize} = useResponsive();
    const [startQuestionsBottom, setStartQuestionsBottom] = React.useState<number>(0);
    React.useEffect(() => {
        const startQuestionsEl = document.getElementById(startQuestionsId);
        const linkToSquareEl = document.getElementById(squareLinkId);
        let newBottom = 0;
        if (startQuestionsEl !== null) {
            newBottom = startQuestionsEl.getBoundingClientRect().bottom;
        }
        if (linkToSquareEl !== null) {
            newBottom = Math.max(newBottom, linkToSquareEl.getBoundingClientRect().bottom);
        }
        if (newBottom > 0) {
            setStartQuestionsBottom(newBottom);
        }
    }, [setStartQuestionsBottom]);
    const bestTop = pageSize.y - topFooter + (visible ? 0 : 400);
    // Ensure footer never goes above the start button on desktop or phones with a very small screen.
    const top = Math.max(bestTop, startQuestionsBottom);
    return <footer style={{top}} className={visible ? 'footer-visible' : 'footer-hidden'}>
        <FooterSeparation color={colors.palette.c1}/>
        <div className="footer-content" style={{minHeight: topFooter + 400}}>
            {children}
        </div>
    </footer>;
};

export default Footer;