import * as React from 'react';
import routes from '../../routes';
import { LinkButton } from '../base/Button';
import { useSimpleTranslation } from '../hooks/Translator';

type Props = {
    hasGivenUp: boolean,
}

const GivenUp: React.FC<Props> = ({hasGivenUp}) => {
    const t = useSimpleTranslation();
    if (!hasGivenUp) {
        return null;
    }
    return <LinkButton to={routes.home} filled={true} type="primary" label={t.search.foundIdea.startNewSearchLabel}/>;};

export default GivenUp;