import { IdeaKey } from "../model/idea";

const location = window.location;
const dev = location.hostname === 'localhost' || location.hostname.startsWith('192.168');
const localPort = Number.parseInt(location.port, 10) - 3000 + 8080;
const serverHostAndPort = dev ? `${location.hostname}:${localPort}` : 'api.nebuly.fr';
const securitySuffix = dev ? '' : 's';
const httpUrl = `http${securitySuffix}://${serverHostAndPort}`;
const wsUrl = `ws${securitySuffix}://${serverHostAndPort}`;

export type HttpErrorResponse = {
    type: 'http-error',
    status: number,
    message: string,
    url: string,
}

export type NetworkErrorResponse = {
    type: 'network-error',
    error: any,
    url: string,
}

type SuccessServerResponse<T> = {
    type: 'success',
    content: T
}

export type QueryOptions = RequestInit & {
    forwardErrorToListeners?: boolean
}

export type QueryResponse<T> = HttpErrorResponse | NetworkErrorResponse | SuccessServerResponse<T>;

export type QueryErrorResponse = HttpErrorResponse | NetworkErrorResponse;

export type QuerierState = {
    pendingQueries: number,
    errors: QueryErrorResponse[],
};

export type QuerierListener = (state: QuerierState) => void;

let pendingQueries = 0;
let errors: QueryErrorResponse[] = [];
let listeners: QuerierListener[] = [];

function notifyListeners() {
    listeners.forEach(l => l({pendingQueries, errors}));
}

function addListener(l: QuerierListener) {
    listeners.push(l);
}

function removeListener(l: QuerierListener) {
    listeners = listeners.filter(ll => ll !== l);
}

function onQueryError(error: QueryErrorResponse, options: QueryOptions): QueryErrorResponse {
    if (options.forwardErrorToListeners !== false) {
        errors.push(error);
    }
    return error;
}

async function queryRaw(url: string, inputOptions?: QueryOptions): Promise<QueryResponse<string>> {
    const options: QueryOptions = inputOptions === undefined ? {} : inputOptions;
    // Force credentials on every query.
    options.credentials = 'include';
    try {
        pendingQueries ++;
        notifyListeners();
        const r = await fetch(`${httpUrl}/${url}`, options);
        notifyListeners();
        const status = r.status;
        if (r.ok) {
            const text = await r.text();
            return {type: 'success', content: text};
        } else {
            notifyListeners();
            const text = await r.text();
            return onQueryError({type: 'http-error', message: text, status, url}, options);
        }
    } catch (e) {
        notifyListeners();
        return onQueryError({type: 'network-error', error: e, url}, options);
    } finally {
        pendingQueries --;
        notifyListeners();
    }
};

async function query(url: string, options?: QueryOptions): Promise<string | null> {
    const r = await queryRaw(url, options);
    switch (r.type) {
        case 'success': return r.content;
        default: return null;
    }
}

// T is the type of the server response. We explicit it so that we declare it and can ensure it is migrated correctly
// when it changes server side.
async function queryJson<T>(url: string, options?: QueryOptions): Promise<T | null> {
    const r = await query(url, options);
    if (r === null) {
        return null;
    } else {
        return JSON.parse(r) as T;
    }
};

function postDataAsOptions(data: any): QueryOptions {
    return {
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'},
        method: 'POST',
    };
}

// T is the type of the payload. We explicit it so that we declare it and can ensure it is migrated correctly
// when it changes server side.
async function postData<T>(url: string, data: T, options?: QueryOptions): Promise<string | null> {
    let fullOptions = postDataAsOptions(data);
    if (options !== undefined) {
        fullOptions = {...options, ...fullOptions};
    }
    return await query(url, fullOptions);
}

async function tryQueryJson<FromServer>(url: string, options?: QueryOptions): Promise<QueryResponse<FromServer>> {
    const fullOptions: QueryOptions = options === undefined ? {} : options;
    fullOptions.forwardErrorToListeners = false;
    const r = await queryRaw(url, fullOptions);
    switch (r.type) {
        case 'success': return {type: 'success', content: JSON.parse(r.content)};
        default: return r;
    }
}

async function tryQueryJsonJson<ToServer, FromServer>(url: string, payload: ToServer): Promise<QueryResponse<FromServer>> {
    const options = postDataAsOptions(payload);
    options.forwardErrorToListeners = false;
    const r = await queryRaw(url, options);
    switch (r.type) {
        case 'success': return {type: 'success', content: JSON.parse(r.content)};
        default: return r;
    }
}

async function queryJsonJson<ToServer, FromServer>(url: string, payload: ToServer, options?: QueryOptions): Promise<FromServer | null> {
    const r = await postData(url, payload, options);
    if (r === null) {
        return null;
    } else {
        return JSON.parse(r) as FromServer;
    }
}

function getFullContribIdeaQueryUrl(idea: IdeaKey) {
    return `contrib/idea/${idea.merchant_id}/${encodeURIComponent(idea.id_within_merchant)}`;
}

const exports = {
    postData,
    queryRaw,
    query,
    queryJson,
    queryJsonJson,
    tryQueryJson,
    tryQueryJsonJson,
    postDataAsOptions,
    httpUrl,
    wsUrl,
    addListener,
    removeListener,
    getFullContribIdeaQueryUrl,
};

export default exports;