import * as React from 'react';

import routes from '../../routes';
import { Link } from 'react-router-dom';
import { useSimpleTranslation } from '../hooks/Translator';
import FullLogo from '../FullLogo';

type Props = {}

const PrivacyPolicy: React.FC<Props> = () => {
    const t = useSimpleTranslation();

    return <div className="centered-column">
        <Link to={routes.home}><FullLogo /></Link>
        <h1>{t.about.privacyPolicy.header}</h1>
        <p className="nohmargin">
            Le respect de la vie privée est fondamental sur Nebuly et nous faisons le maximum pour fonctionner sans compromettre votre vie privée:
            </p>
        <div className="legal" >
            <ul>
                <li>Aucun traqueur externe (Google Analytics etc…) n'est utilisé</li>
                <li>Aucun réseau social (Facebook, Instagram, Twitter etc…) n'est utilisé ni référencé</li>
                <li>Nous ne stockons aucun cookie si vous ne créez pas de compte</li>
            </ul>
        </div>
        <h2>Que stockons nous</h2>
        <p className="legal"><strong>Lors d'une recherche de cadeau</strong>: les réponses aux questions et réactions face aux propositions de cadeaux sont stockées.
            Les choix ainsi que la date correspondant sont stockés, <em>sans aucune donnée personnelle</em> (adresse IP, navigateur utilisé, résolution, zone horaire, source du traffic etc…) associée.<br />
            L'identifiant de la recherche n'est stockée que la durée de la recherche, dans le <a href="https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage">session storage.</a></p>
        <p className="legal"><strong>Lors de la création d'un compte</strong>: l'adresse email utlisée lors de la création du compte est stockée.
            Nous utilisons <a href="https://aws.amazon.com/ses/">Amazon SES</a> pour l'envoi des e-mails et transmettons donc les adresses de nos utilisateurs à Amazon SES dans ce but.
            Nous n'envoyons que les e-mails relatifs à la gestion du compte à cette adresse (confirmation de l'adresse, perte de mot de passe).
            Ces adresses e-mail ne sont envoyées à aucune autre tierce-partie.<br />
            Le mot de passe choisi est <a href="https://www.postgresql.org/docs/current/pgcrypto.html">hashé et salté</a> avant le stockage en base de donnée.<br />
            L'email et le mot de passe sont <em>les seules informations stockée</em> avec le compte utilisateur. Nous ne stockons aucune autre donnée personnelle.<br />
            Les contributions (réponses données, idées de cadeau proposées, questions suggérées) sont stockées et reliées au compte de l'utilisateur.
            </p>

        <h2>Versions</h2>
        <div className="legal">
            <ul>
                <li><strong>2019-10-05</strong>: Version initiale</li>
            </ul>
        </div>
        <h2>Comment nous contacter</h2>
        <p className="legal">Pour tout question sur cette politique de gestion des données personnelles, ou si vous voulez que l'on supprime les données de votre compte:<br /> {t.about.legal.emailLine}</p>
        <Link to={routes.home}>{t.home.linkLabel}</Link>
    </div>;
}

export default PrivacyPolicy;