import * as React from 'react';
import { PageSize } from './ResponsiveHOC';
import If from './base/If';
import { Point, addPoints as add, pointsToD, multiplyPoints as multiply } from '../util/geometry';

import "./Clouds.scss";
import useResponsive from './hooks/Responsive';

export type Circle = { 
    center: Point,
    radius: number,
}

type GradientStop = {
    color: string,
    p: number,
}

type GradientProperties = {
    start: Point,
    end: Point,
    stops: GradientStop[],
}

type Cloud = {
    circles: Circle[],
    gradient: GradientProperties,
    opacity: number,
}

type CloudDirection = 'left' | 'right';

const colorToId = (c: string) => c.replace('#', '');
const pointToId = (p: Point) => `${Math.floor(p[0])},${Math.floor(p[1])}`;
const gradientStoptoId = (gs: GradientStop) => `s${gs.p}-${colorToId(gs.color)}`;
const gradientToId = (gp: GradientProperties) => `gradient-${gp.stops.map(gradientStoptoId).join(':')}-${pointToId(gp.start)}-${pointToId(gp.end)}`;
export const randomF = (f: number) => (Math.random() - 0.5) * f;
// Only draw debug in dev.
const drawDebug = process.env.NODE_ENV !== 'production' && false;


const getHeightAboveWhichColorWillBeTooSimilar = (cloudCenterY: number) => {
    return cloudCenterY;
};

function generateCloudCircles(cloudCenter: Point, size: Point): Circle[] {
    const [width, height] = size;

    // Generate 5 circles around an ellipsis:
    const circles: Circle[] = [];
    const coords: Point[] = [
        [-1, 0],
        [-0.5, 0.6],
        [0, 0.2],
        [0.5, 0.6],
        [1, 0],
    ];
    coords.forEach(c => {
        const center: Point = [
            c[0] * width / 2 + cloudCenter[0] + randomF(10),
            c[1] * height / -2 + cloudCenter[1] + randomF(10),
        ];
        circles.push({
            center,
            radius: width / 5 + randomF(8),
        });
    });
    return circles;
}

function generateCloud(cloudCenter: Point, size: Point, startColor: string, endColor: string, direction: CloudDirection, opacity: number): Cloud {
    const [, height] = size;
    const circles = generateCloudCircles(cloudCenter, size);
    const circlesCenter = multiply(circles.reduce<Point>((m, c) => add(c.center, m), [0, 0]), 1. / circles.length);
    const gradientY = height / 2;
    const gradientX = gradientY / 40;
    const gradientStart = add(circlesCenter, [0, gradientY]);
    const gradientEnd = add(circlesCenter, [direction === 'left' ? -gradientX : gradientX, -height]);
    const stops: GradientStop[] = [{p: 0, color: startColor}, {p: 1, color: endColor}];
    return {
        circles,
        opacity,
        gradient: {
            start: gradientStart,
            end: gradientEnd,
            stops,
        }
    };
}


function generateClouds(pageSize: PageSize): Cloud[] {
    const {x: px, y: py} = pageSize;
    const yTop = 0;
    const height = py - yTop;
    const colorStart = '#fff';
    const colorEnd = '#e7f1ff';

    let size: Point = [px / 4, px / 16];
    let y = yTop + height * 0.25;
    const clouds: Cloud[] = [];
    while (true) {
        const left = clouds.length % 2 === 0;
        const opacity = (y - yTop) / (py - yTop) * 0.5 + 0.5;
        const x = left ? px * (0.3 + randomF(0.15)) : px * (0.7 + randomF(0.15));
        clouds.push(generateCloud([x, y], size, colorStart, colorEnd, left ? 'left' : 'right', opacity));
        size = multiply(size, 1.2);
        y = getHeightAboveWhichColorWillBeTooSimilar(y) + size[1];
        if (y > py + size[1]) {
            return clouds;
        }
    }
}

function renderCloud(c: Cloud, i: number) {
    const firstCircle = c.circles[0];
    const lastCircle = c.circles[c.circles.length - 1];
    const socleStart = add(firstCircle.center, [-firstCircle.radius, 0]);
    const socleEnd = add(lastCircle.center, [lastCircle.radius, 0]);
    const socle = [
        socleStart,
        add(socleStart, [0, 100]),
        add(socleEnd, [0, 100]),
        socleEnd
    ];
    const fill = `url(#${gradientToId(c.gradient)})`;
    return <g key={i} style={{opacity: c.opacity}}>
        {c.circles.map((c, i) => <circle key={i} cx={c.center[0]} cy={c.center[1]} r={c.radius} fill={fill}/>)}
        <path d={pointsToD(socle)} fill={fill}/>
    </g>
}

const Background: React.FC<{}> = () => {
    const {pageSize} = useResponsive();
    const [clouds, setClouds] = React.useState<Cloud[]>(generateClouds(pageSize));
    React.useEffect(() => {
        setClouds(generateClouds(pageSize));
    }, [pageSize]);

    const yTop = 0;
    return <svg id="background" viewBox={`0 0 ${pageSize.x} ${pageSize.y}`} style={{height:pageSize.y}}>
        <defs>
            {clouds.map((cloud, i) => {
                    return <linearGradient
                    key={i}
                    gradientUnits="userSpaceOnUse"
                    id={gradientToId(cloud.gradient)}
                    x1={cloud.gradient.start[0]}
                    x2={cloud.gradient.end[0]}
                    y1={cloud.gradient.start[1]}
                    y2={cloud.gradient.end[1]}
                    >
                    {cloud.gradient.stops.map((stop, i) => <stop key={i} offset={stop.p} stopColor={stop.color}/>)}
                    </linearGradient>
            })}
        </defs>
        <If test={drawDebug}>
            <line x1={0} x2={pageSize.x} y1={yTop} y2={yTop} stroke="red" strokeWidth={1} />
        </If>
        {clouds.map((c, i) => renderCloud(c, i))}
    </svg>;
}

export default Background;