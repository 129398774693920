import * as React from 'react';

import If from './base/If';

import "./ServiceWorkerManager.scss";
import { TranslatePath } from '../i18n/Translator';
import { useAccount } from './hooks/Account';
import useTranslation from './hooks/Translator';

type AddMessageFn = (p: TranslatePath, data: any | undefined, shouldUpdate: boolean, timeout: number | null) => void;

const hasServiceWorker = 'serviceWorker' in navigator;

// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the 'N+1' visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

// We want to disable completely the service worker since there are bugs in Firefox
// that prevents it from loading the newer version (every time the admin connects
// this component displays the message that an update is available and the page
// should be reloaded, even after killing the browser and reopening the page)
// service workers don't bring enough advantages versus all the critical bugs
// they produce for now on.
// But this prevents the creation of a PWA so we enable it.
const enableServiceWorker = process.env.NODE_ENV === 'production';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function registerValidSW(swUrl: string, addMessage: AddMessageFn) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      addMessage(l => l.admin.serviceWorker.registered, undefined, false, 2000);
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the old content will have been purged and
                // the fresh content will have been added to the cache.
                // It's the perfect time to display a 'New content is
                // available; please refresh.' message in your web app.
                addMessage(l => l.admin.serviceWorker.updatedWhileControlled, undefined, true, null);
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // 'Content is cached for offline use.' message.
                addMessage(l => l.admin.serviceWorker.updatedWhileUncontrolled, undefined, false, 2000);
              }
            }
          };
        }
      };
    })
    .catch(error => {
      addMessage(l => l.admin.serviceWorker.registrationError, {error}, false, null);
    });
}


function checkValidServiceWorker(swUrl: string, addMessage: AddMessageFn) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type')!.indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, addMessage);
      }
    })
    .catch(() => {
        addMessage(l => l.admin.serviceWorker.offlineMode, undefined, false, 2000);
    });
}

function register(addMessage: AddMessageFn) {
  if (enableServiceWorker && hasServiceWorker) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(
      process.env.PUBLIC_URL!,
      window.location.toString()
    );
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, addMessage);
        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
            addMessage(l => l.admin.serviceWorker.ready, undefined, false, null);
        });
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl, addMessage);
      }
    });
  }
}

async function unregister(): Promise<boolean> {
  try {
    if (hasServiceWorker) {
      const registration = await navigator.serviceWorker.ready;
      return await registration.unregister();
    }
    return false;
  } catch (e) {
    // When testing on localhost we get a SecurityException.
    console.warn("Could not unregister Service Worker.", e);
    return false;
  }
}

async function forceUpdate(addMessage: AddMessageFn) {
  const unregistered: boolean = await unregister();
  if (!unregistered) {
    addMessage(l => l.admin.serviceWorker.unregisterFailed, undefined, false, null);
  } else {
    window.location.reload();
  }
}

const ServiceWorkerManager: React.FC<{}> = () => {
  const [controlMessages, setControlMessages] = React.useState<string[]>([]);
  const [events, setEvents] = React.useState<MessageEvent[]>([]);
  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(false);
  const {translateFull} = useTranslation();
  const {isAdmin} = useAccount();

  const onControlMessage = (p: TranslatePath, data: any | undefined, shouldUpdate: boolean, timeoutMs: number | null) => {
    const message = translateFull(p, data);
    setControlMessages([...controlMessages, message]);
    if (shouldUpdate) {
      setShouldUpdate(true);
    }
    if (timeoutMs !== null) {
      setTimeout(() => {
        setControlMessages(controlMessages => controlMessages.filter(m => m !== message));
      }, timeoutMs)
    }
  };

  React.useEffect(() => {
    const onServiceWorkerMessage = (event: MessageEvent) => {
      setEvents([...events, event]);
    };
    if (hasServiceWorker) {
      navigator.serviceWorker.addEventListener('message', onServiceWorkerMessage);
      // Insert here condition if some user agent do not handle correctly SWs.
      if (enableServiceWorker) {
        register(onControlMessage);
      } else {
          unregister();
      }
    }
    return () => {
      if (hasServiceWorker) {
          navigator.serviceWorker.removeEventListener('message', onServiceWorkerMessage);
      }
    }
    // events and onControlMessage are read in the hook but the hook should not re-run if they change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Always force updates, we could ask nicely later because this causes a refresh of the page
  // but most of the time the user won't notice it and this is better to update.
  React.useEffect(() => {
    if (shouldUpdate) {
      forceUpdate(onControlMessage);
    }
  })

  return <If test={isAdmin && controlMessages.length + events.length > 0}>
  <div id="service-worker-report">
    <div id="service-worker-messages">
      {controlMessages.map((e, i) => <div key={i} className="service-worker-control-message">{e}</div>)}
      {events.map((e, i) => <div key={i} className="service-worker-message">{e.data}</div>)}
    </div>
  </div>
</If>;
};

export default ServiceWorkerManager;