import { ContribEnrichIdeaAction } from './contrib/ideas/enrich';
import { ContribReactionsAction } from './contrib/reactions';
import { ContribQuestionsAddAction } from './contrib/questions/add';
import { ContribIdeasAction } from './contrib/ideas/add';
import { SearchAction } from './search';
import { ContribIdeasCohortAction } from './contrib/ideas/cohort';
import {ModerationAction} from './moderation';
import {AnalysisAction} from './admin/analysis';
import { ShareAction } from './share';

type EmptyAction = {type: ''};

export const CLEAR = 'CLEAR_STORE';
type CLEAR_ACTION_TYPE = typeof CLEAR;

type ClearStateAction = {
    type: CLEAR_ACTION_TYPE
}

export type Action =
ContribReactionsAction |
ContribIdeasAction |
ContribQuestionsAddAction |
ContribEnrichIdeaAction |
ContribIdeasCohortAction |
SearchAction |
ModerationAction |
AnalysisAction |
ClearStateAction |
ShareAction |
EmptyAction