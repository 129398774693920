import * as React from 'react';

import Range from './Range';
import { posToPrice, priceToPos, sliderPriceMarks, priceTipFormatter, MAX_POS, CENTS } from '../../model/price';
import useTranslation from '../hooks/Translator';
import { coalesce } from '../../util/common';

const PriceRange: React.FC<{
    priceMinCents: number,
    priceMaxCents: number,
    /** Force a minimum distance between the min price and max price if set. */
    pushable?: number,
    onPriceChange?: (minPrice: number, maxPrice: number) => void,
}> = ({priceMinCents, priceMaxCents, pushable, onPriceChange}) => {
    const {translateFull} = useTranslation();
    if (onPriceChange !== undefined) {
        return <div>
            <Range
            marks={sliderPriceMarks}
            included={true}
            min={0}
            max={MAX_POS}
            step={1}
            pushable={coalesce(pushable, 1)}
            value={[Math.floor(priceToPos(priceMinCents / CENTS)), Math.floor(priceToPos(priceMaxCents / CENTS))]}
            tipFormatter={(v: number) => priceTipFormatter(translateFull, v)}
            onChange={pos => onPriceChange(posToPrice(pos[0])*CENTS, posToPrice(pos[1]) * CENTS)}/>
            </div>
    }
    if (priceMinCents === priceMaxCents) {
        return <span>{priceMinCents/ CENTS} €</span>;
    } else {
        return <span>{priceMinCents / CENTS} - {priceMaxCents / CENTS} €</span>;
    }
};

export default PriceRange;