import * as React from 'react';

import {Range as CoreRange, createSliderWithTooltip, SliderProps} from 'rc-slider';
import { GenericSliderProps } from 'rc-slider/lib/interface';

const LibraryRange = createSliderWithTooltip(CoreRange);

export type WithTooltipProps = {
    tipFormatter: (pos: number) => string,
}

/**
 * Override the props definition of rc-slider which are incorrect for pushable that can be a number.
 */
export type PropsOverride = GenericSliderProps & {
    value?: number[];
    defaultValue?: number[];
    count?: number;
    min?: number;
    max?: number;
    allowCross?: boolean;
    pushable?: boolean | number;
    onChange?: (value: number[]) => void;
    onBeforeChange?: (value: number[]) => void;
    onAfterChange?: (value: number[]) => void;
    reverse?: boolean;
    vertical?: boolean;
    marks?: Record<number, React.ReactNode | {
        style?: React.CSSProperties;
        label?: string;
    }>;
    step?: number;
    threshold?: number;
    prefixCls?: string;
    included?: boolean;
    disabled?: boolean;
    trackStyle?: React.CSSProperties[];
    handleStyle?: React.CSSProperties[];
    tabIndex?: number | Array<number>;
    ariaLabelGroupForHandles?: string | Array<string>;
    ariaLabelledByGroupForHandles?: string | Array<string>;
    ariaValueTextFormatterGroupForHandles?: string | Array<string>;
    handle?: SliderProps['handle'];
    draggableTrack?: boolean;
}
class Range extends React.Component<WithTooltipProps & PropsOverride> {
    public render() {
        // @ts-ignore
        return <LibraryRange {...this.props} />;
    }
}

export default Range;