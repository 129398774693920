import { Question} from '../../../model/question';
import { Idea } from '../../../model/idea';
import { ContribReaction } from '../../../model/reaction';
import { ReactionsAddIdeasSource } from '../../store';

export const SET_AVAILABLE_QUESTIONS = 'CONTRIB_REACTIONS_SET_AVAILABLE_QUESTIONS';
export type CONTRIB_REACTIONS_SET_AVAILABLE_QUESTIONS = typeof SET_AVAILABLE_QUESTIONS;

export const SET_QUESTION = 'CONTRIB_REACTIONS_SET_QUESTION';
export type CONTRIB_REACTIONS_SET_QUESTION = typeof SET_QUESTION;

export const SET_IDEAS = 'CONTRIB_REACTIONS_SET_IDEAS';
export type CONTRIB_REACTIONS_SET_IDEAS = typeof SET_IDEAS;

export const SET_REACTIONS = 'CONTRIB_REACTIONS_SET_REACTIONS';
export type CONTRIB_REACTIONS_SET_REACTIONS = typeof SET_REACTIONS;

export const HIDE_ALREADY_REACTED = 'CONTRIB_REACTIONS_HIDE_ALREADY_REACTED';
export type CONTRIB_REACTIONS_HIDE_ALREADY_REACTED = typeof HIDE_ALREADY_REACTED;

export const SET_IDEAS_SOURCE = 'CONTRIB_REACTIONS_SET_IDEAS_SOURCE';
export type CONTRIB_REACTIONS_SET_IDEAS_SOURCE = typeof SET_IDEAS_SOURCE;

export type ContribReactionsSetAvailableQuestions = {
    type: CONTRIB_REACTIONS_SET_AVAILABLE_QUESTIONS,
    questions: Question[]
}

export type ContribReactionsSetQuestion = {
    type: CONTRIB_REACTIONS_SET_QUESTION,
    question: Question
}

export type ContribReactionsSetIdeas = {
    type: CONTRIB_REACTIONS_SET_IDEAS,
    ideas: Idea[],
    question: number,
}

export type ContribReactionsSetReactions = {
    type: CONTRIB_REACTIONS_SET_REACTIONS,
    reactions: ContribReaction[],
}

export type ContribReactionsHideAlreadyReacted = {
    type: CONTRIB_REACTIONS_HIDE_ALREADY_REACTED,
    hide: boolean
}

export type ContribReactionsSetIdeasSource = {
    type: CONTRIB_REACTIONS_SET_IDEAS_SOURCE,
    source: ReactionsAddIdeasSource,
}

export type ContribReactionsAction =
ContribReactionsSetAvailableQuestions |
ContribReactionsSetQuestion |
ContribReactionsSetIdeas |
ContribReactionsSetReactions |
ContribReactionsSetIdeasSource |
ContribReactionsHideAlreadyReacted ;