import * as React from 'react';
import {ComponentType, ComponentSpec} from 'react';
import createReactClass from 'create-react-class';
import { Subtract, getDisplayName } from '../util/HOC';

export type PropsFromAnimation = {
    triggerAnimation: (midCallback: () => boolean, endCallback: () => void) => void,
    t: number | undefined,
}

const DEBUG = false;

// https://math.stackexchange.com/questions/1391152/ease-in-function?rq=1
const easeInOut = (t: number, a: number) => Math.pow(t, a) / (Math.pow(t, a) + Math.pow(1 - t, a));
const easeIn = (t: number, a: number) => 2 * easeInOut(t / 2, a);
const easeOut = (t: number, a:number) => 2 * easeInOut(0.5 + t / 2, a) - 1;
// The default ease factor.
const DEF = 2;

export const animateEaseIn = function(t: number, startT: number, endT: number, startV: number, endV: number) {
  const p = easeIn(Math.min(1, Math.max(0, (t - startT) / (endT - startT))), DEF);
  return startV * (1 - p) + endV * p;
};

export const animateEaseOut = function(t: number, startT: number, endT: number, startV: number, endV: number) {
  const p = easeOut(Math.min(1, Math.max(0, (t - startT) / (endT - startT))), DEF);
  return startV * (1 - p) + endV * p;
};

export const animateEaseInOut = function(t: number, startT: number, endT: number, startV: number, endV: number) {
    const p = easeInOut(Math.min(1, Math.max(0, (t - startT) / (endT - startT))), DEF);
    return startV * (1 - p) + endV * p;
};

export const animate2EaseMiddle = function(t: number, startT1: number, endT1: number, startT2: number, endT2: number, startV: number, midV: number, endV: number) {
if (t < startT2) {
    return animateEaseOut(t, startT1, endT1, startV, midV);
}
return animateEaseIn(t, startT2, endT2, midV, endV);
};

export const animate2EaseInOut = function(t: number, startT1: number, endT1: number, startT2: number, endT2: number, startV: number, midV: number, endV: number) {
if (t < startT2) {
    return animateEaseIn(t, startT1, endT1, startV, midV);
}
return animateEaseOut(t, startT2, endT2, midV, endV);
};

function wrapAnimation<P extends object>(duration: number, Component: ComponentType<P>) : ComponentType<Subtract<P, PropsFromAnimation>>{
    const animationDuration = DEBUG ? duration * 10 : duration;
    const spec: ComponentSpec<Subtract<P, PropsFromAnimation>, {}> = {
        displayName: `AnimationHOC(${getDisplayName(Component)})`,

        // Business

        getAnimationT() {
            return (Date.now() - this.state.timeLastSuccess) / animationDuration;
        },

        animate(midCallback: () => boolean, endCallback: () => void) {
            this.forceUpdate();
            const t = this.getAnimationT();
            if (t >= 0 && t <= 1.2) {
                if (t > 0.5 && midCallback !== undefined) {
                    // The midCallback can stop the animation.
                    // This is used for some components that change the route (so unmount themself) there, we don't want to continue
                    // the animation in this situation
                    if (midCallback()) {
                        requestAnimationFrame(() => {
                            this.animate(undefined, endCallback);
                        })
                    }

                } else {
                    requestAnimationFrame(() => {
                        this.animate(midCallback, endCallback);
                    });
                }
            } else {
                if (endCallback !== undefined) {
                    endCallback();
                }
            }
        },

        // Lifecycle

        getInitialState() {
            return {
                timeLastSuccess: -animationDuration
            }
        },

        componentDidMount() {
            if (DEBUG) {
                this.triggerAnimation(() => {}, () => {});
            }
        },

        // Event handling
        triggerAnimation(midCallback: () => void, endCallback: () => void) {
            this.setState({timeLastSuccess: Date.now()});
            requestAnimationFrame(() => {
                this.animate(midCallback, endCallback);
            });
        },

        // Rendering

        render() {
            let t = this.getAnimationT();
            if (t < 0 || t > 1) {
                t = undefined;
            }
            return <Component {...this.props} t={t} triggerAnimation={(midCallback: () => boolean, endCallback: () => void) => this.triggerAnimation(midCallback, endCallback)}/>
        }
    }
    return createReactClass(spec);
}

export default wrapAnimation;