import { ModerationContribGenericIdea, ModerationContribMerchantIdea } from "../../model/idea";
import { ModerationContribQuestion } from "../../model/question";
import { ServerUserReactionsContribution, ServerUserReaction } from "../../model/reaction";

export const SET_GENERIC_IDEAS = 'MODERATION_SET_GENERIC_IDEAS';
export type MODERATION_SET_GENERIC_IDEAS = typeof SET_GENERIC_IDEAS;

export const SET_MERCHANT_IDEAS = 'MODERATION_SET_MERCHANT_IDEAS';
export type MODERATION_SET_MERCHANT_IDEAS = typeof SET_MERCHANT_IDEAS;

export const SET_QUESTIONS = 'MODERATION_SET_QUESTIONS';
export type MODERATION_SET_QUESTIONS = typeof SET_QUESTIONS;

export const SET_REACTIONS_SUMMARY = 'MODERATION_SET_REACTIONS_SUMMARY';
export type MODERATION_SET_REACTIONS_SUMMARY = typeof SET_REACTIONS_SUMMARY;

export const SET_REACTIONS_USER = 'MODERATION_SET_REACTIONS_USER';
export type MODERATION_SET_REACTIONS_USER = typeof SET_REACTIONS_USER;

export type ModerationSetGenericIdeas = {
    type: MODERATION_SET_GENERIC_IDEAS,
    ideas: ModerationContribGenericIdea[],
}

export type ModerationSetMerchantIdeas = {
    type: MODERATION_SET_MERCHANT_IDEAS,
    ideas: ModerationContribMerchantIdea[],
}

export type ModerationSetQuestions = {
    type: MODERATION_SET_QUESTIONS,
    questions: ModerationContribQuestion[],
}

export type ModerationSetReactionsSummary = {
    type: MODERATION_SET_REACTIONS_SUMMARY,
    summary: ServerUserReactionsContribution[],
}

export type ModerationSetReactionsUser = {
    type: MODERATION_SET_REACTIONS_USER,
    userId: number,
    reactions: ServerUserReaction[],
}

export type ModerationAction =
ModerationSetGenericIdeas |
ModerationSetMerchantIdeas |
ModerationSetReactionsSummary |
ModerationSetReactionsUser |
ModerationSetQuestions;
