import * as React from 'react';
import { useQueryJson, QueryHookResult, isPendingQuery, isFailedQuery } from '../../hooks/Query';
import { RouteComponentProps } from 'react-router-dom';
import { useSimpleTranslation } from '../../hooks/Translator';
import { LocaleContent } from '../../../i18n/locales/types';

type PropsFromRouter = {
    token: string // the validation token
}

type Props = RouteComponentProps<PropsFromRouter>;

function getMainText(t: LocaleContent, validationResult: QueryHookResult<void>) {
    if (isPendingQuery(validationResult)) {
        return t.common.loading;
    }
    if (isFailedQuery(validationResult)) {
        return t.search.alert.emailValidation.failure;
    }
    return t.search.alert.emailValidation.success;
}

const AlertEmailValidation: React.FC<Props> = ({match: {params: {token}}}) => {
    const {result: validationResult} = useQueryJson<void>(`public/alert/validate/${token}`, {transformResponse: s => s});
    const t = useSimpleTranslation();
    const mainText = getMainText(t, validationResult);
    return <div className="centered-column">
        <h1>{t.search.alert.emailValidation.title}</h1>
        <p className="p-wrap">{mainText}</p>
    </div>;
}

export default AlertEmailValidation;