import * as React from 'react';

import { Link } from 'react-router-dom';
import routes from '../../routes';

import './Legal.scss';
import { useSimpleTranslation } from '../hooks/Translator';
import FullLogo from '../FullLogo';

type Props = {}

const Legal: React.FC<Props> = () => {
    const t = useSimpleTranslation();

    return <div className="centered-column">
        <Link to={routes.home}><FullLogo /></Link>
        <h1>{t.about.legal.header}</h1>
        <p>
            {t.about.legal.socialLine}<br />
            {t.about.legal.addressLine}<br />
            {t.about.legal.emailLine}<br />
            {t.about.legal.tvaLine}<br />
            {t.about.legal.sirenLine}
        </p>
        <p>
            <Link to={routes.about.privacyPolicy}>{t.about.privacyPolicy.linkLabel}</Link>
        </p>
        <Link to={routes.home}>{t.home.linkLabel}</Link>
    </div>;
}

export default Legal;