import * as React from 'react';
import H from 'history';
import { connect } from 'react-redux';
import classNames from 'classnames';

import wrapI18N, { PropsFromI18N } from '../../i18n/TranslatorHOC';
import wrapResponsive, { PropsFromResponsive, PageSize } from '../ResponsiveHOC';
import { StoreState } from '../../redux/store';
import GenderPicker from '../GenderPicker';
import {searchAgeMax, searchAgeMinReachable, searchAgeMin, getSliderAgeMarks, ageToPos, allowSearchChildrenGifts, posToAge, ageTipFormatter} from '../../model/age';
import { SearchSetGender, SET_GENDER, SearchSetAge, SearchSetBudget, SET_BUDGET, SET_STEP, SET_HINTS } from '../../redux/actions/search';
import Slider from '../base/Slider';
import { SET_AGE } from '../../redux/actions/search';

import './StartQuestions.scss';
import routes from '../../routes';
import PriceRange from '../base/PriceRange';
import If from '../base/If';
import { UserSex } from '../../model/gender';
import QuestionMark from './QuestionMark';
import { MultiPartStartStep, stepsOrder } from '../../redux/reducers/search';
import Button, { LinkButton } from '../base/Button';
import Deck, {Animation} from './Deck';
import { getStartStateFromLocation } from './KeywordLink';
import { sizes } from './Layout';
import WelcomeMessage from './WelcomeMessage';

type PropsFromParent = {
    history: H.History,
}

type PropsFromState = {
    hasChosenGender: boolean,
    gender: UserSex,
    age: number,
    budgetMinCents: number,
    budgetMaxCents: number,
    hints: string,
    step: MultiPartStartStep,
}

type PropsFromDispatch = {
    setGender: (gender: UserSex) => void,
    setAge: (age: number) => void,
    setBudget: (priceMinCents: number, priceMaxCents: number) => void,
    setHints: (hints: string) => void,
    setStep: (step: MultiPartStartStep) => void,
}

const swipeDuration = 800;
// Force a minimum size of price range.
const priceRangePushable = 4;

const setGenderActionCreator = (gender: UserSex): SearchSetGender => ({gender, type: SET_GENDER});
const setAgeActionCreator = (age: number): SearchSetAge => ({age, type: SET_AGE});
const setBudgetActionCreator = (priceMinCents: number, priceMaxCents: number): SearchSetBudget => ({priceMinCents, priceMaxCents, type: SET_BUDGET});
const setStepActionCreator = (step: MultiPartStartStep) => ({step, type: SET_STEP});
const setHintsActionCreator = (hints: string) => ({hints, type: SET_HINTS});

export const startQuestionsId = 'start-questions';
type Props = PropsFromI18N & PropsFromState & PropsFromDispatch & PropsFromResponsive & PropsFromParent;

export function propagateSearchAndHashFromLocation(originalUrl: string) {
    const location = window.location;
    let searchToAppend = location.search;
    if (originalUrl.includes('?')) {
        searchToAppend = searchToAppend.replace('?', '&');
    }
    return originalUrl + searchToAppend + location.hash;
}

export function isMultiPart(pageSize: PageSize) {
    return pageSize.x < 800 || pageSize.y < 450;
}

async function isSquareRelevant(hints: string): Promise<boolean> {
    return false;
    /* 
    TODO disabled for christmas as it is not really relevant. This is more for birthdays were ideas can be shared.
    And for now the performance of the square is not that great.
    const relevance = await querier.postData<string>('public/square/rv', hints);
    if (relevance === null) {
        return false; 
    }
    return Number.parseInt(relevance, 10) > 1;
    */
}

type State = {
    deckAnimation: Animation | undefined,
    warmDeck: boolean
}

class StartQuestions extends React.Component<Props, State> {



    // LifeCycle

    constructor(props: Props) {
        super(props);
        this.onChangeBudget = this.onChangeBudget.bind(this);
        this.state = {
            deckAnimation: undefined,
            warmDeck: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({warmDeck: true});
        }, 500);
        const {age, gender} = getStartStateFromLocation();
        if (age !== undefined) {
            this.onChangeAge(age);
        }
        if (gender !== undefined) {
            this.props.setGender(gender);
        }
    }

    // Event Handling

    onMoveToStep(direction: 1 | -1, halfPageWidth: number) {
        const currentStep = this.props.step;
        let nextStep = stepsOrder[(stepsOrder.indexOf(currentStep) + direction + stepsOrder.length) % stepsOrder.length];
        const contentBelow = this.getStepContent(nextStep);
        this.setState({deckAnimation: {duration: swipeDuration, effect: 'swipe', toLeft: direction === -1, startDx: 0, contentBelow, halfPageWidth}}, () => {
            this.props.setStep(nextStep);
        });
    }

    onChangeBudget(priceMin: number, priceMax: number) {
        this.props.setBudget(priceMin, priceMax);
    }

    onChangeAgePos(agePos: number) {
        const age = posToAge(agePos);
        this.onChangeAge(age);
    }

    onChangeAge(age: number) {
        this.props.setAge(age);
        if (!allowSearchChildrenGifts && age < searchAgeMin) {
            // Show the explanation then move up the age limit.
            setTimeout(() => {
                if (this.props.age < searchAgeMin) {
                    this.props.setAge(searchAgeMin)
                }
            }, 3000);
        }
    }

    async onStartFromMultiPart() {
        this.props.setStep(stepsOrder[0]);
        const squareRelevant = await isSquareRelevant(this.props.hints);
        if (squareRelevant) {
            this.props.history.push(propagateSearchAndHashFromLocation(routes.square.changeInput(this.props.hints)));
        } else {
            this.props.history.push(propagateSearchAndHashFromLocation(routes.search));
        }
    }

    // Rendering

    private renderMoveToPreviousStep(halfPageWidth: number) {
        return <Button type="positive" filled={false} onClick={() => this.onMoveToStep(-1, halfPageWidth)} label={this.props.translate(l => l.search.start.previousPartLabel)}/>
    }

    private renderMoveToNextStep(halfPageWidth: number) {
        return <Button type="positive" filled={true} onClick={() => this.onMoveToStep(1, halfPageWidth)} label={this.props.translate(l => l.search.start.nextPartLabel)}/>
    }

    private renderChangeStep(halfPageWidth: number) {
        if (this.props.step === 'gender') {
            return <Button type="positive" filled onClick={() => this.onMoveToStep(-1, halfPageWidth)} label={this.props.translate(l => l.search.start.previousPartLabel)}/>;
        }
        if (this.props.age < searchAgeMin) {
            return this.renderLowAgeExplanation();
        }
        const currentStep = this.props.step;
        if (currentStep === stepsOrder[0]) {
            return <Button type="positive" className="multi-part-start" filled={true} onClick={() => this.onMoveToStep(1, halfPageWidth)} label={this.props.translate(l => l.search.start.startMultiPartLabel)}/>;
        }
        let nextStep = null;
        if (currentStep === stepsOrder[stepsOrder.length - 1]) {
            nextStep = <Button type="positive" filled={true} onClick={() => this.onStartFromMultiPart()} label={this.props.translate(l => l.search.start.startLabel)}/>;
        } else {
            nextStep = this.renderMoveToNextStep(halfPageWidth);
        }
        return <div className="buttons-row">
            {this.renderMoveToPreviousStep(halfPageWidth)}
            {nextStep}
        </div>;
    }

    private getStepContent(step: MultiPartStartStep) {
        switch (step) {
            case 'presentation': return undefined;
            case 'gender': return this.props.translate(l => l.search.start.header);
            case 'age': return this.props.translate(l => l.search.start.ageHeader[this.props.gender]);
            case 'budget': return this.props.translate(l => l.search.start.budgetHeader);
            case 'hints': return this.props.translate(l => l.search.start.hintsHeader);
        }
    }

    private renderMultiPartQuestion(step: MultiPartStartStep) {
        const content = this.getStepContent(step);
        return <Deck
            content={content}
            animation={this.state.deckAnimation}
            touchStartX={undefined}
            touchCurrentX={undefined}
            onAnimationEnd={() => this.setState({deckAnimation: undefined})}
            />;
    }

    private renderLowAgeExplanation() {
        return <div id="search-start-low-age-explanation">
        <If test={!allowSearchChildrenGifts && this.props.age < searchAgeMin}>
            {this.props.translate(l => l.search.start.lowAgeExplanation)}
        </If>
    </div>;
    }

    private renderAgeSlider() {
        const sliderAgeMarks = getSliderAgeMarks(searchAgeMinReachable, this.props.translateFull);
        const minPos = ageToPos(searchAgeMinReachable);
        return <Slider 
        min={minPos} 
        max={ageToPos(searchAgeMax)} 
        included={false} 
        marks={sliderAgeMarks} 
        value={ageToPos(this.props.age)} 
        tipFormatter={(pos: number) => ageTipFormatter(pos, searchAgeMax, this.props.translateFull, this.props.translate)} 
        onChange={pos => this.onChangeAgePos(pos)}/>;
    }

    private renderHintsInput(inMonoPart: boolean) {
        return <textarea
        defaultValue={this.props.hints}
        className={`search-start-hints search-start-hints-${inMonoPart ? 'mono' : 'multi'}-step`}
        title={this.props.translate(l => l.search.start.hintsPlaceholder)}
        placeholder={this.props.translate(l => l.search.start.hintsPlaceholder)}
        onChange={e => this.props.setHints(e.target.value)}/>
    }

    private renderMultiPartInput(halfPageWidth: number, forStep: MultiPartStartStep) {
        switch (forStep) {
            case 'presentation': return null;
            case 'gender': return <GenderPicker gender={this.props.gender} setGender={g => {this.props.setGender(g); this.onMoveToStep(1, halfPageWidth);}} compact highlightValue={this.props.hasChosenGender}/>;
            case 'age': return this.renderAgeSlider();
            case 'budget': return <PriceRange pushable={priceRangePushable} priceMinCents={this.props.budgetMinCents} priceMaxCents={this.props.budgetMaxCents} onPriceChange={this.onChangeBudget}/>;
            case 'hints': return this.renderHintsInput(false);
        }
    }

    private renderMultiPartInputTransitionFriendly(halfPageWidth: number, forStep: MultiPartStartStep) {
        const currentStep = this.props.step;
        const startQuestionPartInputTransitionFriendlyAbbreviated = 'sqpitf';
        const visibilityClassName = currentStep === forStep ? "visible" : "hidden";
        return <div className={`${startQuestionPartInputTransitionFriendlyAbbreviated} ${startQuestionPartInputTransitionFriendlyAbbreviated}-${visibilityClassName}`}>
            {this.renderMultiPartInput(halfPageWidth, forStep)}
        </div>
    }

    private renderHiddenDeckForWarming() {
        if (!this.state.warmDeck) {
            return null;
        }
        return <div style={{position: 'absolute', opacity: 0, pointerEvents: 'none', zIndex: -4, top: 0, left: 0, width: 0, height: 0, overflow: 'hidden'}}>
            <Deck content={undefined} animation={undefined} touchStartX={undefined} touchCurrentX={undefined} onAnimationEnd={() => {}}/>
        </div>;
    }

    private renderMultiPart(halfPageWidth: number) {
        const {step} = this.props;
        const top = step === 'presentation' ? <p className="p-wrap polished"><WelcomeMessage /></p> : this.renderMultiPartQuestion(step);
        const canStealSpaceFromDeck3dOverflowHeight = step !== 'presentation';
        return <div id="start-questions" className="column start-questions-part">
            {top}
            <div className="start-questions-part-input" style={{marginTop: canStealSpaceFromDeck3dOverflowHeight ? -sizes.overflowBottom3d : 0}}>
                {this.renderMultiPartInputTransitionFriendly(halfPageWidth, 'gender')}
                {this.renderMultiPartInputTransitionFriendly(halfPageWidth, 'age')}
                {this.renderMultiPartInputTransitionFriendly(halfPageWidth, 'budget')}
                {this.renderMultiPartInputTransitionFriendly(halfPageWidth, 'hints')}
            </div>
            {this.renderChangeStep(halfPageWidth)}
            {this.renderHiddenDeckForWarming()}
        </div>;
    }

    private renderMonoPart() {
        const {budgetMinCents, budgetMaxCents, translate, gender, setGender} = this.props;
        const afterGenderPickedClassName = classNames({
            'start-questions-waiting-gender': true,
            'start-questions-has-chosen-gender': this.props.hasChosenGender,
            'start-questions-has-not-chosen-gender': !this.props.hasChosenGender
        });
        return <div className="centered-column" id={startQuestionsId}>
            <p className="p-wrap polished"><WelcomeMessage /></p>
            <form className="centered-column">
                <div className="start-questions-line start-question-line-gender">
                    <label>{translate(l => l.search.start.header)}<QuestionMark/></label>
                    <div className="start-questions-input">
                        <GenderPicker gender={gender} setGender={g => setGender(g)} compact={false} highlightValue={this.props.hasChosenGender} />
                    </div>
                </div>
                <div className={afterGenderPickedClassName}>
                    <div className="start-questions-line start-question-line-age">
                        <label>{translate(l => l.search.start.ageHeader[gender])}<QuestionMark/></label>
                        <div className="start-questions-input">
                            {this.renderAgeSlider()}
                        </div>
                    </div>
                    {this.renderLowAgeExplanation()}
                    <div className="start-questions-line start-question-line-budget">
                        <label>{translate(l => l.search.start.budgetHeader)}<QuestionMark/></label>
                        <div className="start-questions-input">
                            <PriceRange pushable={priceRangePushable} priceMinCents={budgetMinCents} priceMaxCents={budgetMaxCents} onPriceChange={this.onChangeBudget}/>
                        </div>
                    </div>
                    <div className="start-questions-line">
                        <label>{translate(l => l.search.start.hintsHeader)}<QuestionMark/></label>
                        <div className="start-questions-input start-question-input-hint">
                            {this.renderHintsInput(true)}
                        </div>
                    </div>
                    <div className="start-questions-submit-line">
                        <LinkButton type="positive" filled={true} to={propagateSearchAndHashFromLocation(routes.search)} label={this.props.translate(l => l.search.start.startLabel)}/>
                    </div>
                </div>
        </form>
        </div>;
    }

    public render() {
        return isMultiPart(this.props.pageSize) ? this.renderMultiPart(this.props.pageSize.x / 2) : this.renderMonoPart();
    }
}

const mapStateToPros = (state: StoreState) => ({
    gender: state.search.gender,
    age: state.search.age,
    budgetMinCents: state.search.priceMinCents,
    budgetMaxCents: state.search.priceMaxCents,
    step: state.search.startStep,
    hasChosenGender: state.search.hasChosenGender,
    hints: state.search.hints,
});

const mapDispatchToProps = {
    setGender: setGenderActionCreator,
    setAge: setAgeActionCreator,
    setBudget: setBudgetActionCreator,
    setStep: setStepActionCreator,
    setHints: setHintsActionCreator,
};

export default connect(mapStateToPros, mapDispatchToProps)(wrapResponsive(wrapI18N(StartQuestions)));