import * as React from 'react';
import wrapI18N, { PropsFromI18N } from '../../i18n/TranslatorHOC';

type Props = PropsFromI18N;

const QuestionMark: React.FC<Props> = (props) => {
    // We need to inject a &nbsp; in French to avoid a ? alone on his line.
    return <span className="question-mark" dangerouslySetInnerHTML={{__html: props.translate(l => l.question.mark)}}></span>;
};

export default React.memo(wrapI18N(QuestionMark));