import * as React from 'react';
import H from 'history';
import {useDispatch} from 'react-redux';

import { TranslateSimpleFn, TranslateFn } from '../../i18n/TranslatorHOC';
import { Idea,  } from '../../model/idea';
import { Feedback } from '../../model/feedback';
import { SearchFlow } from '../../server/SearchFlow';
import routes from '../../routes';
import  Button, { LinkButton } from '../base/Button';
import FoundIdeaFeedback from './FoundIdeaFeedback';
import { UserSex } from '../../model/gender';
import useTranslation from '../hooks/Translator';
import { Dispatch } from 'redux';
import { SET_PAYLOAD } from '../../redux/actions/share';
import { Action } from '../../redux/actions';

import './FoundIdeaBelowDeck.scss';

type Props = {
    idea: Idea,
    asQuestion: boolean,
    flow: SearchFlow,
    isAcceptedByUser: boolean,
    searchSex: UserSex,
    animateHideAnswered: () => void,
    onRequiresLargeHeight: (requireLargeHeight: boolean) => void,
    history: H.History,
}

function shouldAnimateHide(f: Feedback): boolean {
    switch (f) {
        case 'ClickedOnIdea': return false;
        case 'GoodIdea': return false;
        case 'SawIdea': return false;

        case 'IDontKnow': return true;
        case 'BadIdea': return true;
        case 'HasAlready': return true;
        case 'InconsistentIdea': return true;
        case 'YouCanDoBetter': return true;
        case 'YouAreClose': return true;
        case 'Reset': return true;
    }
}

// Event Handling

export function goToIdea(idea: Idea, sessionId: string) {
    const win = window.open(routes.gotoIdea.fn(idea.content, sessionId), '_blank');
    if (win !== null) {
        win.focus();
    }
}

export function onClickToIdea(flow: SearchFlow, idea: Idea, aaq: boolean) {
    flow.onPickFeedback(idea.key, 'ClickedOnIdea', aaq);
    goToIdea(idea, flow.id);
}

function onPickFeedback(flow: SearchFlow, idea: Idea, k: Feedback, aaq: boolean, animateHideAnswered: () => void, onRequiresLargeHeight: (b: boolean) => void) {
    flow.onPickFeedback(idea.key, k, aaq);
    onRequiresLargeHeight(false);
    if (aaq || shouldAnimateHide(k)) {
        animateHideAnswered();
    }
}

// Rendering

function renderAlertShare(translate: TranslateSimpleFn, translateFull: TranslateFn, history: H.History, dispatch: Dispatch<Action>, flowId: string, idea: Idea, isBadIdea: boolean) {
    return <div className="centered-button-row">
        <LinkButton
            label={translate(l => l.search.alert.linkToLabel)}
            type="primary"
            filled={false}
            small
            to={routes.alert.create.fn(flowId)}/>
        <Button
            label={translate(l => l.search.share.linkToLabel)}
            type="primary"
            disabled={isBadIdea}
            filled={false}
            small
            onClick={() => {
                const url = idea.content;
                dispatch({type: SET_PAYLOAD, payload: {
                    title: translate(l => l.search.share.payload.title),
                    text: translateFull(l => l.search.share.payload.text, {url: encodeURIComponent(url)}),
                    url
                }});
                history.push(routes.share.home);
            }}/>
    </div>;
}

const FoundIdeaBelowDeck: React.FC<Props> = (props: Props) => {
    const [isBadIdea, setIsBadIdea] = React.useState<boolean>(false);
    const {translate, translateFull} = useTranslation();
    const dispatch = useDispatch();

    const {flow, idea, asQuestion, animateHideAnswered, searchSex, isAcceptedByUser, history, onRequiresLargeHeight} = props;
    if (isAcceptedByUser) {
        return <div className="found-idea-footer">
            <span id="found-idea-footer-text">{translate(l => l.search.foundIdea.goodIdeaFooter)}</span>
            <LinkButton to={routes.home} filled={true} type="primary" label={translate(l => l.search.foundIdea.startNewSearchLabel)}/>
            {renderAlertShare(translate, translateFull, history, dispatch, flow.id, props.idea, isBadIdea)}
        </div>;
    } else {
        return <div className="found-idea-footer">
            <FoundIdeaFeedback
            searchSex={searchSex}
            asQuestion={asQuestion}
            onPickFeedback={(f) => onPickFeedback(flow, idea, f, asQuestion, animateHideAnswered, onRequiresLargeHeight)}
            onRequireLargeHeight={isLarge => onRequiresLargeHeight(isLarge)}
            onPickFirstAnswer={(v) => setIsBadIdea(v === 'no')} />
            {renderAlertShare(translate, translateFull, history, dispatch, flow.id, props.idea, isBadIdea)}
        </div>;
    }
}

export default FoundIdeaBelowDeck;