import { UserSex } from '../../model/gender';
import { MultiPartStartStep } from '../reducers/search';

export const SET_GENDER = 'SEARCH_SET_GENDER';
export type SEARCH_SET_GENDER = typeof SET_GENDER;

export const SET_AGE = 'SEARCH_SET_AGE';
export type SEARCH_SET_AGE = typeof SET_AGE;

export const SET_BUDGET = 'SEARCH_SET_BUDGET';
export type SEARCH_SET_BUDGET = typeof SET_BUDGET;

export const SET_STEP = 'SEARCH_SET_STEP';
export type SEARCH_SET_STEP = typeof SET_STEP;

export const SET_HINTS = 'SET_HINTS';
export type SEARCH_SET_HINTS = typeof SET_HINTS;

export type SearchSetGender = {
    type: SEARCH_SET_GENDER,
    gender: UserSex,
}

export type SearchSetAge = {
    type: SEARCH_SET_AGE,
    age: number,
}

export type SearchSetBudget = {
    type: SEARCH_SET_BUDGET,
    priceMinCents: number,
    priceMaxCents: number,
}

export type SearchSetStep = {
    type: SEARCH_SET_STEP,
    step: MultiPartStartStep,
}

export type SearchSetHints = {
    type: SEARCH_SET_HINTS,
    hints: string,
}

export type SearchAction =
SearchSetGender |
SearchSetAge |
SearchSetStep |
SearchSetHints |
SearchSetBudget;