import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import routes from '../../routes';

type PropsFromRouter = {
    url: string // the full url of the idea to go to.
    sid: string // the search session id
}

type Props = RouteComponentProps<PropsFromRouter>;

/**
 * Just redirects the user to the given URL, preparing a nice referer for analysis.
 */
class GoToIdea extends React.Component<Props> {

    // Business Logic

    private extractRouterProps(props: Props): PropsFromRouter {
        const params = qs.parse(props.location.search);
        const url = params.url as string || '';
        const sid = params.sid as string || '';
        return {url, sid};
    }

    // LifeCycle

    public componentDidMount() {
        const {url, sid} = this.extractRouterProps(this.props);
        this.props.history.replace(`${routes.gotoIdea.full}?sid=${sid}`);
        window.location.href = url;
    }

    // Rendering

    public render() {
        return <div>&nbsp;</div>;
    }
}

export default GoToIdea;