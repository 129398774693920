import * as React from 'react';
import useTranslation from '../hooks/Translator';

const French: React.FC<{}> = ({children}) => {
    const t = useTranslation();
    if (t.locale === 'fr') {
        return <>{children}</>;
    }
    return null;
};

export {
    French,
}