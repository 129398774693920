import * as React from 'react';
import { French } from '../base/Locale';
import { getStartStateFromLocation } from './KeywordLink';

const WelcomeMessage: React.FC<{}> = () => {
    const state = getStartStateFromLocation();
    if (state.occasion === 'christmas') {
        return <French>
            À la recherche d'un <strong>cadeau de Noël</strong>&nbsp;? 
            Je te pose quelques questions et te déniche une idée de cadeau grâce à tes réponses&nbsp;!
        </French>
    }
    return <French>
        À la recherche d'un <strong>cadeau</strong> à offrir&nbsp;?
        Je te pose quelques questions et te déniche une idée de cadeau grâce à tes réponses&nbsp;!
    </French>
}

export default WelcomeMessage;