import * as React from 'react';

type Props = {
    suffix?: string,
};

const mainTitle = 'Nebuly';

// Legacy version for most pages, should be replaced by PageTitle
class TitleSetter extends React.Component<Props> {

    // Business Logic

    private updateTitle(props: Props) {
        const suffix = props.suffix === undefined ? '' : ` - ${props.suffix}`;
        document.title = `${mainTitle}${suffix}`;
    }

    // LifeCycle

    public componentDidMount() {
        this.updateTitle(this.props);
    }

    public componentWillUnmount() {
        // Remove our specific title if any.
        this.updateTitle({});
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.suffix !== this.props.suffix) {
            this.updateTitle(this.props);
        }
    }


    public render() {
        return <div style={{display: 'none'}}>&nbsp;</div>;
    }
}

const PageTitle: React.FC<{
    title: string
    asSuffix?: boolean
}> = ({title, asSuffix}) => {
    const documentTitle = asSuffix === true ? `${mainTitle} - ${title}` : title;
    React.useEffect(() => {
        document.title = documentTitle;
    }, [documentTitle]);
    return <h1>{title}</h1>
}

export default TitleSetter;

export {
    PageTitle,
}