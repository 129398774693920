import { i32 } from "./rust";

export type AnswerValue = 'Yes' | 'IDontKnow' | 'No';
export type AnswerOrFeedbackValue = 'Yes' | 'ProbablyYes' | 'IDontKnow' | 'ProbablyNo' | 'No' | 'Good' | 'Bad';
const serverAnswerValueMapping : AnswerOrFeedbackValue[] = ['Yes', 'ProbablyYes', 'IDontKnow', 'ProbablyNo', 'No', 'Good', 'Bad']; // Same on server, order is important
export const allAnswersOrFeedback = serverAnswerValueMapping

export function isAnswer(a: AnswerOrFeedbackValue) {
    return a !== 'Good' && a !== 'Bad';
}

export function mapNumberToAnswer(v: number): AnswerOrFeedbackValue {
    return serverAnswerValueMapping[v];
}

export function mapAnswerToNumber(v: AnswerOrFeedbackValue): number {
    return serverAnswerValueMapping.indexOf(v);
}

export type AnswerValueMap<V> = {
    Yes: V,
    ProbablyYes: V,
    IDontKnow: V,
    ProbablyNo: V,
    No: V,
    Good: V,
    Bad: V,
    [key: string]: V,
}


export type StringAnswerValueMap = AnswerValueMap<string>

export type AnswerSource = 'User' | 'Rules' | 'QuestionsRelations' | 'Iaaq' | 'Square'
export type AnswerSourceMap<V> = {[A in AnswerSource]: V}

export type AutomatedAnswer = {
    a: AnswerValue,
    question: i32,
}