import {useEffect, useState} from 'react';

import frFR from '../../i18n/locales/fr-FR';
import translator, { TranslatePath } from '../../i18n/Translator';
import { LocaleContent } from '../../i18n/locales/types';
import { ShortCutMap } from '../../model/question';

function useTranslationEffect() {
    const setTranslationVersion = useState<number>(0)[1];

    useEffect(() => {
        function handleTranslationChange() {
            setTranslationVersion(t => t + 1);
        }
        translator.addListener(handleTranslationChange);
        return function cleanup() {
            translator.removeListener(handleTranslationChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/* No props are used in the translation. Do not cleanup on each render. */]);
}

const translate = (path: TranslatePath) => translator.translate(path);
const translateFull = (path: TranslatePath, data: any) => translator.translateData(path, data);
const formatDate = (d: Date, options: Intl.DateTimeFormatOptions) => translator.formatDate(d, options);
const getShortcuts = () => translator.getData(l => l.question.shortcuts) as ShortCutMap;

function useTranslation() {
    useTranslationEffect();

    return {
        locale: translator.locale,
        translate,
        translateFull,
        formatDate,
        getShortcuts,
    }
}

function useSimpleTranslation(): LocaleContent {
    useTranslationEffect();

    if (translator.localeData !== undefined) {
        return translator.localeData;
    } else {
        return frFR;
    }
}

export default useTranslation;
export {
    useSimpleTranslation,
}