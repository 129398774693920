import React from 'react';
import { useDrop } from 'react-dnd';

import './AlertCreator.scss';
import useTranslation from '../../hooks/Translator';

export const DND_TYPE = 'alert-question';
export const ignorePosLimitHeight = 20;


type PropsFromParent = {
    y: number,
    isLast: boolean,
    onIsOver: () => void,
    onDrop: (valid: boolean) => void,
}

type Props = PropsFromParent;

const IgnorePosLimit: React.FC<Props> = (props: Props) => {
    const {y, isLast, onIsOver, onDrop} = props;
    const {translate} = useTranslation();

    const drop = useDrop({
        accept: DND_TYPE,
        hover: () => onIsOver(),
        drop: () => onDrop(true),
      })[1];

    return <div ref={drop} className="ignore-line draggable" style={{height: ignorePosLimitHeight, top: y}}>
        {translate(l => isLast ? l.search.alert.addIgnoredQuestionsInstruction : l.search.alert.ignoredQuestionsHeader)}
    </div>;
}

export default IgnorePosLimit;