import * as React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import If from '../../base/If';
import { useSimpleTranslation } from '../../hooks/Translator';
import './WaitingForInput.scss';

const WaitingForInput: React.FC<{}> = () => {
    const t = useSimpleTranslation();
    const [suggestUseGuide, setSuggestUseGuide] = React.useState<boolean>(false);
    React.useEffect(() => {
        const timeout = setTimeout(() => setSuggestUseGuide(true), 2000);
        return () => {
            clearTimeout(timeout);
        }
    });
    return <If test={suggestUseGuide}>
            <div className="square-waiting-for-input">
                <span className="suggest-use-guide-prefix">{t.search.square.suggestUseGuidePrefix}</span>
                <Link to={routes.home}>{t.search.square.useGuideAction}</Link> 
                <span className="suggest-use-guide-prefix">{t.search.square.suggestUseGuideSuffix}</span>
            </div>
        </If>;
};

export default WaitingForInput;