import * as React from 'react';
import { Suspense } from 'react';

import { useSimpleTranslation } from '../../hooks/Translator';
import { isReadyQuery, QueryHookResult } from '../../hooks/Query';
import { SquareResult } from './model';
import useResponsive from '../../hooks/Responsive';
const Debug = React.lazy(() => import('./Debug'));

const DebugInfoGuard: React.FC<{    
    result: QueryHookResult<SquareResult>,
}> = ({result}) => {
    const t = useSimpleTranslation();
    const {pageSize} = useResponsive();
    if (!isReadyQuery(result)) {
        return null;
    }
    if (pageSize.x < 600) {
        return null;
    }
    return <Suspense fallback={<div>{t.common.loading}</div>}>
        <Debug result={result} />
    </Suspense>;
}

export default DebugInfoGuard;