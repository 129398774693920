
export const SET_URL = 'CONTRIB_MERCHANT_IDEAS_SET_URL';
export type CONTRIB_MERCHANT_IDEAS_SET_URL = typeof SET_URL;

export const SET_CONTENT = 'CONTRIB_GENERIC_IDEAS_SET_CONTENT';
export type CONTRIB_GENERIC_IDEAS_SET_CONTENT = typeof SET_CONTENT;

export const SET_AGE = 'CONTRIB_GENERIC_IDEAS_SET_AGE';
export type CONTRIB_GENERIC_IDEAS_SET_AGE = typeof SET_AGE;

export const SET_PRICE = 'CONTRIB_GENERIC_IDEAS_SET_PRICE';
export type CONTRIB_GENERIC_IDEAS_SET_PRICE = typeof SET_PRICE;

export type ContribMerchantIdeasSetUrl = {
    type: CONTRIB_MERCHANT_IDEAS_SET_URL,
    url: string,
}

export type ContribGenericIdeasSetContent = {
    type: CONTRIB_GENERIC_IDEAS_SET_CONTENT,
    content: string,
}

export type ContribGenericIdeasSetAge = {
    type: CONTRIB_GENERIC_IDEAS_SET_AGE,
    age: number,
    min: boolean
}

export type ContribGenericIdeasSetPrice = {
    type: CONTRIB_GENERIC_IDEAS_SET_PRICE,
    priceMinCents: number,
    priceMaxCents: number,
}

export type ContribIdeasAction =
ContribGenericIdeasSetContent |
ContribGenericIdeasSetAge |
ContribGenericIdeasSetPrice |
ContribMerchantIdeasSetUrl;
