import { Point } from "../../util/geometry";
import { PageSize } from "../ResponsiveHOC";

export type Side = 'left' | 'right'

export type DeckLayout = {
    cardSize: Point,
    marginTop: number,
}

export type IdeasHighlighterDisplay = 'none' | 'line' | 'big';

export type IdeasHighlighterLayout = {
    ideasCount: number,
    display: IdeasHighlighterDisplay,
    height: number,
    width: number,
}

export type QuestionFeedbackLocation = 'below' | 'none';

export type SearchLayout = {
    deck: DeckLayout,
    questionFeedback: QuestionFeedbackLocation,
    ideasHighlighter: IdeasHighlighterLayout,
    main: Point,
    sideWidth: number,
    hasRoomOnSidesOfDeck: boolean,
}

const sizes = {
    marginBottom: 0,
    overflowBottom3d: 120,
    belowUiMinHeight: 120 /** Answers/Idea feedback height */ + 20 /** Search Home bottom padding */,
    maxWidth: 400,
    // The horizontal margin we leave while sweaping in addition to the screen size to be
    // sure the card rotated part leaves the screen at the end of the animation.
    rotationHMargin: 200,
    ideaHighlightBelowHeight: 120,
    // If the deck height goes below this value we do not display the ideas highlight to get more space for the deck
    minDeckHeight: 200,
};

// We take only 80% of the width of the page to have a margin around our background.
const deckWidthPercent = 0.85;

function landscape(pageSize: PageSize): boolean {
    return pageSize.x > pageSize.y;
}

function getIdeasHighlighterLayout(pageSize: PageSize, hasRoomOnSidesOfDeck: boolean): IdeasHighlighterLayout {
    if (!hasRoomForIdeaHighlightBelowDeck(pageSize)) {
        return {
            ideasCount: 0,
            display: 'none',
            height: 0,
            width: 0,
        };
    }
    if (hasRoomOnSidesOfDeck) {
        return {
            ideasCount: 9, // First iteration showing only 9 ideas in a row
            display: 'big',
            height: sizes.ideaHighlightBelowHeight,
            width: pageSize.x,
        };
    }
    return {
        ideasCount: 3,
        display: 'line',
        height: sizes.ideaHighlightBelowHeight,
        width: pageSize.x,
    }
}


function hasRoomForIdeaHighlightBelowDeck(pageSize: PageSize): boolean {
    return pageSize.y - sizes.belowUiMinHeight - sizes.ideaHighlightBelowHeight > sizes.minDeckHeight;
}

const getDeckLayout = (pageSize: PageSize, dpi: number): DeckLayout => {
    const availableWidth = pageSize.x * deckWidthPercent;
    const availableHeight = pageSize.y - sizes.belowUiMinHeight - (hasRoomForIdeaHighlightBelowDeck(pageSize) ? sizes.ideaHighlightBelowHeight : 0);
    const width = Math.min(sizes.maxWidth, availableWidth);
    const maxHeightFromWidth = width * 1.8;
    const biggerThanThisIsUselss = 400;
    const cardHeight = Math.min(availableHeight, maxHeightFromWidth, biggerThanThisIsUselss);
    return {
        cardSize: [width, cardHeight],
        marginTop: (availableHeight - cardHeight) / 2,
    };
};

const getLayout = (pageSize: PageSize, dpi: number, makeSpaceForActions: boolean): SearchLayout => {
    const deck = getDeckLayout(pageSize, dpi);
    const hasRoomOnSidesOfDeck = landscape(pageSize) && deck.cardSize[0] < pageSize.y - 300;
    const ideasHighlighter = getIdeasHighlighterLayout(pageSize, hasRoomOnSidesOfDeck);
    const mainHeight = pageSize.y + (makeSpaceForActions ? 200 : 0);
    const mainWidth = hasRoomOnSidesOfDeck ? deck.cardSize[0] + 50 : pageSize.x;
    const sideWidth = hasRoomOnSidesOfDeck ? (pageSize.x - mainWidth) / 2 : 0;
    return {
        main: [mainWidth, mainHeight],
        sideWidth,
        deck,
        ideasHighlighter,
        questionFeedback: 'none', // TODO find where to place this UI
        hasRoomOnSidesOfDeck
    };
};

export {
    sizes,
    getLayout,
    getDeckLayout,
}