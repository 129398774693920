import { FullContribIdea } from '../../../../model/idea';

export const SET_NEXT_IDEAS = 'CONTRIB_ENRICH_IDEA_SET_NEXT_IDEAS';
export type CONTRIB_ENRICH_IDEA_SET_NEXT_IDEAS = typeof SET_NEXT_IDEAS;

export type ContribEnrichIdeaSetNextIdeas = {
    type: CONTRIB_ENRICH_IDEA_SET_NEXT_IDEAS,
    ideas: FullContribIdea[],
}

export type ContribEnrichIdeaAction =
ContribEnrichIdeaSetNextIdeas
