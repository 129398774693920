import * as React from 'react';
import { SentenceForm, SentenceSubject, ShortCutMap, QuestionAndShortcut, defaultQuestionShortcut} from '../model/question';
import { uppercaseFirstLetter } from '../util/strings';
import { UserSex } from '../model/gender';
import QuestionMark from './search/QuestionMark';
import useTranslation from './hooks/Translator';

type Props = {
    id?: string,
    content: string,
    subject: UserSex,
    form: SentenceForm,
    asSentence?: boolean,
    style?: React.CSSProperties,
}

const QC: React.FC<Props> = (props) => {
    const {getShortcuts} = useTranslation();
    let formatted = format(props.content, getShortcuts(), props.subject, props.form);
    let end = <span></span>;
    if (props.asSentence) {
        formatted = `${uppercaseFirstLetter(formatted)}`;
        if (props.form === 'interrogative') {
            end = <QuestionMark/>;
        } else {
            end = <span>.</span>;
        }
    }
    return <span id={props.id} className="question" style={props.style}><span>{formatted}</span>{end}</span>
};

type QuestionConditionalFormatPart = {
    conditions: string,
    text: string,
}

type QuestionFormatPart = QuestionConditionalFormatPart | string;

const extractShortcut = (content: string): QuestionAndShortcut => {
    if (content.indexOf('{') !== 0) {
        return {contentAfter: content, shortcut: defaultQuestionShortcut};
    }
    const braceCloseIndex = content.indexOf('} ');
    if (braceCloseIndex === -1) {
        return {contentAfter: content, shortcut: defaultQuestionShortcut};
    }
    return {
        contentAfter: content.substring(braceCloseIndex + '} '.length),
        shortcut: content.substring('{'.length, braceCloseIndex),
    }
}

const injectShortcut = (input: QuestionAndShortcut):string => {
    if (input.shortcut === defaultQuestionShortcut) {
        return input.contentAfter;
    }
    return `{${input.shortcut}} ${input.contentAfter}`;
}

const parse = (str: string): QuestionFormatPart[] => {
    const result = [];
    let currentPos = 0;
    while (true) {
        const openBracketIndex = str.indexOf('[', currentPos);
        if (openBracketIndex === -1) {
            result.push(str.substr(currentPos));
            break;
        }
        const closeBracketIndex = str.indexOf(']', openBracketIndex);
        if (closeBracketIndex === -1) {
            return [`String ${str} opened a bracket at index ${openBracketIndex} but didn't close it.`];
        }
        const colonIndex = str.indexOf(':', openBracketIndex);
        if (colonIndex === -1) {
            return [`String ${str} opened a bracket at index ${openBracketIndex} but didn't have a colon afterward.`];
        }
        if (openBracketIndex > currentPos) {
            result.push(str.substring(currentPos, openBracketIndex));
        }
        result.push({
            conditions: str.substring(openBracketIndex + '['.length, colonIndex),
            text: str.substring(colonIndex + ':'.length, closeBracketIndex)
        });
        if (closeBracketIndex === str.length - 1) {
            break;
        }
        currentPos = closeBracketIndex + ']'.length;
    }
    return result;
}

const isConditionMet = (condition: string, userSex: SentenceSubject, form: SentenceForm): boolean => {
    switch (condition) {
        case 'M': return userSex === 'Male';
        case 'F': return userSex === 'Female';
        case 'C': return userSex === 'Couple';
        case 'U': return userSex === 'Unknown';
        case 'O': return userSex === 'Others';
        case 'A': return form === 'affirmative';
        case 'I': return form === 'interrogative';
        case 'N': return form === 'negative';
        // Singular
        case 'S': return userSex === 'Male' || userSex === 'Female' || userSex === 'Unknown';
        // Plural
        case 'P': return userSex === 'Couple' || userSex === 'Others';
        default: return false;
    }
};

const formatPart = (part: QuestionFormatPart, userSex: SentenceSubject, form: SentenceForm): string => {
    if (typeof part === 'string') {
        return part;
    }
    const {conditions, text} = part;
    for (let i = 0; i < conditions.length; i++) {
        const condition = conditions.charAt(i);
        if (!isConditionMet(condition, userSex, form)) {
            return '';
        }
    }
    return text;
}

const expandShortcuts = (str: string, shortcuts: ShortCutMap): string => {
    if (str.indexOf('{') === -1) {
        return str;
    }
    const shortcutKeys = Object.keys(shortcuts);
    for (let i = 0; i < shortcutKeys.length; i++) {
        const key = shortcutKeys[i];
        const toSearch = `{${key}}`;
        const index = str.indexOf(toSearch);
        if (index !== -1) {
            let newStr = str.replace(toSearch, shortcuts[key]);
            return expandShortcuts(newStr, shortcuts);
        }
    }
    return str;
}

const format = (str: string, shortcuts: ShortCutMap, userSex: SentenceSubject, form: SentenceForm): string => {
    return parse(expandShortcuts(str, shortcuts)).map(part => formatPart(part, userSex, form)).join('');
}

// Gets a short representation of the question to distinguish it from others.
const getSymbol = (content: string, shortcuts: ShortCutMap): string => {
    const parsed = parse(expandShortcuts(content, shortcuts));
    const meaningfulParts: string[] = parsed.filter(p => typeof p === 'string') as string[];
    const meaningfulWords = meaningfulParts.join(' ').split(' ').filter(w => w.length > 3);
    return meaningfulWords.map(w => w[0]).join('');
}

export {
    format,
    parse, // exported for tests only
    extractShortcut,
    injectShortcut,
    getSymbol,
};

export default React.memo(QC);