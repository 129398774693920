import * as React from 'react';
import close from '../../../images/close_main_fg.svg';
import { mapFeedbackToNumber } from '../../../model/feedback';
import { Idea, IdeaKey, shortenIdeaName, toHash } from "../../../model/idea";
import { i16, i32 } from '../../../model/rust';
import routes, { routeToUrl } from '../../../routes';
import querier from '../../../server/querier';
import If from '../../base/If';
import { useHoverSideEffect } from '../../hooks/Hover';
import { useSimpleTranslation } from '../../hooks/Translator';
import { goToIdea } from '../FoundIdeaBelowDeck';
import ResponsiveShare from '../share/ResponsiveShare';
import { IdeaAndSingleScore } from './model';

export type SingleSquareLayout = {
    top: number,
    left: number,
    side: number,
    needsCloseButton: boolean,
}

export type ShareCommonAllIdeas = {
    query: string,
    result: IdeaAndSingleScore[],
    sessionId: string,
}

const highlightRatio = 0.6;

const maxLetters = 30;

function truncateTitle(title: string): string {
    const words = title.split(' ');
    let result = '';
    let first = true;
    for (let word of words) {
        if (result.length + word.length > maxLetters) {
            result = result + '\u2026';
            break;
        }
        if (!first) {
            result = result + ' ';
        }
        first = false;
        result = result + word;
    }
    return result;
}

// Same as SquareFeeback server side. Attributes names are minimified.
type SquareFeedback = {
    // session id
    s: string,
    // feedback
    f: i16,
    // merchant_id
    m: i32,
    // idea_id
    i: string,
}

function sendClick(sessionId: string, idea: IdeaKey) {
    querier.postData<SquareFeedback>('public/square/f', {
        s: sessionId,
        f: mapFeedbackToNumber('ClickedOnIdea'),
        m: idea.merchant_id,
        i: idea.id_within_merchant,
    });
}

function onClickOnIdea(sessionId: string, idea: Idea) {
    sendClick(sessionId, idea.key);
    goToIdea(idea, sessionId);
}

function createShareLink(common: ShareCommonAllIdeas, idea: IdeaKey): string {
    return routeToUrl(`${routes.square.share(common.query, idea, common.result, common.sessionId)}`);
}

const SquareIdea: React.FC<{
    idea: Idea,
    layout: SingleSquareLayout,
    sessionId: string,
    shareCommon: ShareCommonAllIdeas,
    highlighted: boolean,
    faded: boolean,
    onHover: (hash: string | undefined) => void,
}> = ({idea, layout: {top, left, side, needsCloseButton}, sessionId, shareCommon, highlighted, faded, onHover}) => {
    const t = useSimpleTranslation();
    const {onMouseEnter, onMouseLeave} = useHoverSideEffect(onHover, toHash(idea.key));
    const src = `${querier.httpUrl}/img/${idea.image}`;
    return <div 
        className="square-idea clickable"
        style={{backgroundImage: `url(${src})`, width: side, height: side, top, left, zIndex: highlighted ? 3: 2, opacity: faded ? 0.5 : 1}}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onContextMenu={(e) => {onHover(toHash(idea.key));e.preventDefault()}}
        onClick={() => { onClickOnIdea(sessionId, idea)}}>
        <div className={`square-idea-highlight ${highlighted ? 'square-idea-highlighted' : ''}`} style={{width: side * highlightRatio, height: side * highlightRatio}}>
            <If test={needsCloseButton}>
                <img src={close} alt="Reduce highlight" className="clickable square-idea-close-highlight" onClick={() => {onHover(undefined);}}/>
            </If>
            <div className="square-idea-title" onClick={() => onClickOnIdea(sessionId, idea)}>
                {truncateTitle(shortenIdeaName(idea.title))}
            </div>
            <ResponsiveShare
             title={t.search.square.share.title}
             text={t.search.square.share.text}
             url={createShareLink(shareCommon, idea.key)}
             />
        </div>
    </div>
}

export default SquareIdea;