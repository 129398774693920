import { Question } from "./question";
import { Idea, IdeaKey } from "./idea";
import { HistogramData } from "../components/base/CountHistogram";
import { i16, i32, i64, u16 } from "./rust";
import { UserSex } from "./gender";
import { AnswerSource } from "./answer";

export type ServerSearchSessionSummary = {
    readonly id: string,
    readonly gender: UserSex,
    readonly age: number,
    readonly price_min_cents: number,
    readonly price_max_cents: number,
    readonly hints: string,
    readonly start_date: string,
    // Can have no action if the user did nothing.
    readonly last_action_date: string | null,
    readonly answers_count: number,
    readonly feedback_count: number,
    readonly from_location: string,
}

export type SearchEnvironment = {
    pw: u16,
    ph: u16,
    dpi: u16,
    ua: string,
    uv: string,
}

export type ServerSearchSessionStart = {
    readonly gender: UserSex,
    readonly age: number,
    readonly price_min_cents: number,
    readonly price_max_cents: number,
    readonly date: string,
    readonly from_location: string,
    readonly hints: string,
    readonly env: SearchEnvironment,
}

// SearchAnswer server side
export type ServerSearchAnswer = {
    readonly question_id: number,
    readonly a: number,
    readonly date: string,
    readonly source: AnswerSource,
}

// UserFacingSearchSessionAnswer server side 
export type ServerSearchSessionAnswer = {
    readonly question: Question,
    readonly answer_or_feedback: number,
    readonly date: string,
    readonly source: AnswerSource,
}

export type ServerSearchSessionFeedback = {
    readonly idea: Idea,
    readonly feedback: number,
    readonly date: string,
    readonly aaq: boolean,
}

export type ServerSearchSessionMetadata = {
    readonly id: number,
    readonly value: string,
    readonly date: string,
}

export type IdeaScore = {
    user: i16,
    db: i16,
    hints: i16,
}

export type IdeaAndScore = [IdeaKey, IdeaScore | null];

export type ServerSearchStepSnapshot = {
    readonly date: string,
    readonly count_ideas_by_score: HistogramData,
    readonly best_ideas: Idea[],
    readonly scores: IdeaAndScore[],
}

export type ServerSearchSessionReport = {
    readonly id: string,
    readonly start: string,
    readonly starts: ServerSearchSessionStart[],
    readonly answers: ServerSearchSessionAnswer[],
    readonly feedback: ServerSearchSessionFeedback[],
    readonly metadata: ServerSearchSessionMetadata[],
    readonly snapshots: ServerSearchStepSnapshot[],
}

export type GoogleKeyword = {
    keyword: string,
    strict: boolean,
}

export type NaturalVisitSource = 'Natural';
export type GoogleVisitSource = {
    Google: {
        readonly keyword?: GoogleKeyword,
        readonly campaign?: string,
        readonly gclid: string | undefined // Not set in JSON set by server when aggregating multiple ones
    },
}

export type VisitSource = NaturalVisitSource | GoogleVisitSource;

export type SimpleAggregatedVisitSource = {
    Simple: VisitSource
}
export type MultipleAggregatedVisitSource = 'Multiple';

export type AggregatedVisitSource = SimpleAggregatedVisitSource | MultipleAggregatedVisitSource;
export type AggregatedVisitSourceAndCount = [AggregatedVisitSource, number];

export type ServerSearchSessionsDayPerformance = {
    readonly day: string,
    readonly starts: i64,
    // Gives for each feedback code the number of times we saw it this day
    readonly feedback: i64[],
    // Gives for each feedback code the number of unique sessions that sent it this day
    readonly sessions_with_feedback: i64[],
    // The number of search start from natural visits
    readonly natural: i64,
    readonly answers: i64,
    readonly gave_up: i64,
    readonly clicks_converted: i64,
    readonly conversions: i64,
    readonly gain_cents: i32,
}

export function isNaturalVisitSource(vs: VisitSource): vs is NaturalVisitSource {
    return vs === 'Natural';
}

export function isGoogleVisitSource(vs: VisitSource): vs is GoogleVisitSource {
    return (vs as GoogleVisitSource).Google !== undefined;
}

export function isSimpleAggregatedVisitSource(avs: AggregatedVisitSource): avs is SimpleAggregatedVisitSource {
    return (avs as SimpleAggregatedVisitSource).Simple !== undefined;
}

export function isMultipleAggregatedVisitSource(avs: AggregatedVisitSource): avs is MultipleAggregatedVisitSource {
    return avs === 'Multiple';
}

// We don't care about the campaign and the strict flag here for simplicity.
// The strict flag is only known for old visits.
export function areVisitSourceEqual(vs1: VisitSource, vs2: VisitSource) {
    if (isNaturalVisitSource(vs1)) {
        return isNaturalVisitSource(vs2);
    } else if (isGoogleVisitSource(vs1)) {
        if (isGoogleVisitSource(vs2)) {
            if (vs1.Google.keyword === undefined) {
                return vs2.Google.keyword === undefined;
            } else {
                if (vs2.Google.keyword === undefined) {
                    return false;
                }
                return vs1.Google.keyword.keyword === vs2.Google.keyword.keyword;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function areAggregatedVisitSourceEqual(avs1: AggregatedVisitSource, avs2: AggregatedVisitSource) {
    if (isMultipleAggregatedVisitSource(avs1) && isMultipleAggregatedVisitSource(avs2)) {
        return true;
    }
    if (isSimpleAggregatedVisitSource(avs1) && isSimpleAggregatedVisitSource(avs2)) {
        return areVisitSourceEqual(avs1.Simple, avs2.Simple);
    }
    return false;
}


export type InconsistentFeedbackAnalysisContent = {
    readonly InconsistentFeedback: [IdeaKey, String],
}

export type ServerAnalysisContent = InconsistentFeedbackAnalysisContent;

export type VariableStoredDebugInfo = {
    Variable: [string, string]
};

export type StepReasonStoredDebugInfo = {
    StepReason: string,
}

export type AnalysisStoredDebugInfo = {
    Analysis: ServerAnalysisContent
}

export type SideEffectStoredDebugInfo = {
    SideEffect: {
        RemoveIdea: IdeaKey
    }
}

export const getCurrentStepDebugInfo = 'GetCurrentStep';
export const restartDebugInfo = 'Restart';

export type StoredDebugInfo = VariableStoredDebugInfo 
| StepReasonStoredDebugInfo 
| AnalysisStoredDebugInfo 
| typeof getCurrentStepDebugInfo 
| typeof restartDebugInfo 
| SideEffectStoredDebugInfo

export function isVariableStoredDebugInfo(sdb: StoredDebugInfo): sdb is VariableStoredDebugInfo {
    return (sdb as VariableStoredDebugInfo).Variable !== undefined;
}

export function isStepReasonStoredDebugInfo(sdb: StoredDebugInfo): sdb is StepReasonStoredDebugInfo {
    return (sdb as StepReasonStoredDebugInfo).StepReason !== undefined;
}

export function isAnalysisStoredDebugInfo(sdb: StoredDebugInfo): sdb is AnalysisStoredDebugInfo {
    return (sdb as AnalysisStoredDebugInfo).Analysis !== undefined;
}

export type ServerUserProvidedSearchSessionAnalysis = {
    readonly session_id: string,
    readonly content: ServerAnalysisContent,
}