import * as React from 'react';

import "./Drawer.scss";

type PropsFromParent = {
    label: string,
}

type Props = PropsFromParent;

const Drawer: React.FC<Props> = props => {
    const [open, setOpen] = React.useState<boolean>(false);

    return <div className="drawer column">
        <div className="clickable" onClick={() => setOpen(!open)}>{props.label}</div>
        {open ? props.children : null}
    </div>;
}

export default Drawer;