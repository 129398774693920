import React, { useState } from "react";
import { useEffect } from "react";
import { LocaleContent } from "../../i18n/locales/types";
import { ServerMerchant } from "../../model/merchant";
import querier, {HttpErrorResponse, QueryOptions} from "../../server/querier";
import { coalesce } from "../../util/common";
import { useStatePersisted } from "./Persist";
import { useSimpleTranslation } from "./Translator";

type FetchMode = 'OnMountUpdateAndRefresh' | 'OnUpdateAndRefresh' | 'OnRefresh';

type QueryHookOptions<Result> = {
    persistPath?: string, // If one wants to persist the query result between reload. Mostly used for admin expensive queries.
    errorCallback?: (v: FailedQueryState) => void,
    callback?: (v: Result) => void,
    transformResponse?: (text: string) => Result,
    fetchMode?: FetchMode,
}

export type FailedQueryState = {
    userText: string,
    response?: string,
    httpErrorCode: number | undefined,
}

const notStartedQuery = '$$not-started$$';
const pendingQuery = '$$pending-query$$';
type NotStartedQueryState = typeof notStartedQuery;
type PendingQuery = typeof pendingQuery;

type NotStartedQuery = {
    state: NotStartedQueryState,
    refresh: () => void,
}

type FailedQuery = {
    userText: string,
    // The server response for HTTP errors, not defined for network errors.
    response?: string,
    httpErrorCode: number | undefined,
    refresh: () => void,
}

// The type we store in our internal state that we can persist.
export type QueryHookState<T> = T | PendingQuery | FailedQueryState | NotStartedQueryState;
// The types that we return to components, contain additional non serializables attributes like refresh.
export type QueryHookResult<T> = T | PendingQuery | FailedQuery | NotStartedQuery;

type QueryHookReturn<T> = {
    result: QueryHookResult<T>,
    // Changes the result of the query.
    // Useful for UI performing expensive queries and that provide controls that perform change server side. This way the UI can reflect the change without having to perform the fetch query again.
    setResult: (v: T) => void,
    refresh: () => void,
}

function failedQueryStateToQueryHookFailure(s: FailedQueryState, refresh: () => void): FailedQuery {
    return {
        userText: s.userText,
        response: s.response,
        httpErrorCode: s.httpErrorCode,
        refresh
    };
}

/**
 * Converts the query hook state into the component state.
 * Adds the parts that we cannot persist.
 * @param s The internal query hook state.
 */
function queryHookStateToResult<T>(s: QueryHookState<T>, refresh: () => void): QueryHookResult<T> {
    if (s === notStartedQuery) {
        return {
            state: s,
            refresh
        };
    } else if (isFailedQueryState(s)) {
        return failedQueryStateToQueryHookFailure(s, refresh);
    }
    return s;
}

function toQueryOptions(input: unknown): QueryOptions {
    if (input === undefined) {
        return {forwardErrorToListeners: false};
    } else {
        const o: QueryOptions = querier.postDataAsOptions(input);
        o.forwardErrorToListeners = false;
        return o;
    }
}

// Providing an empty url to the hook will not trigger a query. This is useful when
// we fetch on update and some input values should not trigger a query.
function isValidUrl(s: string) {
    return s.length > 0;
}

export function getUserText(serverResponse: HttpErrorResponse, t: LocaleContent): string {
    if (serverResponse.status === 401 || serverResponse.status === 403) {
        return t.common.errors.accessDenied;
    }
    if (serverResponse.status < 500) {
        return t.common.errors.genericUserError;
    } else {
        return t.common.errors.applicationError;
    }
}

async function fetchPure<Input, Result>(
    url: string, 
    body: Input,
    t: LocaleContent,
    setState: React.Dispatch<React.SetStateAction<QueryHookState<Result>>>,
    setStateNoPersist: (v: QueryHookState<Result>) => void,
    setQueryCount: React.Dispatch<React.SetStateAction<number>>,
    setLastProcessedInputs: (inputs: [string, Input]) => void, 
    hookOptions: QueryHookOptions<Result>,
) {
    if (!isValidUrl(url) || body === null) {
        setStateNoPersist(notStartedQuery);
        setLastProcessedInputs([url, body]);
        return;
    }
    // Setting lastProcessedInput before state so that useEffect does not reset state to notStarted
    setLastProcessedInputs([url, body]);
    setStateNoPersist(pendingQuery);
    setQueryCount(c => c + 1);
    const serverResponse = await querier.queryRaw(url, toQueryOptions(body));
    switch (serverResponse.type) {
        case 'http-error': {
            const errorState = {
                userText: getUserText(serverResponse, t), 
                httpErrorCode: serverResponse.status,
                response: serverResponse.message
            };
            setStateNoPersist(errorState);
            if (hookOptions.errorCallback !== undefined) {
                hookOptions.errorCallback(errorState);
            }
            break;
        }
        case 'network-error': setStateNoPersist({userText: t.common.errors.networkError, httpErrorCode: undefined});break;
        case 'success': {
            const transformResponse = hookOptions.transformResponse !== undefined ? hookOptions.transformResponse : JSON.parse;
            const result: Result = transformResponse(serverResponse.content);
            setState(result);
            if (hookOptions.callback !== undefined) {
                hookOptions.callback(result);
            }
            break;
        }
    }
}

/**
 * @param path the url at which we query.
 * @param options query hook options, should be constants.
 */
function useFullQueryJson<Input, Result>(path: string, input: Input | null, hookOptions: QueryHookOptions<Result>): QueryHookReturn<Result> {
    // Memoize constant inputs to be able to put them in later React.useMemo calls without risks. 
    // They are often created without useMemo in calling code since they are constants js hashes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedHookOptions = React.useMemo(() => hookOptions, []); // Should be constants
    const persistPath = memoizedHookOptions.persistPath;
    const [state, setState, setStateNoPersist] = useStatePersisted<QueryHookState<Result>>(notStartedQuery, persistPath);
    const [lastProcessedInputs, setLastProcessedInputs] = useStatePersisted<[string, Input | null]>([path, input], persistPath === undefined ? undefined : persistPath + '-inputs');
    const [queryCount, setQueryCount] = useState<number>(0);
    const t = useSimpleTranslation();
    const fetchOnMount = memoizedHookOptions.fetchMode === undefined || memoizedHookOptions.fetchMode === 'OnMountUpdateAndRefresh';
    const fetchOnUpdate = memoizedHookOptions.fetchMode !== 'OnRefresh';
    const fetch = React.useMemo(
        () => () => fetchPure(path, input, t, setState, setStateNoPersist, setQueryCount, setLastProcessedInputs, memoizedHookOptions), 
        [input, setState, t, path, setStateNoPersist, setQueryCount, setLastProcessedInputs, memoizedHookOptions]
    );
    useEffect(() => {
        if (state === pendingQuery) {
            return;
        }
        if (state === notStartedQuery && isValidUrl(path) && input !== null) {
            if ((queryCount === 0 && fetchOnMount) || (queryCount > 0 && fetchOnUpdate)) {
                fetch();
            }
        }
        // We compare inputs and lastProcessedInputs with a stringify because React.useState(input) returns an object
        // different from input even without state change.
        if (fetchOnUpdate && (isReadyQueryState(state) || isFailedQueryState(state)) && (JSON.stringify(lastProcessedInputs) !== JSON.stringify([path, input]))) {
            setState(notStartedQuery);
        }
        return () => {
            // TODO cancel the query
        };
    }, [input, state, fetch, queryCount, lastProcessedInputs, fetchOnMount, fetchOnUpdate, path, setState]);
    const refresh = () => fetch();
    return {
        result: queryHookStateToResult(state, refresh),
        setResult: setState,
        refresh,
    };
}

function useQueryJson<Result>(path: string, options?: QueryHookOptions<Result>): QueryHookReturn<Result> {
    return useFullQueryJson<undefined, Result>(path, undefined, coalesce(options, {}));
}

// path the url at which to POST
// input when null the query will not launch. undefined inputs are reserved for useQueryJson
function useQueryJsonJson<Input, Result>(path: string, input: Input | null, options?: QueryHookOptions<Result>): QueryHookReturn<Result> {
    return useFullQueryJson<Input, Result>(path, input, coalesce(options, {}));
}

function isNotStartedQuery(r: QueryHookResult<any>): r is NotStartedQuery {
    return (r as NotStartedQuery).state === notStartedQuery;
}

function isPendingQuery(r: QueryHookResult<any>): r is PendingQuery {
    return r === pendingQuery;
}

function isFailedQuery(r: QueryHookResult<any>): r is FailedQuery {
    return (r as FailedQuery).userText !== undefined;
}

function isFailedQueryState(r: QueryHookState<any>): r is FailedQueryState {
    return (r as FailedQueryState).userText !== undefined;
}

function isReadyQuery<T>(r: QueryHookResult<T>): r is T {
    return r !== pendingQuery && !isFailedQuery(r) && !isNotStartedQuery(r);
}

function isReadyQueryState<T>(r: QueryHookState<T>): r is T {
    return r !== pendingQuery && r !== notStartedQuery && !isFailedQueryState(r);
}

function useQueryAllMerchants() {
    return useQueryJson<ServerMerchant[]>('admin/merchant/all', {persistPath: 'admin-merchant-all'});
}

export {
    useQueryJson,
    useQueryJsonJson,
    isNotStartedQuery,
    isPendingQuery,
    isFailedQuery,
    isReadyQuery,
    useQueryAllMerchants,
}