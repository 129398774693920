import { IdeaAndSingleScore } from "./components/search/square/model";
import { IdeaKey, IdeaSearchCriteria, IdeasFeedType, toHash } from "./model/idea";

const routes = {
    account: {
        signup: '/account/signup',
        signin: '/account/signin',
        validation: {
            // This path is build server side when creating the validation email, we thus do not need a fn version.
            full: '/account/validation/:token',
        },
    },
    contrib: {
        home: '/contrib/',
        questions: {
            home: '/contrib/questions',
            add: '/contrib/questions/add',
            enrich: {
                full: '/contrib/questions/enrich/:qid',
                fn: (qid: number) => `/contrib/questions/enrich/${qid}`,
            }
        },
        ideas: {
            add: {
                home: '/contrib/ideas/add',
                generic: '/contrib/ideas/add/generic',
                merchant: '/contrib/ideas/add/merchant',
            },
            enrich: {
                full: '/contrib/idea/enrich/single/:mid/:iid',
                fn: (key: IdeaKey, asCohort: boolean) => `/contrib/idea/enrich/single/${key.merchant_id}/${encodeURIComponent(key.id_within_merchant)}${asCohort ? '?nis=cohort' : ''}`,
                feed: {
                    full: '/contrib/idea/enrich/feed/:type',
                    fn: (t: IdeasFeedType) => `/contrib/idea/enrich/feed/${t}`,
                },
            },
            search: {
                noCriteria: '/contrib/idea/search',
                criteria: (criteria: IdeaSearchCriteria[]) => `/contrib/idea/search?c=${JSON.stringify(criteria)}`,
            },
            cohort: '/contrib/idea/cohort',
            inspirations: '/contrib/idea/inspirations',
        },
        reactions: {
            add: {
                home: '/contrib/reactions/add',
                form: {
                    full: '/contrib/reactions/add/:qid',
                    fn: (qid: number): string => `/contrib/reactions/add/${qid}`,
                },
            },
        },
        suggestions: '/contrib/suggestions',
    },
    home: '/',
    landingPages: {
        christmasFr: '/noel',
    },
    about: {
        home: '/about',
        legal: '/about/legal',
        bot: '/bot',
        privacyPolicy: '/about/privacy-policy',
    },
    search: '/search',
    square: {
        full: '/square',
        // Link to the page with no query filled
        empty: '/square',
        // Reverse of getReplayFromLocation
        share: (query: string, highlight: IdeaKey | null, result: IdeaAndSingleScore[], sessionId: string) => {
            const highlightPart = highlight === null ? '' : `&h=${toHash(highlight)}`;
            return `/square?q=${encodeURIComponent(query)}${highlightPart}&r=${JSON.stringify(result.map(ideaAndScore => `${ideaAndScore[1]}:${toHash(ideaAndScore[0].key)}`))}&o=${sessionId}`
        },
        // When the user triggers a search by changing the input, we change the url to reflect this change in case the user wants to share himself and for history.
        changeInput: (query: string) => `/square?q=${encodeURIComponent(query)}`,
    },
    alert: {
        create: {
            full: '/alert/create/:sid',
            fn: (sid: string): string => `/alert/create/${sid}`,
        },
        validateEmail: {
            full: '/alert/validation/:token',
        },
    },
    email: '/email/:key',
    share: {
        home: '/share',
    },
    gotoIdea: {
        full: '/goto',
        fn: (url: string, sid: string) => `${routes.gotoIdea.full}?url=${encodeURIComponent(url)}&sid=${sid}`,
    },
    moderation: {
        home: '/mod',
        userReactions: {
            full: '/mod/user-reactions/:uid',
            fn: (uid: number): string => `/mod/user-reactions/${uid}`,
        },
        ideasRelations: {
            full: '/mod/idea-relations/:mid/:iid',
            fn: (key: IdeaKey) => `/mod/idea-relations/${key.merchant_id}/${encodeURIComponent(key.id_within_merchant)}`,
        },
    },
    blog: {
        home: '/articles',
    },
    admin: {
        home: '/admin',
        stats: {
            overview: '/admin/stats/overview',
            discovery: '/admin/stats/discovery',
            performance: '/admin/stats/performance',
            performanceEvolution: '/admin/stats/performance-evolution',
            quality: '/admin/stats/quality',
            olap: '/admin/stats/olap',
        },
        crawl: {
            home: '/admin/crawl',
            reports: '/admin/crawl/reports',
            tester: '/admin/crawl/tester',
            auto: '/admin/crawl/auto',
            flows: '/admin/crawl/flows',
        },
        analytics: '/admin/analytics',
        podium: '/admin/podium',
        searchSessions: {
            sessionsAnalysis: '/admin/analytics/search-sessions',
            feedbackAnalysis: '/admin/analytics/feedback',
            detail: {
                full: '/admin/search-session/:sid',
                fn: (sessionId: string): string => `/admin/search-session/${sessionId}`
            }
        },
        tag: {
            redundantAnalysis: '/admin/tag/ra',
            obvious: '/admin/tag/obvious',
            suggestions: '/admin/tag/suggestions',
            learning: {
                home: '/admin/tag/learning',
            },
        },
        merchants: {
            home: '/admin/merchants',
        },
        performance: {
            home: '/admin/perf',
        },
        automatedAnswers: {
            home: '/admin/automated-answers',
        },
        twins: {
            home: '/admin/twins',
        },
        testUi: '/admin/testui',
        testSize: '/admin/testsize',
    },
    productionUiHost: 'https://www.nebuly.fr',
}

export function routeToUrl(route: string) {
    return `${window.location.origin}${route}`;
} 


export default routes;