import * as React from 'react';
import { HashedIdeaKey, toHash } from "../../../model/idea";
import { SearchEnvironment } from '../../../model/search';
import { coalesce } from '../../../util/common';
import { isFailedQuery, isNotStartedQuery, isPendingQuery, isReadyQuery, QueryHookResult, useQueryJsonJson } from "../../hooks/Query";
import { useSimpleTranslation } from "../../hooks/Translator";
import { extractMetadataFromLocation } from '../common';
import { SquareLayout, SquareReplay, SquareResult } from './model';
import SquareIdea, { ShareCommonAllIdeas, SingleSquareLayout } from './SquareIdea';
import WaitingForInput from './WaitingForInput';

const highlightSideIncrease = 80;

function getSingleSquareLayout(layout: SquareLayout, i: number, highlight: boolean): SingleSquareLayout {
    const defaultSide = layout.side / 3;
    const row = Math.floor(i / 3);
    const col = i % 3;
    let side = defaultSide;
    let top = row * layout.side / 3;
    let left = col * layout.side / 3;
    let needsCloseButton = false;
    if (highlight) {
        // On a large screen we highlight with a light side increase.
        // On small screens we highlight by taking the full square size
        const smallScreen = side < 200;
        if (smallScreen) {
            side = layout.side;
            top = 0;
            left = 0;
            // Since we take all the space we need a close button
            needsCloseButton = true;
        } else {
            side = defaultSide + highlightSideIncrease;
            top -= row * highlightSideIncrease / 2;
            left -= col * highlightSideIncrease / 2;
        }
    }
    return {
        top,
        left,
        side,
        needsCloseButton,
    };
}

const Square: React.FC<{
    result: SquareResult,
    layout: SquareLayout,
    sessionId: string,
    shareCommon: ShareCommonAllIdeas,
    highlightFromShare: HashedIdeaKey | undefined,
}> = ({result, layout, sessionId, shareCommon, highlightFromShare}) => {
    const [highlight, setHighlight] = React.useState<string>(coalesce(highlightFromShare, ''));
    return <div className="square" style={{width: layout.side, height: layout.side}}>
        {result.ideas.map(([idea, score], i) => {
            const hash = toHash(idea.key);
            const highlighted = hash === highlight;
            return <SquareIdea 
            key={toHash(idea.key)} 
            idea={idea} 
            shareCommon={shareCommon}
            layout={getSingleSquareLayout(layout, i, highlighted)} 
            highlighted={highlighted}
            faded={highlight.length > 0  && !highlighted}
            onHover={hash => setHighlight(coalesce(hash, ''))}
            sessionId={sessionId}
            />;
        })}
    </div>;
}

const QuerySquare: React.FC<{
    result: QueryHookResult<SquareResult>,
    layout: SquareLayout,
    query: string,
    indicateWaitingForInput: boolean,
    highlightFromShare: HashedIdeaKey | undefined,
}> = ({result, layout, query, indicateWaitingForInput, highlightFromShare}) => {
    const t = useSimpleTranslation();
    if (isPendingQuery(result)) {
        return <div>{t.common.loading}</div>;
    }
    if (isNotStartedQuery(result)) {
        if (indicateWaitingForInput) {
            return <WaitingForInput/>;
        } else {
            return null;
        }
    }
    if (isFailedQuery(result)) {
        return <div>{t.common.errors.applicationError}</div>;
    } 
    if (isReadyQuery(result)) {
        const shareCommon: ShareCommonAllIdeas = {
            result: result.ideas,
            sessionId: result.sid,
            query,
        };
        return <Square result={result} layout={layout} sessionId={result.sid} shareCommon={shareCommon} highlightFromShare={highlightFromShare} />;
    } 
    const n: never = result;
    return n;
}

export type ReplayFromLocation = {
    query: string,
    // Same as SquareReplay.r
    result: string[],
    highlight: HashedIdeaKey | undefined,
    // The session id from which this result was shared.
    sourceSessionId: string | undefined,
}

function createReplayQuery(l: ReplayFromLocation, searchEnvironment: SearchEnvironment): SquareReplay | null {
    return {
        t: l.query,
        r: l.result,
        h: l.highlight,
        os: l.sourceSessionId,
        e: searchEnvironment,
        l: extractMetadataFromLocation(),
    };
}

const ReplaySquare: React.FC<{
    fromLocation: ReplayFromLocation,
    layout: SquareLayout,
    searchEnvironment: SearchEnvironment,
    setSessionId: (s: string | undefined) => void,
}> = ({fromLocation, layout, searchEnvironment, setSessionId}) => {
    const replay = React.useMemo(() => createReplayQuery(fromLocation, searchEnvironment), [fromLocation, searchEnvironment]);
    const {result} = useQueryJsonJson<SquareReplay, SquareResult>('public/square/r', replay, {
        callback: r => {
            setSessionId(r.sid);
        },
        errorCallback: _e => {
            setSessionId(undefined);
        }
    });
    if (replay === null) {
        return null;
    }
    return <QuerySquare {...{layout, result, query: '', indicateWaitingForInput: false, highlightFromShare: fromLocation.highlight}}/>
}

export {
    QuerySquare,
    ReplaySquare,
};

