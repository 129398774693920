import * as React from 'react';
import { VisitSource, isNaturalVisitSource, isGoogleVisitSource, GoogleKeyword } from '../../../model/search';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import { toUnit, reverse } from '../../../util/strings';
import { usize } from '../../../model/rust';
import If from '../../base/If';
import routes from '../../../routes';

export type Props = {
    url: string,
}

function extractParamValue(location: string, from: usize): string {
    const rest = location.substring(from);
    const andPos = rest.indexOf('&');
    if (andPos === -1) {
        return rest;
    } else {
        return rest.substring(0, andPos);
    }
}

// TS version of VisitSource::from_location with gclid handling on top.
export function extractVisitSource(urlSearchPart: string): VisitSource {
    if (urlSearchPart.length === 0) {
        return 'Natural';
    }
    const pos_gk = urlSearchPart.indexOf("gk=");
    const pos_gks = urlSearchPart.indexOf("gks=");
    const pos_gc = urlSearchPart.indexOf("gc=");
    const pos_gclid = urlSearchPart.indexOf("gclid=");

    let [pos_kw, strict] = [-1, false];
    if (pos_gk >= 0 ) {
        pos_kw = pos_gk + "gk=".length;
    }
    if (pos_gks >= 0) {
        pos_kw = pos_gks + "gks=".length;
        strict = true;
    };

    if (pos_gclid === -1 && pos_gc === -1 && pos_kw === -1) {
        return 'Natural';
    }
    let keyword: GoogleKeyword | undefined = undefined;
    let campaign = undefined;
    let gclid = undefined;
    if (pos_gclid >= 0) {
        gclid = extractParamValue(urlSearchPart, pos_gclid + 'gclid='.length);
    }
    if (pos_kw >= 0) {
        keyword = {keyword: extractParamValue(urlSearchPart, pos_kw), strict};
    }
    if (pos_gc >= 0) {
        campaign = extractParamValue(urlSearchPart, pos_gc + 'gc='.length);
    }
    return {Google: {keyword, campaign, gclid}};
}

function isSquare(url: string) {
    return url.startsWith(routes.square.full);
}

class VisitLocation extends React.Component<Props> {

    public render() {
        const square = isSquare(this.props.url);
        try {
            const source = extractVisitSource(this.props.url);
            if (isNaturalVisitSource(source)) {
                return square ? `Natural □` : `Natural`;
            } else if (isGoogleVisitSource(source)) {
                const {gclid, keyword, campaign} = source.Google;
                const color1 = gclid === undefined ? '#000' : d3ScaleChromatic.interpolateRainbow(toUnit(gclid));
                const color2 = gclid === undefined ? '#000' : d3ScaleChromatic.interpolateRainbow(toUnit(reverse(gclid)));
                const r = 10;
                const strict = keyword === undefined ? false : keyword.strict;
                return <div className="row" style={{alignItems: 'center'}}>
                    <div style={{fontWeight: 'bold', margin: 4}}>G</div>
                    <If test={square}>
                        □
                    </If>
                    <If test={campaign!== undefined}>
                        <div style={{margin: 4, color: '#555'}}>{campaign}</div>
                    </If>
                    <div style={{textDecoration: strict ? 'underlined' : 'dashed'}}>{keyword === undefined ? 'no kw' : decodeURI(keyword.keyword)}</div>
                    <div style={{backgroundColor: color1, borderRadius: '50%', width: r, height: r, marginLeft: 4}} title={gclid}/>
                    <div style={{backgroundColor: color2, borderRadius: '50%', width: r, height: r}} title={gclid}/>
                </div>
            } else {
                return `Unhandled source type: ${JSON.stringify(source)}`;
            }
        } catch (e) {
            return e.message;
        }
    }
}

export default VisitLocation;