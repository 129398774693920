import * as H from 'history';
import debounce from 'lodash.debounce';
import qs from 'query-string';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { HashedIdeaKey } from '../../../model/idea';
import routes, { routeToUrl } from '../../../routes';
import AnimatedPlaceholderInput from '../../base/AnimatedPlaceholderInput';
import If from '../../base/If';
import Refresh from '../../base/Refresh';
import FullLogo from '../../FullLogo';
import { useAccount } from '../../hooks/Account';
import { useStatePersisted } from '../../hooks/Persist';
import { isReadyQuery, QueryHookResult, useQueryJsonJson } from '../../hooks/Query';
import useResponsive from '../../hooks/Responsive';
import { useSimpleTranslation } from '../../hooks/Translator';
import { PageSize } from '../../ResponsiveHOC';
import { createEnvironment, extractMetadataFromLocation } from '../common';
import ResponsiveShare from '../share/ResponsiveShare';
import SquareDebugGuard from './DebugGuard';
import { InterruptFooter, InterruptHeader } from './Interrupt';
import './Main.scss';
import { IdeaAndSingleScore, SquareLayout, SquareQuery, SquareResult } from './model';
import { QuerySquare, ReplayFromLocation, ReplaySquare } from './Square';

function getReplayFromLocation(search: string): ReplayFromLocation | undefined {
    const paramSearch = qs.parse(search);
    if (paramSearch.q !== undefined) {
        const query = paramSearch.q as string;
        if (query.trim().length === 0) {
            return undefined;
        }
        let highlight = undefined;
        let result = [];
        let sourceSessionId = undefined;
        if (paramSearch.h !== undefined) {
            highlight = paramSearch.h as HashedIdeaKey;
        }
        if (paramSearch.r !== undefined) {
            try {
                result = JSON.parse(paramSearch.r as string);
            } catch (e) {
                // Invalid url content, could have been forged
                // TODO check that we got an array of strings
            }
        }
        if (paramSearch.o !== undefined) {
            sourceSessionId = paramSearch.o as string;
        }
        return {
            query,
            highlight,
            result,
            sourceSessionId,
        };
    } else {
        return undefined;
    }
}

function triggerQueryDirectly(
    text: string, 
    history: H.History,
    setQueryToSend: React.Dispatch<React.SetStateAction<string>>) {
    setQueryToSend(text);
    history.push(routes.square.changeInput(text));
}

const triggerQueryLater = debounce(triggerQueryDirectly, 1000);

function computeLayout(p: PageSize): SquareLayout {
    const aboveMin = 150;
    const below = 20;
    const marginLeftRight = below;
    const availableHeight = p.y - aboveMin - below;
    const availableWidth = p.x - marginLeftRight * 2;
    const side = Math.min(availableHeight, availableWidth);
    // Center verticaly
    const above = (availableHeight - side)/ 2 + aboveMin;
    return {
        side,
        below,
        above,
        inputWidth: Math.min(400, p.x - 40),
    }
}

function performQuery(fromLocation: ReplayFromLocation | undefined, queryToSend: string) {
    if (queryToSend === '') {
        // Do not perform the query if the input is empty
        return false;
    }
    if (fromLocation !== undefined) {
        // This is a shared square query, display its result if it is not included in the location
        return fromLocation.result.length === 0;
    }
    return true;
}

const Share: React.FC<{
    query: string,
    result: QueryHookResult<SquareResult>, 
    sessionId: string | undefined
}> = ({query, result, sessionId}) => {
    const t = useSimpleTranslation();
    const disabled = query.trim().length < 1 || sessionId === undefined || !isReadyQuery(result);
    let shareResult: IdeaAndSingleScore[] = [];
    if (isReadyQuery(result)) {
        shareResult = result.ideas;
    }
    return <ResponsiveShare
        disabled={disabled}
        title={t.search.square.share.title}
        text={t.search.square.share.text}
        url={routeToUrl(routes.square.share(query, null, shareResult, sessionId as string))}
     />
}
 
const Main: React.FC<RouteComponentProps<{}>> = ({location, history}) => {
    const t = useSimpleTranslation();
    const fromLocation = React.useMemo(() => getReplayFromLocation(location.search), [location.search]);
    const initialQuery = fromLocation === undefined ? '' : fromLocation.query;
    const {isAdmin} = useAccount();
    const {pageSize, dpi} = useResponsive();
    const [queryInput, setQueryInput] = React.useState<string>(initialQuery);
    const [queryToSend, setQueryToSend] = React.useState<string>(initialQuery);
    const [sessionId, setSessionId] = useStatePersisted<string | undefined>(undefined, 'sqsid');
    const inputEl = React.useRef<HTMLInputElement>(null);
    const layout = computeLayout(pageSize);
    // We do not update the environment we send to the server every time the user changes its page size. The dpi should not change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchEnvironment = React.useMemo(() => createEnvironment(pageSize, dpi), [dpi]);
    const shouldPerformQueryFromInput = performQuery(fromLocation, queryToSend);
    const payload: SquareQuery | null = React.useMemo(() => {
            if (shouldPerformQueryFromInput) {
                return {
                    t: queryToSend,
                    sid: sessionId,
                    e: searchEnvironment,
                    l: extractMetadataFromLocation(),
                };
            } else {
                return null;
            }
        }, 
        // Do not put sessionId in the memo parameters to not retrigger the query when only this parameter changed. 
        // When the text changes afterward the sessionId will have a good value.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queryToSend, searchEnvironment, fromLocation, shouldPerformQueryFromInput]
    );
    const {result, refresh} = useQueryJsonJson<SquareQuery, SquareResult>('public/square/q', payload, {
        callback: r => {
            setSessionId(r.sid);
        },
        errorCallback: _e => {
            setSessionId(undefined);
        }
    });
    React.useEffect(() => {
        if (inputEl.current === null) {
            return;
        }
        // Do not focus on mobile because this opens the virtual keyboard that is big and hides almost everything.
        // TODO still focus on desktop
        // inputEl.current.focus();
    }, [inputEl]);
    return <div className="square-and-form">
        <div className="square-above" style={{height: layout.above}}>
            <Link to={routes.home}>
                <FullLogo includeBeta/>
            </Link>
            <div className="square-input-line">
                <label>
                    {t.search.square.label}
                </label>
                <AnimatedPlaceholderInput 
                className="square-input" 
                innerRef={inputEl}
                placeholders={t.search.square.placeholders} 
                style={{width: layout.inputWidth}} 
                inputStyle={{width: layout.inputWidth - 20}} 
                value={queryInput}
                periodMs={2000} 
                onChange={t => {
                    setQueryInput(t);
                    triggerQueryLater(t, history, setQueryToSend);
                }}/>
                <Share query={queryInput} result={result} sessionId={sessionId} />
                <If test={isAdmin}>
                    <Refresh onClick={refresh}/>
                </If>
            </div>
            <InterruptHeader result={result} />
        </div>
        <QuerySquare query={queryToSend} {...{result, layout, indicateWaitingForInput: fromLocation === undefined, highlightFromShare: undefined}}/> 
        <SquareDebugGuard result={result}/>
        <If test={fromLocation !== undefined && !shouldPerformQueryFromInput}>
            <ReplaySquare {...{layout, fromLocation: fromLocation as ReplayFromLocation, searchEnvironment, setSessionId}}/>
        </If>
        <InterruptFooter result={result} />
    </div>;
};

export default Main;