import * as React from 'react';
import { Point } from '../../util/geometry';
import { retrieveImageSize } from '../hooks/Image';

// Displays a single idea image taking the maximum size not exceeding a width and height while keeping ratio.

type Props = {
    maxWidth: number,
    maxHeight: number,
    src: string,
    style?: React.CSSProperties,
};

type State = {
    width: number,
    height: number
    imageSize: Point,
}

const defaultState: State = {
    width: -1,
    height: -1,
    imageSize: [-1, -1],
}

let lastComputedState = {src: '', state: defaultState};

// Fit a rectangle inside another by keeping its ratio.
// ratioRect is the rectangle representing the image here
// sizeRect is the rectangle representing the maximum size here
// Based on DiaPop Wallpapers.fitRect
function computeSize(sizeRect: Point, ratioRect: Point): Point {
    // 0 is width, 1 is height
    const distort = ratioRect[0] * sizeRect[1] - ratioRect[1] * sizeRect[0];
    if (distort > 0) {
        // inside is too wide / container is too tall, we need to add a border above and below inside
        const d = (sizeRect[1] - (ratioRect[1] * sizeRect[0]) / ratioRect[0]);
        return [sizeRect[0], sizeRect[1] - d];
    }
    else if (distort < 0) {
        // inside is too tall / container is too wide, we need to add a border to the left and right of inside
        const d = (sizeRect[0] - (ratioRect[0] * sizeRect[1]) / ratioRect[1]);
        return [sizeRect[0] - d, sizeRect[1]];
    }
    else {
        // distort == 0, both rect have same ratio, just fill the container
        return sizeRect;
    }
}

class LargeIdeaImage extends React.Component<Props, State> {

    // Business Logic

    private updateState(props: Props, imageSize: Point) {
        const size = computeSize([props.maxWidth, props.maxHeight], imageSize);
        lastComputedState = {src: props.src, state: {width: size[0], height: size[1], imageSize}};
        this.setState({imageSize, width: size[0], height: size[1]});
    }

    private async onPropsChange(props: Props, oldProps?: Props) {
        const oldSrc = oldProps === undefined ? undefined : oldProps.src;
        let imageSize = this.state.imageSize;
        if (oldSrc !== props.src) {
            imageSize = await retrieveImageSize(props.src);
        }
        this.updateState(props, imageSize);
    }

    // LifeCycle

    constructor(props: Props) {
        super(props);
        if (props.src === lastComputedState.src) {
            this.state = lastComputedState.state;
        } else {
            this.state = defaultState;
        }
    }

    componentDidMount() {
        this.onPropsChange(this.props, undefined);
    }

    componentDidUpdate(oldProps: Props) {
        if (oldProps.src !== this.props.src || oldProps.maxHeight !== this.props.maxHeight || oldProps.maxWidth !== this.props.maxWidth) {
            this.onPropsChange(this.props, oldProps);
        }
    }

    render() {
        const {width, height} = this.state;
        const {style, maxWidth, maxHeight} = this.props;
        if (width === -1 || height === -1) {
            // Render a placeholder instead to avoid layout glitches while computing the correct size.
            return <div style={{width: maxWidth, height: maxHeight, visibility: 'hidden'}}>&nbsp;</div>;
        }
        return <img className="large-image" src={this.props.src} style={{...style, width, height}} alt="Large idea representation"/>;
    }
}

export default LargeIdeaImage;