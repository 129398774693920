import * as React from 'react';
import { useSelector } from 'react-redux';
import { SearchState, StoreState } from '../../../redux/store';
import routes from '../../../routes';
import { LinkButton } from '../../base/Button';
import { isReadyQuery, QueryHookResult } from '../../hooks/Query';
import { useSimpleTranslation } from '../../hooks/Translator';
import { propagateSearchAndHashFromLocation } from '../StartQuestions';
import './Interrupt.scss'

function shouldShow(result: QueryHookResult<any>, search: SearchState) {
    if (!isReadyQuery(result)) {
        return false;
    }
    if (!search.hasChosenGender) {
        // We did not interrupt a started search, do not display
        return false;
    }
    return true;
}

const InterruptHeader: React.FC<{result: QueryHookResult<any>}> = ({result}) => {
    const t = useSimpleTranslation();
    const search = useSelector((s: StoreState) => s.search);
    if (!shouldShow(result, search)) {
        return null;
    }
    return <div className="square-interrupt-header">{t.search.square.interruptHeader}</div>;
};

const InterruptFooter: React.FC<{result: QueryHookResult<any>}> = ({result}) => {
    const t = useSimpleTranslation();
    const search = useSelector((s: StoreState) => s.search);
    if (!shouldShow(result, search)) {
        return null;
    }
    return <div className="square-interrupt-footer">
        <LinkButton filled type="primary" to={propagateSearchAndHashFromLocation(routes.search)} label={t.search.square.interruptFooder}/>
    </div>;
};

export {
    InterruptHeader,
    InterruptFooter,
};