const palette = {
    c1: '#808eff',
    c2: '#0069f2',
    c2a: '#b8d5fb',
    c3: '#30e0ff',
    c3a: '#9af0ff',
    c4: '#29ecb9',
    c4a: '#acf8e4',
    c1Darker: '#4e5dca',
    c5: '#cf8e50',
    midC1C3: '#58b6ff',
};

const colors = {
    palette,
    fg: {
        main: palette.c1Darker,
        darker: '#04235a',
        highlight: palette.c3,
    },
    bg: {
        main: '#fff',
        darker: '#e7f1ff',
        darker2: '#c5ddff',
        darker3: '#b8d5fb',
    },
    error: {
        foreground: 'rgb(167, 21, 21)'
    }
};

export  {
    colors
};