import React from 'react';
import { AccountInfo, AccountType, isModerator as isUserModerator, ServerAccountInfo } from '../../model/user';
import { isReadyQuery, QueryHookResult, useQueryJson } from './Query';

const isModerator = (accountInfo: AccountInfo) => isUserModerator(accountInfo.type);
const isAdmin = (accountInfo: AccountInfo) => accountInfo.type === AccountType.Admin;

type UseAccountResult = {
    accountInfo: AccountInfo,
    accountInfoLoaded: boolean,
    logOut: () => void,
    logIn: (accountInfo: AccountInfo) => void,
    invalidateAccountInfo: () => void,
    isLoggedIn: boolean,
    isModerator: boolean,
    isAdmin: boolean,
}

const NON_LOGGED: AccountInfo = {email: '', type: AccountType.NonLogged};

function toClientAccountInfo(queryAccountInfo: QueryHookResult<AccountInfo>) {
    if (isReadyQuery(queryAccountInfo)) {
        return queryAccountInfo;
    } else {
        return NON_LOGGED;
    }
}

function useAccount(): UseAccountResult {
    const {result: queryAccountInfo, refresh, setResult: setAccount} = useQueryJson<AccountInfo>('account/info', {persistPath: 'account-info', transformResponse: s => {
        const server = JSON.parse(s) as ServerAccountInfo;
        return {
            email: server.email,
            type: server.account_type
        };
    }});
    const accountInfo = React.useMemo(() => toClientAccountInfo(queryAccountInfo), [queryAccountInfo]);
    const logOut = () => setAccount(NON_LOGGED);
    return {
        accountInfo,
        accountInfoLoaded: isReadyQuery(queryAccountInfo),
        isLoggedIn: accountInfo.type !== AccountType.NonLogged,
        logIn: (accountInfo: AccountInfo) => setAccount(accountInfo),
        logOut,
        invalidateAccountInfo: refresh,
        isAdmin: isAdmin(accountInfo),
        isModerator: isModerator(accountInfo),
    }
}

export {
    useAccount
};
