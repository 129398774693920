import * as React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import {useSimpleTranslation} from '../../hooks/Translator';
import NativeShare from './NativeShare';

import alternateEmail from '../../../images/share/alternate_email.svg';
import gmail from '../../../images/share/gmail.png';
import native from '../../../images/share/native.svg';
import whatsapp from '../../../images/share/whatsapp.png';

import './Home.scss';
import { SharePayload } from '../../../redux/actions/share';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../redux/store';

const Home: React.FC<{}> = () => {
    const t = useSimpleTranslation();
    const payload: SharePayload = useSelector((s: StoreState) => s.share.payload);
    return <div className="centered-column">
        <h1>{t.search.share.title}</h1>
        <p className="p-wrap">{t.search.share.text}</p>
        <div className="row">
            <a className="share-button" href={`mailto:?subject=${payload.title}&body=${payload.text}`}>
                <img src={alternateEmail} height={36} alt="Email Logo Arobase"/>
                <span>{t.search.share.email}</span>
            </a>
            <a className="share-button" href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&su=${payload.title}&body=${payload.text}&ui=2&tf=1`}>
                <img src={gmail} height={30} style={{marginTop: 3, marginBottom: 3}} alt="Gmail Logo"/>
                <span>{t.search.share.gmail}</span>
            </a>
            <a className="share-button" href={`https://api.whatsapp.com/send?text=${payload.text}`} data-action="share/whatsapp/share">
                <img src={whatsapp} height={36} alt="WhatsApp Logo"/>
                <span>{t.search.share.whatsapp}</span>
            </a>
            <NativeShare payload={payload}>
                <div className="share-button">
                    <img src={native} height={36} alt="Native share icon"/>
                    <span>{t.search.share.native}</span>
                </div>
            </NativeShare>
        </div>
        <Link to={routes.search}>{t.search.share.cancelLabel}</Link>
    </div>
}

export default Home;