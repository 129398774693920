
export function uppercaseFirstLetter(s: string): string {
    return `${s.charAt(0).toUpperCase()}${s.substring(1)}`;
}

const MAX = 2147483647;
const MIN = -2147483648;

export function toUnit(s: string) {
    let hash = 0; let i; let chr;
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
        chr   = s.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    const between0And1 = (hash - MIN) / (MAX - MIN);
    return between0And1;
}


export function reverse(s: string): string {
    let r = '';
    for (let i = s.length - 1; i >= 0; i--) {
        r = r + s.charAt(i);
    }
    return r;
}

export function isValidEmailLight(email: string) {
    const atPos = email.indexOf('@');
    if (atPos === -1) {
        return false;
    }
    const dotPos = email.substring(atPos).indexOf('.');
    return dotPos !== -1;
}