import * as React from 'react';

import CoreSlider, {createSliderWithTooltip, SliderProps} from 'rc-slider';
import { WithTooltipProps } from './Range';

const LibrarySlider = createSliderWithTooltip(CoreSlider);

class Slider extends React.Component<WithTooltipProps & SliderProps> {
    public render() {
        return <LibrarySlider {...this.props} />
    }
}

export default Slider;