import * as React from 'react';

import wrapI18N, { PropsFromI18N } from '../../i18n/TranslatorHOC';

type PropsFromParent = {
    onKeyPress: (ev: KeyboardEvent) => void,
}

type Props = PropsFromI18N & PropsFromParent;

class KeyboardShortcuts extends React.Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    public async componentDidMount() {
        window.addEventListener('keydown', this.onKeyPress);
    }

    public componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyPress);
    }

    // Event Handling

    private onKeyPress(ev: KeyboardEvent) {
        // Ignore key presses when editing inputs.
        if (document.activeElement !== null) {
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                return;
            }
        }
        this.props.onKeyPress(ev);
    }

    public render() {
        return null;
    }
}

export const keyboardShortcuts = {
    contrib: {
        idea: {
            enrich: {
                decreaseRating: '-',
                increaseRating: '+',
                moveToNext: 'n',
                cycleReaction: 'c',
                focusInput: 'i',
                validate: 'v',
            }
        },
        suggestions: {
            chooseYes: 'i',
            chooseNo: 'o',
            reload: 'r',
            chooseBetter: 'a',
            chooseDesired: 'z',
            chooseNeeded: 'e',
            chooseHigh: 'q',
            chooseNormal: 's',
            chooseLow: 'd',
            chooseNone: 'f',

        }
    }
}

export default wrapI18N(KeyboardShortcuts);