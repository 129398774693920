import * as React from 'react';
import {RouteComponentProps, Link} from 'react-router-dom';
import { useSelector } from 'react-redux';

import StartQuestions, { isMultiPart } from './search/StartQuestions';
import HomePageLinks from './HomePageLinks';

import './Home.scss';
import './search/Home.scss';
import routes from '../routes';
import { StoreState } from '../redux/store';
import If from './base/If';
import { MultiPartStartStep, stepsOrder } from '../redux/reducers/search';
import FullLogo from './FullLogo';
import useResponsive from './hooks/Responsive';
import Footer from './Footer';
import { useSimpleTranslation } from './hooks/Translator';
import FooterContent from './search/FooterContent';

export const squareLinkId = 'square-link';

function renderPresentationSpacer(step: MultiPartStartStep, flex: number) {
    return <If test={step === stepsOrder[0]}>
        <div className="spacer" style={{flex}}>&nbsp;</div>
    </If>;
}

const SearcherHome: React.FC<RouteComponentProps<{}>> = (props) => {
    const t = useSimpleTranslation();
    const step = useSelector((s: StoreState) => s.search.startStep);
    const {pageSize} = useResponsive();
    const presentation = !isMultiPart(pageSize) || step === stepsOrder[0];
    return <div className="home centered-column" style={{height: pageSize.y}}>
        {renderPresentationSpacer(step, 2)}
        <If test={presentation}>
            <Link to={routes.about.home} className="full-logo-link"><FullLogo height={pageSize.y / 8}/></Link>
        </If>
        <StartQuestions history={props.history}/>
        {renderPresentationSpacer(step, 1)}
        <If test={presentation}>
            <div id={squareLinkId}>
                <Link to={routes.square.empty}>{t.search.square.linkFast}</Link>
            </div>
        </If>
        {renderPresentationSpacer(step, 2)}
        <Footer visible={presentation}>
            <HomePageLinks/>
            <FooterContent />
        </Footer>
    </div>;
}

export default SearcherHome;