import * as React from 'react';
import { Suspense } from 'react';
import * as H from 'history';

import { SearchFlow, ServerSearchDebugInfo, SetSearchState } from '../../server/SearchFlow';
import Drawer from '../base/Drawer';
import { useSimpleTranslation } from '../hooks/Translator';
const DebugInfo = React.lazy(() => import('./DebugInfo'));

type Props = {
    debugInfo?: ServerSearchDebugInfo,
    flow?: SearchFlow,
    history: H.History,
    updateState: SetSearchState,
}

const DebugInfoGuard: React.FC<Props> = ({debugInfo, flow, history, updateState}) => {
    const t = useSimpleTranslation();
    if (debugInfo === undefined) {
        return null;
    }
    if (flow === undefined) {
        return null;
    }
    return <div style={{position: 'absolute', left: 5, top: 5, width: '30vw'}}>
            <Drawer label={t.search.debugInfo.header}>
            <Suspense fallback={<div>{t.common.loading}</div>}>
                <DebugInfo
                id={flow.id}
                history={history}
                debugInfo={debugInfo}
                updateState={updateState} />
            </Suspense>
        </Drawer>
    </div>
    ;
}

export default DebugInfoGuard;