import { useEffect, useState } from 'react';
import { Point } from '../../util/geometry';

export async function retrieveImageSize(src: string): Promise<Point> {
    const img = new Image();
    return new Promise<Point>((resolve, _reject) => {
        img.onload = function() {
            resolve([img.width, img.height]);
        };
        img.src = src;
    });
}

export function useImageSize(src: string) {
    const [size, setSize] = useState<Point>([-1, -1]);
    useEffect(() => {
        retrieveImageSize(src).then(newSize => {
            setSize(newSize);
        });
    }, [src]);
    return size;
}