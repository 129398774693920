import { Action } from "../../../actions";
import { SET_IDEAS } from "../../../actions/contrib/ideas/cohort";
import { ContribIdeasCohortState } from "../../../store";

const defaultState: ContribIdeasCohortState = {
    ideas: [],
};



const reducer = (state: ContribIdeasCohortState = defaultState, action: Action = {type: ''}): ContribIdeasCohortState => {
    switch (action.type) {
        case SET_IDEAS:
            return {...state, ideas: action.ideas};
        default:
            return state;
    }
}

export default reducer;