import { combineReducers } from 'redux';
import { Action, CLEAR } from '../actions';
import { StoreState } from '../store';
import contribReactionsReducer from './contrib/reactions';
import contribQuestionsAddReducer from './contrib/questions/add';
import searchReducer from './search';
import { contribGenericIdeasReducer, contribMerchantIdeasReducer } from './contrib/ideas/add';
import contribEnrichIdeaReducer from './contrib/ideas/enrich';
import contribIdeasCohortReducer from './contrib/ideas/cohort';
import moderationReducer from './moderation';
import analysisReducer from './admin/analysis';
import shareReducer from './share';

const ideasAddContribReducer = combineReducers({
    generic: contribGenericIdeasReducer,
    merchant: contribMerchantIdeasReducer,
});

const ideasContribReducer = combineReducers({
    add: ideasAddContribReducer,
    enrich: contribEnrichIdeaReducer,
    cohort: contribIdeasCohortReducer,
})

const questionsContribReducer = combineReducers({
    add: contribQuestionsAddReducer,
});

const contribReducer = combineReducers({
    reactions: contribReactionsReducer,
    ideas: ideasContribReducer,
    questions: questionsContribReducer,
});

const adminReducer = combineReducers({
    analysis: analysisReducer,
})

const fullReducer = combineReducers({
    contrib: contribReducer,
    search: searchReducer,
    moderation: moderationReducer,
    admin: adminReducer,
    share: shareReducer,
});

// https://stackoverflow.com/a/35641992
const clearerReducer = (state: StoreState | undefined, action: Action): StoreState => {
    if (action.type === CLEAR) {
        state = undefined;
    }
    return fullReducer(state, action);
}

const checkerReducer = (state: StoreState | undefined, action: Action): StoreState => {
    //console.log('action', action);
    const stateBefore = state;
    const stateAfter = clearerReducer(state, action);
    // We ignore the @@ actions which are internal redux actions since they contain the INIT action
    // that initializes the store and does nothing if we loaded it from the local storage.
    if (stateAfter === stateBefore && !action.type.startsWith('@@')) {
        // tslint:disable-next-line:no-console Used only in dev
        console.error("Action did not change root state:", action);
    }
    return stateAfter;
}

const production = process.env.NODE_ENV === 'production';
export default production ? clearerReducer : checkerReducer;