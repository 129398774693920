import { Idea, IdeaKey } from "../model/idea";
import querier from "./querier";
import { Question, AskableQuestion } from "../model/question";
import { AnswerOrFeedbackValue, mapAnswerToNumber, isAnswer, AnswerSource} from "../model/answer";
import { Feedback, mapFeedbackToNumber } from "../model/feedback";
import { HistogramData } from "../components/base/CountHistogram";
import { wait } from "../util/promises";
import { SearchEnvironment, StoredDebugInfo } from "../model/search";

const wsUrl = `${querier.wsUrl}/sws/`;
const idStorageKey = 'sfid';

const SIMULATE_SLOW_SERVER = false;

type SearchStart = { // Same on Server
    gender: string,
    age: number,
    price_min_cents: number,
    price_max_cents: number,
    from_location: string,
    hints: string,
    env: SearchEnvironment,
}
type ServerSearchAnswerDebugInfo = {
    question: Question,
    a: number,
    source: AnswerSource,
}

export type SearchFeature = {}

function parseFeatures(serverFeatures: string): SearchFeature[] {
    return [];
}

export type FinderStateDebugInfo = {
    ideas_count: number,
    count_ideas_by_score: HistogramData,
    ideas_with_highest_score: Idea[],
    answers: ServerSearchAnswerDebugInfo[],
}

export type ServerSearchDebugInfo = {
    state: FinderStateDebugInfo,
    stored: StoredDebugInfo[],
}

export type OutputState = {
    needsRestart: boolean,
    hasFoundGoodIdea: boolean,
    question?: AskableQuestion,
    idea?: Idea,
    ideaAsAQuestion: boolean,
    debugInfo?: ServerSearchDebugInfo,
    hasGivenUp: boolean,
    loading: boolean,
    ideasHighlight: Idea[],
    features: SearchFeature[],
}

export type SetSearchState = (state: Partial<OutputState>) => void;

type ClientMessageFlowId = { // Same on Server (excluding type)
    type: 'FLOW_ID',
    start: SearchStart,
}

type ClientMessageStartSync = { // Same on Server (excluding type)
    type: 'START_SYNC',
    start: SearchStart,
    flow_id: string,
}

type ClientMessageGetCurrentStep = { // Same on Server (excluding type)
    type: 'GET_CURRENT_STEP',
    id: string,
}

type ClientMessageAnswer = { // Same on Server (excluding type)
    type: 'ANSWER',
    flow_id: string,
    question_id: number,
    answer: number
}

type ClientMessageFeedback = { // Same on Server (excluding type)
    type: 'IDEA_FEEDBACK',
    flow_id: string,
    idea_key: IdeaKey,
    feedback: number
    aaq: boolean,
}

type ClientMessageClickedOnIdea = { // Same on Server (excluding type)
    flow_id: string,
    idea_key: IdeaKey,
}

const IDEAS_HIGHLIGHT_COUNT_CLIENT_MESSAGE_TYPE = 'IHC';

type ClientMessageIdeaHighlightCount = {
    type: typeof IDEAS_HIGHLIGHT_COUNT_CLIENT_MESSAGE_TYPE,
    id: string,
    n: number,
}

type ClientMessageMetadata = {
    type: 'M', // short for metadata
    f: string, // short for flow id
    k: string, // key
    v: string, // value 
}

type ServerMessageFlowId = {
    type: 'FLOW_ID',
    id: string,
    f: string, // short for features
}

type ServerMessageInvalidFlowId = {
    type: 'INVALID_FLOW_ID',
}

type ServerMessageQuestion = {
    type: 'QUESTION',
    question: AskableQuestion,
}

type ServerMessageIdea = {
    type: 'IDEA',
    idea: Idea,
    aaq: boolean,
}

type ServerMessageEnd = {
    type: 'END',
}

type ServerMessageDebugInfo = {
    type: 'DEBUG_INFO',
    state: FinderStateDebugInfo,
    stored: StoredDebugInfo[],
}

type ServerMessageGiveUp = {
    type: 'GIVE_UP',
}

type ServerMessageResume = {
    type: 'R', // short for resume
    f: string, // short for features
}

const IDEAS_HIGHLIGHT_SERVER_MESSAGE_TYPE = 'IH';

type ServerMessageIdeasHighlight = {
    type: typeof IDEAS_HIGHLIGHT_SERVER_MESSAGE_TYPE,
    ideas: Idea[],
}

type ServerMessage = ServerMessageFlowId | ServerMessageInvalidFlowId | ServerMessageQuestion | ServerMessageIdea | ServerMessageEnd | ServerMessageDebugInfo | ServerMessageGiveUp | ServerMessageIdeasHighlight | ServerMessageResume;
type ClientMessage = ClientMessageFlowId | ClientMessageGetCurrentStep | ClientMessageFeedback | ClientMessageAnswer | ClientMessageClickedOnIdea | ClientMessageStartSync | ClientMessageIdeaHighlightCount | ClientMessageMetadata;

async function sendMessage(socket: WebSocket, m: ClientMessage) {
    const toSend = JSON.stringify(m);
    socket.send(toSend);
}

export class SearchFlow {
    id: string;
    socket: WebSocket;
    setState: SetSearchState;
    startInstant: DOMHighResTimeStamp;
    questionsReceived: number;

    constructor(id: string, socket: WebSocket, setState: SetSearchState, startInstant: DOMHighResTimeStamp) {
        this.id = id;
        this.setState = setState;
        // The socket should be already open before calling this constructor.
        this.socket = socket;
        this.attachMessageListener(this.socket);
        this.startInstant = startInstant;
        this.questionsReceived = 0;
    }

    private attachMessageListener(socket: WebSocket) {
        const self = this;
        socket.addEventListener('message', function (event) {
            const json = JSON.parse(event.data);
            if (SIMULATE_SLOW_SERVER) {
                setTimeout(() => self.processServerMessage(json), 5000);
            } else {
                self.processServerMessage(json);
            }
        });
    }

    private async recreateWebsocket() {
        try {
            this.socket = await startWebsocket();
            this.attachMessageListener(this.socket);
        } catch (e) {
            // Could not start WS, server might be down or restarting, wait and retry
            await wait(2000);
            await this.recreateWebsocket();
        }
    }

    private async sendMessage(m: ClientMessage) {
        if (this.socket.readyState !== WebSocket.OPEN) {
            await this.recreateWebsocket();
        }
        sendMessage(this.socket, m);
    }

    private sendTimeToFirstQuestion() {
        this.sendMessage({type: 'M', f: this.id, k: 'ttfqms', v: `${performance.now() - this.startInstant}`});
    }

    private processServerMessage(json: ServerMessage) {
        switch (json.type) {
            case 'FLOW_ID':
                sessionStorage.setItem(idStorageKey, json.id);
                this.setState({features: parseFeatures(json.f)})
                break;
            case 'INVALID_FLOW_ID':
                sessionStorage.removeItem(idStorageKey);
                this.setState({needsRestart: true})
                break;
            case 'QUESTION':
                this.setState({question: json.question, loading: false});
                this.questionsReceived++;
                if (this.questionsReceived === 1) {
                    this.sendTimeToFirstQuestion();
                }
                break;
            case 'IDEA':
                this.setState({idea: json.idea, loading: false, ideaAsAQuestion: json.aaq});
                this.sendMessage({
                    flow_id: this.id,
                    type: 'IDEA_FEEDBACK',
                    idea_key: json.idea.key,
                    feedback: mapFeedbackToNumber('SawIdea'),
                    aaq: json.aaq,
                });
                break;
            case 'END':
                sessionStorage.removeItem(idStorageKey);
                this.setState({hasFoundGoodIdea: true});
                break;
            case 'DEBUG_INFO':
                this.setState({debugInfo: {state: json.state, stored: json.stored}});
                break;
            case 'GIVE_UP':
                sessionStorage.removeItem(idStorageKey);
                this.setState({hasGivenUp: true, loading: false});
                break;
            case IDEAS_HIGHLIGHT_SERVER_MESSAGE_TYPE:
                this.setState({ideasHighlight: json.ideas});
                break;
            case 'R':
                this.setState({features: parseFeatures(json.f)});
                break;
        }
    }

    public getCurrentStep() {
        this.setState({loading: true});
        this.sendMessage({type: 'GET_CURRENT_STEP', id: this.id});
    }

    public sendIdeasHighlightCount(count: number) {
        this.sendMessage({type: IDEAS_HIGHLIGHT_COUNT_CLIENT_MESSAGE_TYPE, n: count, id: this.id});
    }

    public onPickAnswer(questionId: number, answer: AnswerOrFeedbackValue): boolean {
        const shouldUpdateState = isAnswer(answer);
        if (shouldUpdateState) {
            this.setState({loading: true});
        }
        this.sendMessage({
            flow_id: this.id,
            type: 'ANSWER',
            question_id: questionId,
            answer: mapAnswerToNumber(answer)
        });
        return shouldUpdateState;
    }

    private feedbackShouldClearIdea(feedback: Feedback): boolean {
        switch (feedback) {
            case 'Reset': return true;
            case 'BadIdea': return true;
            case 'InconsistentIdea': return true;
            case 'YouCanDoBetter': return true;
            case 'YouAreClose': return true;
            case 'HasAlready': return true;
            case 'IDontKnow': return true;

            case 'ClickedOnIdea': return false;
            case 'GoodIdea': return false;
            case 'SawIdea': return false;
        }
    }

    public onPickFeedback(ideaKey: IdeaKey, feedback: Feedback, aaq: boolean) {
        if (aaq || this.feedbackShouldClearIdea(feedback)) {
            this.setState({idea: undefined});
            this.setState({loading: true});
        }
        this.sendMessage({
            flow_id: this.id,
            type: 'IDEA_FEEDBACK',
            idea_key: ideaKey,
            feedback: mapFeedbackToNumber(feedback),
            aaq,
        });
    }
}

function startNewFlow(start: SearchStart, setState: SetSearchState): Promise<SearchFlow> {
    const startInstant = performance.now();
    const socket = new WebSocket(wsUrl);
    return new Promise((resolve, _reject) => {
        const messageListener = function (event: MessageEvent) {
            const json: ServerMessage = JSON.parse(event.data);
            // Ignore everything but the flow id message
            if (json.type === 'FLOW_ID') {
                socket.removeEventListener('message', messageListener);
                sessionStorage.setItem(idStorageKey, json.id);
                setState({hasFoundGoodIdea: false, idea: undefined, question: undefined});
                resolve(new SearchFlow(json.id, socket, setState, startInstant));
            }
        };
        const openListener = function(_event: Event) {
            sendMessage(socket, {type: 'FLOW_ID', start});
            socket.removeEventListener('open', openListener);
        };
        socket.addEventListener('message', messageListener);
        socket.addEventListener('open', openListener);
    });
}

function startWebsocket(): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
        try {
            const socket = new WebSocket(wsUrl);
            let hasCalledResolve = false;
            const openListener = function(_event: Event) {
                resolve(socket);
                hasCalledResolve = true;
                socket.removeEventListener('open', openListener);
            };
            socket.addEventListener('open', openListener);
            socket.addEventListener('error', e => {
                if (hasCalledResolve) {
                    // Ignore, we cannot do anything about it, this is also handled when sending the message.
                } else {
                    reject(e);
                }
            });
        } catch (e) {
            // new WebSocket can fail
            reject(e);
        }
    });
}

async function resumeFlow(id: string, setState: SetSearchState, start: SearchStart | undefined): Promise<SearchFlow> {
    const socket = await startWebsocket();
    // The user went back to the search page after having maybe changed its search parameters in StartQuestions,
    // we tell so to the server so that it can restart the flow if it detected a change in the start data.
    if (start !== undefined) {
        sendMessage(socket, {
            type: 'START_SYNC',
            start,
            flow_id: id,
        });
    }
    return new SearchFlow(id, socket, setState, performance.now());
}

function resumeOrStartFlow(setState: SetSearchState, start?: SearchStart): Promise<SearchFlow> {
    const existingId = sessionStorage.getItem(idStorageKey);
    if (existingId === null) {
        if (start === undefined) {
            return Promise.reject();
        } else {
            return startNewFlow(start, setState);
        }
    } else {
        return resumeFlow(existingId, setState, start);
    }
}

export {
    resumeOrStartFlow
}