import { createStore } from "redux";
import { ServerTwinsFetchConfig, ServerTwinsGroup } from '../model/analysis';
import { UserSex } from "../model/gender";
import { ContribGenericIdea, FullContribIdea, HashedIdeaKey, Idea, ModerationContribGenericIdea, ModerationContribMerchantIdea } from '../model/idea';
import { ContribQuestion, ModerationContribQuestion, Question } from '../model/question';
import { Reaction, ServerUserReaction, ServerUserReactionsContribution } from "../model/reaction";
import { ServerSearchSessionReport, ServerSearchSessionSummary } from '../model/search';
import { MapMap } from '../util/collections';
import { loadState, saveState } from '../util/storage';
import { SharePayload } from "./actions/share";
import rootReducer from "./reducers";
import { SpreadTestConfig, SpreadTestResult } from "./reducers/admin/analysis";
import { MultiPartStartStep } from "./reducers/search";

export type ReactionsAddIdeasSource = 'least-reactions' | 'suggestions' | 'search';

export type ContribReactionsState = {
    availableQuestions: Question[],
    ideas: Idea[],
    ideasParentQuestion: number,
    hideWithReactions: boolean,
    question?: Question,
    ideasSource: ReactionsAddIdeasSource,
}

export type ContribAddQuestionsState = {
    question: ContribQuestion,
}

export type ReactionPerQuestionId = Map<number, Reaction>;
export type ReactionPerIdea = Map<HashedIdeaKey, Reaction>;
export type ReactionPerIdeaPerQuestionId = MapMap<number, HashedIdeaKey, Reaction>;

export type DiffDisplayMode = 'OnlyFirstQuestion' | 'OnlyFirstQuestionExcludingNone' | 'Union' | 'Intersection' | 'UnionExcludingNone' | 'OnlyDifferences';
export type DiffDisplayModeMap = {
    OnlyFirstQuestion: string,
    OnlyFirstQuestionExcludingNone: string,
    Union: string,
    UnionExcludingNone: string,
    Intersection: string,
    OnlyDifferences: string,
    [key: string]: string,
}

export type ContribGenericIdeasState = {
    idea: ContribGenericIdea
}

export type ContribMerchantIdeasState = {
    url: string
}

export type ContribIdeaEnrichState = {
    nextIdeas: FullContribIdea[],
}

export type ContribIdeasCohortSortMode = 'name' | 'reactions';

export type ContribIdeasCohortState = {
    ideas: Idea[],
}

export type ContribState = {
    questions: {
        add: ContribAddQuestionsState,
    },
    ideas: {
        add: {
            generic: ContribGenericIdeasState,
            merchant: ContribMerchantIdeasState,
        },
        enrich: ContribIdeaEnrichState,
        cohort: ContribIdeasCohortState,
    },
    reactions: ContribReactionsState,
}

export type SearchState = {
    gender: UserSex,
    age: number,
    priceMinCents: number,
    priceMaxCents: number,
    startStep: MultiPartStartStep,
    hasChosenGender: boolean,
    hints: string,
}

export type ModerationState = {
    genericIdeas: ModerationContribGenericIdea[],
    merchantIdeas: ModerationContribMerchantIdea[],
    questions: ModerationContribQuestion[],
    reactionsSummary: ServerUserReactionsContribution[],
    userReactionsId: number,
    userReactions: ServerUserReaction[],
}

export type SearchSessionsAnalysisState = {
    reports: Map<string, ServerSearchSessionReport>,
    summaries: ServerSearchSessionSummary[],
}

export type AnalysisState = {
    twinGroups: ServerTwinsGroup[],
    twinsFetchConfig: ServerTwinsFetchConfig,
    spreadTestConfig: SpreadTestConfig,
    spreadTestResult: SpreadTestResult,
    searchSessions: SearchSessionsAnalysisState,
}

export type AdminState = {
    analysis: AnalysisState,
}

export type ShareState = {
    payload: SharePayload
}

export type StoreState = {
    contrib: ContribState,
    search: SearchState,
    moderation: ModerationState,
    admin: AdminState,
    share: ShareState,
}
const STATE_VERSION = '0.30';

const persistedState = loadState(STATE_VERSION);
const store = createStore(rootReducer, persistedState);

const terminationEvent = 'onpagehide' in window.self ? 'pagehide' : 'unload';

// Save store state on page termination
// https://developers.google.com/web/updates/2018/07/page-lifecycle-api?utm_source=lighthouse&utm_medium=devtools#the-unload-event
window.addEventListener(terminationEvent, (_event: any) => {
    saveState(store.getState(), STATE_VERSION);
}, {capture: true});

export default store;