import * as React from 'react';
import { Link } from 'react-router-dom';

import routes from '../routes';

import './Home.scss';
import If from './base/If';
import { AccountType } from '../model/user';
import { useSimpleTranslation } from './hooks/Translator';
import { useAccount } from './hooks/Account';

const HomePageLinks: React.FC<{}> = () => {
    const t = useSimpleTranslation();
    const { accountInfo } = useAccount();
    // We use a zero-width space between links to let the browser split the line here instead of splitting inside the french version of about that has 2 words (it is hard to put a nbsp in translation data).
    return <>
            <div id="footer-links">
                <a href="https://blog.nebuly.fr">{t.blog.linkToLabel}</a>
                &#x200B;
                <Link to={routes.about.home}>{t.about.header}</Link>
                &#x200B;
                <Link to={routes.contrib.home}>{t.contrib.label}</Link>
                &#x200B;
                <If test={accountInfo.type === AccountType.Admin || accountInfo.type === AccountType.Moderator}>
                    <Link to={routes.moderation.home}>{t.moderation.home.linkToLabel}</Link>
                </If>
                &#x200B;
                <If test={accountInfo.type === AccountType.Admin}>
                    <Link to={routes.admin.home}>{t.admin.header}</Link>
                </If>
            </div>
    </>;
}

export default HomePageLinks;