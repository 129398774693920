import * as React from 'react';

import querier, { QuerierListener, QuerierState, QueryErrorResponse } from './querier';
import hourglass from '../images/hourglass.svg';
import './QuerierReporter.scss';
import { useSimpleTranslation } from '../components/hooks/Translator';
import { LocaleContent } from '../i18n/locales/types';
import { getUserText } from '../components/hooks/Query';
import If from '../components/base/If';

function getErrorUserMessage(e: QueryErrorResponse, t: LocaleContent) {
    switch (e.type) {
        case 'network-error': return t.common.errors.networkError;
        case 'http-error': return getUserText(e, t);
    }
}

function renderError(e: QueryErrorResponse, t: LocaleContent) {
    const userMessage = getErrorUserMessage(e, t);
    const addDetail = process.env.NODE_ENV !== 'production';
    if (addDetail) {
        let detail = '';
        switch (e.type) {
            case 'network-error': detail = e.error; break;
            case 'http-error': {
                const end = e.message === userMessage ? '' : ` ${e.message}`;
                detail = ` [STATUS] ${e.status} [FOR URL] ${e.url}${end}`;
                break;
            }
        }
        return `${userMessage}: ${detail}`;
    } else {
        return userMessage
    }
}

const QuerierReporter: React.FC<{}> = () => {
    const t = useSimpleTranslation();
    const [querierState, setQuerierState] = React.useState<QuerierState>({pendingQueries:0, errors: []});
    const [acknowledgedFailedQueries, setAcknowledgedFailedQueries] = React.useState<Set<QueryErrorResponse>>(new Set());
    React.useEffect(() => {
        const listener: QuerierListener = setQuerierState;
        querier.addListener(listener);
        return () => {
            querier.removeListener(listener);
        };
    }, [setQuerierState])


    // Event Handling

    function onAcknowledgeFailure(e: QueryErrorResponse) {
        setAcknowledgedFailedQueries(prev => {
            let newAcknowledgedFailedQueries = new Set(prev);
            newAcknowledgedFailedQueries.add(e);
            return newAcknowledgedFailedQueries;
        });
    }

    const failuresToRender: QueryErrorResponse[] = querierState.errors.filter(e => !acknowledgedFailedQueries.has(e));
    const className = failuresToRender.length === 0 ? 'querier-reporter-normal' : 'querier-reporter-error';
    return <div id="querier-reporter" className={className}>
        <If test={querierState.pendingQueries > 0}>
            <span className="querier-reporter-waits"><img src={hourglass} alt="Waiting" title="Waiting"/> {querierState.pendingQueries}</span>
        </If>
        <div className="column">
            {failuresToRender.map((e, i) => <span key={i} className="clickable" onClick={() => onAcknowledgeFailure(e)}>{renderError(e, t)}</span>)}
        </div>
    </div>;
}

export default QuerierReporter;