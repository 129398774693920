import { IdeaKey, Idea } from './idea';
import { ServerContributor } from './user';
import { UserSex } from './gender';
import { ServerQuestionModelKey } from './learning';
import { usize } from './rust';

export const defaultQuestionShortcut = '--default--';

// A question being created by the user, has no id.
export type ContribQuestion = {
    content: string,
}

export type AskableQuestion = ContribQuestion & {
    id: number,
    allow_doubt: boolean,
}

export type Question = ContribQuestion & {
    id: number,
    is_tag: boolean,
}

export type QuestionAndShortcut = {
    contentAfter: string,
    shortcut: string,
}

export type SentenceForm = 'interrogative' | 'affirmative' | 'negative';
export type SentenceSubject = UserSex | 'Others';
export const allSentenceSubjects: SentenceSubject[] = ['Male', 'Female', 'Couple', 'Others', 'Unknown'];
export const allSentenceForms: SentenceForm[] = ['affirmative', 'interrogative', 'negative'];

export type SentenceFormMap = { [SF in SentenceForm]: string };

export type SentenceSubjectMap = {[SS in SentenceSubject] : string };

export type ShortCutMap = {
    start: string,
    subject: string,
    likes: string,
    adores: string,
    believes: string,
    doesOften: string,
    has: string,
    is: string,
    isOften: string,
    hasRecently: string,
    hasOften: string,
    lacksOf: string,
    lives: string,
    prefers: string,
    takesCareOf: string,
    spendsTime: string,
    [key: string]: string,
}

export type ServerContribQuestionWithIdeas = {
    content: string,
    corresponding_ideas: IdeaKey[],
}

export type ServerModerationQuestionWithIdeas = {
    content: string,
    corresponding_ideas: IdeaKey[]
}

export type ServerFoundQuestion = Question & {
    // The id in this type can be for public and contrib questions, so it is not unique.
    readonly contrib: boolean, // Indicates if this question comes from the contrib or the public table.
}


// UserProvidedQuestionChange on server side
export type ServerQuestionChange = {
    readonly id: number,
    readonly key: keyof ServerFullQuestion,
    readonly value: string,
}

export type QuestionPresenceImpact = {
    readonly asked: usize,
    readonly answered_yes: usize,
    readonly answered_no: usize,
    readonly answered_idontknow: usize,
}

export type QuestionConversionImpact = {
    readonly asked: usize,
    readonly clicks: QuestionPresenceImpact,
    readonly conversions: QuestionPresenceImpact,
}

export type ServerFullQuestion = {
    readonly id: number,
    readonly content: string,
    readonly keywords: string,
    readonly reactions: number[],
    // Can be undefined because it is expensive to retrieve.
    readonly answers: null | number[],
    readonly objectivity: number,
    readonly category: number,
    readonly models: ServerQuestionModelKey[],
    readonly filling_instructions: string,
    readonly filling_pos: number,
    readonly allow_doubt: boolean,
    readonly impacted_ideas: number,
    readonly conversions_impact: null | QuestionConversionImpact,
}

export type ModerationContribQuestion = {
    readonly id: number,
    readonly contributor: ServerContributor,
    readonly content: string,
    readonly corresponding_ideas: Idea[],
}

export type QuestionCategory = 'Unknown' | 'AskOnlyIfNeeded' | 'GoodToAsk' | 'AverageQuality' | 'TagSecondary' | 'AskStart' | 'TagMain';
export type QuestionCategoryMap = { [Q in QuestionCategory]: string } & {[key: string]: string};

const serverQuestionCategoryMapping: QuestionCategory[] = ['Unknown', 'AskOnlyIfNeeded', 'GoodToAsk', 'AverageQuality', 'TagSecondary', 'AskStart', 'TagMain'];
// We order the categories by quality
export const allQuestionCategories: QuestionCategory[] = ['Unknown', 'TagMain', 'TagSecondary', 'AskOnlyIfNeeded', 'AverageQuality', 'GoodToAsk', 'AskStart'];

export function mapNumberToQuestionCategory(v: number): QuestionCategory {
    return serverQuestionCategoryMapping[v];
}

export function mapQuestionCategoryToNumber(v: QuestionCategory): number {
    return serverQuestionCategoryMapping.indexOf(v);
}

const tagMainNumber = mapQuestionCategoryToNumber('TagMain');
const tagSecondaryNumber = mapQuestionCategoryToNumber('TagSecondary');

export function isCategoryNumberTag(n: number) {
    return n === tagMainNumber || n === tagSecondaryNumber;
}

export function serverFullToUserFacing(q: ServerFullQuestion): Question {
    return {
        content: q.content,
        id: q.id,
        is_tag: isCategoryNumberTag(q.category),
    }
}