import React from 'react';
import logo from '../images/logo.svg';

import './FullLogo.scss';
import { useSimpleTranslation } from './hooks/Translator';
import { coalesce } from '../util/common';

type Props = {
    height?: number,
    includeBeta?: boolean,
}

const FullLogo: React.FC<Props> = ({height: inputHeight, includeBeta}) => {
    const t = useSimpleTranslation();
    const height = coalesce(inputHeight, 100);
    const fontSize = height * 0.7;
    const alt = t.home.name;
    const betaFontSize = 4 + height / 16;
    const beta = includeBeta === true ? <div className="logo-beta-tag" style={{marginTop: height / 6, height: betaFontSize, fontSize: betaFontSize}}>beta</div> : null;
    return <div className="full-logo" style={{height, fontSize}}>
        <img src={logo} alt={alt} title={alt} style={{height}}/>
        {beta}
    </div>
};

export default React.memo(FullLogo);