import * as React from 'react';

import './GenderPicker.scss';
import { UserSex } from '../model/gender';
import Button from './base/Button';
import { useSimpleTranslation } from './hooks/Translator';

type Props = {
    gender: UserSex,
    compact: boolean,
    highlightValue: boolean,
    setGender: (gender: UserSex) => void,
}

const GenderPicker: React.FC<Props> = ({ gender, setGender, highlightValue, compact }) => {
    const t = useSimpleTranslation();
    const lines: UserSex[][] = compact ? [['Female', 'Male'], ['Couple', 'Unknown']] : [['Female', 'Male', 'Couple', 'Unknown']];
    const small = !compact;

    return <div className="gender-picker-root">
        {lines.map((l, i) => <div key={i} className="gender-picker-line">
            {l.map(g => {
                const selected = gender === g && highlightValue;
                const additionalClassName = selected ? 'gender-picker-selected-entry' : '';
                const className = `gender-picker-entry ${additionalClassName}`;
                return (
                    <Button key={g}
                        className={className}
                        onClick={() => setGender(g)}
                        filled={false}
                        small={small}
                        type={selected ? "negative" : "primary"}
                        label={t.genderPicker[g]} />
                );
            })}
        </div>
        )}
    </div>;
}

export default GenderPicker;