import * as React from 'react';

import { Idea, toHash } from '../../model/idea';
import { SearchFlow } from '../../server/SearchFlow';
import { CardContent, isQuestion } from './Card';
import querier from '../../server/querier';
import './IdeasHighlighter.scss';
import { IdeasHighlighterLayout, SearchLayout } from './Layout';
import { useSimpleTranslation } from '../hooks/Translator';
import { onClickToIdea } from './FoundIdeaBelowDeck';
import { uppercaseFirstLetter } from '../../util/strings';
import { coalesce } from '../../util/common';

type IdeaProps = {
    idea: Idea,
    r: number,
    flow: SearchFlow,
}

type Props = {
    layout: IdeasHighlighterLayout,
    flow: SearchFlow,
    ideas: Idea[],
}

type HeaderProps = {
    layout: IdeasHighlighterLayout,
}

type GuardProps = {
    centerContent: CardContent,
    flow?: SearchFlow,
    layout: SearchLayout,
    ideas: Idea[],
}

function getStyle(layout: IdeasHighlighterLayout): React.CSSProperties {
    const {display, height} = layout;
    switch (display) {
        case 'none':
            return {
                display: 'none',
            };
        case 'big':
            return {
                height,
                width: '100vw',
                position: 'relative',
            };
        case 'line':
            return {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height,
            };
    }
}

const curveLimits: number[] = [0, 5, 9, 13, 16];
const reversedCurveLimits:number[] = [...curveLimits];
reversedCurveLimits.reverse();

const curveRadiuses = [150, 120, 100, 80,60];

function getRadiusForCurve(curve: number) {
    return curveRadiuses[curve];
}

function getIdeaStyle(layout: IdeasHighlighterLayout, index: number): React.CSSProperties {
    switch (layout.display) {
        case 'none':
            return {
            };
        case 'big':
            const sign = (index % 2) * 2 - 1;
            const dist = Math.floor((index + 1) / 2);
            const reversedCurveIndex = reversedCurveLimits.findIndex(c => c <= dist);
            const curve = reversedCurveLimits.length - reversedCurveIndex - 1;
            const curvePos = dist - curveLimits[curve];
            const deckPadding = curve === 0 ? 0 : layout.width / 6 + curve * 20;
            const rCurve = getRadiusForCurve(curve);
            const r = rCurve - curvePos * 10;
            const cx = layout.width / 2 - r / 2;
            const cy = layout.height / 2 - r / 2;
            const left = cx + (deckPadding + curvePos * (rCurve + 10)) * sign;
            // For now we disable the curvature of the curves, we have straight curves :D
            const top = cy - /*curvePos * curvePos * (curve * 5 + 1) * 2*/ - curve * 200;
            return {
                position: 'absolute',
                left,
                top,
                width: r,
                height: r,
            };
        case 'line': {
            const r = layout.height - 20;
            return {
                marginLeft: 5,
                marginRight: 5,
                width: r,
                height: r,
            };
        }
    }
}
const limit = 17;
function cleanTitle(title: string) {
    const words = title.split(' ');
    let small = coalesce(words.shift(), '');
    for (let word of words) {
        if (small.length + 1 + word.length < limit) {
            small = small + ' ' + word;
        } else {
            break;
        }
    }
    return uppercaseFirstLetter(small.toLowerCase());
}

const HighlightedIdea: React.FC<IdeaProps> = ({idea, r, flow}) => {
    return <div className="highlighted-idea clickable fade-in" style={{width: r}} onClick={() => onClickToIdea(flow, idea, false)}>
        <img src={`${querier.httpUrl}/img/${idea.image}`} alt={idea.title} style={{height:r, width:r}} />
        <div className="highlighted-idea-title" title={idea.title}>{cleanTitle(idea.title)}</div>
    </div>
};

const IdeasHighlighterHeader: React.FC<HeaderProps> = ({layout}) => {
    const t = useSimpleTranslation();
    return <div className={`ideas-highlight-header-${layout.display === 'big' ? 'large' : 'small'}`}>
        {t.search.ideasHighlight.header}
    </div>;
};

const IdeasHighlighter: React.FC<Props> = ({layout, flow, ideas}) => {
    return <div className="ideas-highlighter" style={getStyle(layout)}>
        {ideas.map((idea, i) => {
            const style = getIdeaStyle(layout, i);
        return <div key={toHash(idea.key)} style={style}>
            <HighlightedIdea idea={idea} r={style.height as number} flow={flow}/>
        </div>;
        })}
    </div>;
};

const IdeasHighlighterGard: React.FC<GuardProps> = ({centerContent, flow, layout, ideas}) => {
    const [countKnownByServer, setCountKnownByServer] = React.useState<number>(0);
    if (!isQuestion(centerContent)) {
        return null;
    }
    if (flow === undefined) {
        return null;
    }
    if (layout.ideasHighlighter.ideasCount !== countKnownByServer) {
        flow.sendIdeasHighlightCount(layout.ideasHighlighter.ideasCount);
        setCountKnownByServer(layout.ideasHighlighter.ideasCount);
    }
    if (ideas.length === 0) {
        return <IdeasHighlighterHeader layout={layout.ideasHighlighter} />
    } else {
        return <IdeasHighlighter layout={layout.ideasHighlighter} flow={flow} ideas={ideas} />
    }
};

export default IdeasHighlighterGard;
