import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; // or any other pipeline

import Home from './components/Home';
import routes from './routes';
import Header from './components/Header';
import SearchHome from './components/search/Home';
import QuerierReporter from './server/QuerierReporter';
import About from './components/about/Home';
import Main from './components/search/square/Main';
import Background from './components/Clouds';
import ServiceWorkerManager from './components/ServiceWorkerManager';
import Legal from './components/about/Legal';
import PrivacyPolicy from './components/about/PrivacyPolicy';
import GoToIdea from './components/search/GoToIdea';
import AlertCreator from './components/search/alert/AlertCreator';
import AlertEmailValidation from './components/search/alert/EmailValidation';
import Share from './components/search/share/Home';
import TitleSetter from './components/TitleUpdater';
import SuspenseLoading from './components/SuspenseLoading';
import articles, { getRoute } from './components/blog/articles';

const ReactionsQuestionChooser = lazy(() => import('./components/contrib/reactions/QuestionChooser'));
const ContribHome = lazy(() => import('./components/contrib/Home'));
const ReactionPicker = lazy(() => import('./components/contrib/reactions/Add'));
const ContribAddQuestion = lazy(() => import('./components/contrib/questions/Add'));
const ContribEnrichQuestion = lazy(() => import('./components/contrib/questions/Enrich'));
const ContribIdeas = lazy(() => import('./components/contrib/ideas/Home'));
const ContribQuestionsHome = lazy(() => import('./components/contrib/questions/Home'));
const ContribGenericIdeas = lazy(() => import('./components/contrib/ideas/GenericIdeas'));
const ContribMerchantIdeas = lazy(() => import('./components/contrib/ideas/MerchantIdeas'));
const AccountCreation = lazy(() => import('./components/account/Signup'));
const AccountValidation = lazy(() => import('./components/account/Validation'));
const AccountSignIn = lazy(() => import('./components/account/SignIn'));
const Admin = lazy(() => import('./components/admin/Home'));
const StatsOlap = lazy(() => import('./components/admin/stats/Olap'));
const StatsOverview = lazy(() => import('./components/admin/stats/Overview'));
const StatsDiscovery = lazy(() => import('./components/admin/stats/Discovery'));
const StatsQuality = lazy(() => import('./components/admin/stats/Quality'));
const StatsPerformance = lazy(() => import('./components/admin/stats/Performance'));
const StatsPerformanceEvolution = lazy(() => import('./components/admin/stats/PerformanceEvolution'));
const CrawlHome = lazy(() => import('./components/admin/crawl/Home'));
const CrawlReport = lazy(() => import('./components/admin/crawl/Report'));
const CrawlTester = lazy(() => import('./components/admin/crawl/Tester'));
const CrawlAuto = lazy(() => import('./components/admin/crawl/Auto'));
const Flows = lazy(() => import('./components/admin/crawl/Flow'));
const AnalyticsHome = lazy(() => import('./components/admin/analytics/Home'));
const SearchSessionsAnalysis = lazy(() => import('./components/admin/analytics/SearchSessionsAnalysis'));
const EnrichSingleIdeaPage = lazy(() => import('./components/contrib/ideas/IdeaPage'));
const EnrichIdeaFeedPage = lazy(() => import('./components/contrib/ideas/FeedPage'));
const SearchIdea = lazy(() => import('./components/contrib/ideas/search/SearchPage'));
const IdeasCohort = lazy(() => import('./components/contrib/ideas/Cohort'));
const Suggestions = lazy(() => import('./components/contrib/Suggestions'));
const ModerationHome = lazy(() => import('./components/moderation/Home'));
const ModerationUserReactions = lazy(() => import('./components/moderation/UserReactions'));
const TestUi = lazy(() => import('./components/admin/TestUi'));
const TestSize = lazy(() => import('./components/admin/TestSize'));
const SearchSessionReportPage = lazy(() => import('./components/admin/analytics/SearchSessionReportPage'));
const LearningHome = lazy(() => import('./components/admin/learning/Home'));
const Podium = lazy(() => import('./components/admin/analytics/Podium'));
const Merchants = lazy(() => import('./components/admin/merchants/Home'));
const Bot = lazy(() => import('./components/about/Bot'));
const FeedbackAnalysis = lazy(() => import('./components/admin/analytics/FeedbackAnalysis'));
const PerformanceHome = lazy(() => import('./components/admin/performance/Home'));
const AutomatedAnswersHome = lazy(() => import('./components/admin/automated-answer/Home'));
const Email = lazy(() => import('./components/email/Home'));
const TagObvious = lazy(() => import ('./components/admin/TagObvious'));
const RedundantContributions = lazy (() => import('./components/admin/analytics/RedundantContributions'));
const TagsSuggestions = lazy (() => import('./components/admin/analytics/TagsSuggestions'));
const TwinsHome = lazy(() => import('./components/admin/analytics/twins/Home'));
const IdeasRelationEditorPage = lazy(() => import('./components/moderation/IdeasRelationEditor'));
const Inspirations = lazy(() => import('./components/contrib/ideas/Inspirations'));
const BlogHome = lazy(() => import('./components/blog/Home'));

class App extends React.Component {

  public render() {
    return (
      <DndProvider options={HTML5toTouch}>
        <Router>
          <div id="router-root">
            <TitleSetter />
            <Route path="" render={routeProps => {
              const path = routeProps.location.pathname;
              return <Header path={path} />
            }}/>
            <div id="main">
              <Background />
              <ServiceWorkerManager />
              <QuerierReporter />
              <Suspense fallback={<SuspenseLoading />}>
                <Route exact={true} path={routes.home} component={Home} />
                <Route exact={true} path={routes.landingPages.christmasFr} component={Home} />
                <Route exact={true} path={routes.about.home} component={About} />
                <Route exact={true} path={routes.about.bot} component={Bot} />
                <Route exact={true} path={routes.about.legal} component={Legal} />
                <Route exact={true} path={routes.about.privacyPolicy} component={PrivacyPolicy} />
                <Route exact={true} path={routes.account.signup} component={AccountCreation} />
                <Route exact={true} path={routes.account.signin} component={AccountSignIn} />
                <Route exact={true} path={routes.account.validation.full} component={AccountValidation} />
                <Route exact={true} path={routes.blog.home} component={BlogHome} />
                {articles.map(article => <Route key={article.title} exact={true} path={getRoute(article)} component={article.component} />)}
                <Route exact={true} path={routes.contrib.home} component={ContribHome} />
                <Route exact={true} path={routes.contrib.suggestions} component={Suggestions} />
                <Route exact={true} path={routes.contrib.questions.home} component={ContribQuestionsHome} />
                <Route exact={true} path={routes.contrib.questions.add} component={ContribAddQuestion} />
                <Route exact={true} path={routes.contrib.questions.enrich.full} component={ContribEnrichQuestion} />
                <Route exact={true} path={routes.contrib.reactions.add.home} component={ReactionsQuestionChooser} />
                <Route exact={true} path={routes.contrib.reactions.add.form.full} component={ReactionPicker} />
                <Route exact={true} path={routes.contrib.ideas.add.home} component={ContribIdeas} />
                <Route exact={true} path={routes.contrib.ideas.add.generic} component={ContribGenericIdeas} />
                <Route exact={true} path={routes.contrib.ideas.add.merchant} component={ContribMerchantIdeas} />
                <Route exact={true} path={routes.contrib.ideas.enrich.full} component={EnrichSingleIdeaPage} />
                <Route exact={true} path={routes.contrib.ideas.enrich.feed.full} component={EnrichIdeaFeedPage} />
                <Route exact={true} path={routes.contrib.ideas.search.noCriteria} component={SearchIdea} />
                <Route exact={true} path={routes.contrib.ideas.cohort} component={IdeasCohort} />
                <Route exact={true} path={routes.contrib.ideas.inspirations} component={Inspirations} />
                <Route exact={true} path={routes.search} component={SearchHome} />
                <Route exact={true} path={routes.share.home} component={Share} />
                <Route exact={true} path={routes.square.full} component={Main} />
                <Route exact={true} path={routes.alert.create.full} component={AlertCreator} />
                <Route exact={true} path={routes.alert.validateEmail.full} component={AlertEmailValidation} />
                <Route exact={true} path={routes.gotoIdea.full} component={GoToIdea} />
                <Route exact={true} path={routes.admin.home} component={Admin} />
                <Route exact={true} path={routes.admin.automatedAnswers.home} component={AutomatedAnswersHome} />
                <Route exact={true} path={routes.admin.crawl.home} component={CrawlHome} />
                <Route exact={true} path={routes.admin.crawl.reports} component={CrawlReport} />
                <Route exact={true} path={routes.admin.crawl.tester} component={CrawlTester} />
                <Route exact={true} path={routes.admin.crawl.auto} component={CrawlAuto} />
                <Route exact={true} path={routes.admin.crawl.flows} component={Flows} />
                <Route exact={true} path={routes.admin.analytics} component={AnalyticsHome} />
                <Route exact={true} path={routes.admin.merchants.home} component={Merchants} />
                <Route exact={true} path={routes.admin.performance.home} component={PerformanceHome} />
                <Route exact={true} path={routes.admin.podium} component={Podium} />
                <Route exact={true} path={routes.admin.testUi} component={TestUi} />
                <Route exact={true} path={routes.admin.testSize} component={TestSize} />
                <Route exact={true} path={routes.admin.tag.obvious} component={TagObvious} />
                <Route exact={true} path={routes.admin.tag.redundantAnalysis} component={RedundantContributions} />
                <Route exact={true} path={routes.admin.tag.suggestions} component={TagsSuggestions} />
                <Route exact={true} path={routes.admin.tag.learning.home} component={LearningHome} />
                <Route exact={true} path={routes.admin.twins.home} component={TwinsHome} />
                <Route exact={true} path={routes.admin.searchSessions.sessionsAnalysis} component={SearchSessionsAnalysis} />
                <Route exact={true} path={routes.admin.searchSessions.feedbackAnalysis} component={FeedbackAnalysis} />
                <Route exact={true} path={routes.admin.searchSessions.detail.full} component={SearchSessionReportPage} />
                <Route exact={true} path={routes.admin.stats.olap} component={StatsOlap} />
                <Route exact={true} path={routes.admin.stats.overview} component={StatsOverview} />
                <Route exact={true} path={routes.admin.stats.performance} component={StatsPerformance} />
                <Route exact={true} path={routes.admin.stats.performanceEvolution} component={StatsPerformanceEvolution} />
                <Route exact={true} path={routes.admin.stats.discovery} component={StatsDiscovery} />
                <Route exact={true} path={routes.admin.stats.quality} component={StatsQuality} />
                <Route exact={true} path={routes.moderation.home} component={ModerationHome} />
                <Route exact={true} path={routes.moderation.userReactions.full} component={ModerationUserReactions} />
                <Route exact={true} path={routes.moderation.ideasRelations.full} component={IdeasRelationEditorPage} />
                <Route exact={true} path={routes.email} component={Email} />
            </Suspense>
            </div>
            <div id="popup"/>
          </div>
        </Router>
      </DndProvider>
    );
  }
}

export default App;
