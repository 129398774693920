import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './redux/store';
import translator from './i18n/Translator';

import 'rc-slider/assets/index.css';
import './rc-overrides.scss';
import './index.scss';

translator.setLocale('fr');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);
