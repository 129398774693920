import * as React from 'react';

type Props = {
    test: boolean,
}

class If extends React.Component<Props> {
    public render() {
        if (this.props.test) {
            return this.props.children;
        } else {
            return null;
        }
    }
}

export default If;