import { ContribQuestion } from '../../../../model/question';

export const SET_QUESTION = 'CONTRIB_QUESTIONS_ADD_SET_QUESTION';
export type CONTRIB_QUESTIONS_ADD_SET_QUESTION = typeof SET_QUESTION;

export const RESET_USER_CHOICES = 'CONTRIB_QUESTIONS_ADD_RESET_USER_CHOICES';
export type CONTRIB_QUESTIONS_ADD_RESET_USER_CHOICES = typeof RESET_USER_CHOICES;

export type ContribQuestionsAddSetQuestion = {
    type: CONTRIB_QUESTIONS_ADD_SET_QUESTION,
    question: ContribQuestion
}

export type ContribQuestionsAddResetUserChoices = {
    type: CONTRIB_QUESTIONS_ADD_RESET_USER_CHOICES
}


export type ContribQuestionsAddAction =
ContribQuestionsAddSetQuestion |
ContribQuestionsAddResetUserChoices