import {Question} from './question';
import { Idea } from './idea';

export const TWINS_SORT_MODE_CHAIN_IDEAS = 0;
export const TWINS_SORT_MODE_IDEAS_CHAIN = 1;
export const TWINS_SORT_MODE_NONE = 2;


export type ServerDanglingReactionPrimary = {
    readonly question: Question,
    readonly reaction_primary: string,
}

export type ServerTwinsGroup = {
    readonly ideas: Idea[],
    readonly chain: ServerDanglingReactionPrimary[],
}

export type ServerTwinsFetchConfig = {
    limit: number,
    min_chain_len: number,
    sort_mode: number,
    min_ideas_count: number,
    max_ideas_count: number,
    allow_single_merchant: boolean,
}