import React, { useState } from "react";
import { save, load } from "../../util/storage";

const production = process.env.NODE_ENV === 'production';

/**
 * Like the useState hook but will persist the value in the session storage to be maintained by hot-reloads (only in dev).
 * @param initialValue The state initial value.
 * @param key The name of the key where to store the value in the session storage.
 */
function useStateHotReloadable<T>(initialValue: T, key: string): [T, (v: T) => void, (v: T) => void] {
    return useStatePersisted(initialValue, production ? undefined : key);
}

function isFunction(functionToCheck: any) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}



/**
 * Like the useState hook but will persist the value in the session storage if the key is defined.
 * @param initialValue The state initial value.
 * @param key The name of the key where to store the value in the session storage. Can be left undefined to fallback to useState.
 */
function useStatePersisted<T>(initialValue: T, key: string | undefined): [T, React.Dispatch<React.SetStateAction<T>>, (v: T) => void] {
    const [raw, setRaw] = useState<T>(initialValue);
    let [wasChanged, setWasChanged] = useState<boolean>(false);
    if (key === undefined) {
        return [raw, setRaw, setRaw];
    }
    function setV(a: React.SetStateAction<T>, persist: boolean) {
        const v = isFunction(a) ? (a as Function)(raw) : a;
        setRaw(v);
        setWasChanged(true);
        if (persist && key !== undefined) {
            save(v, key);
        }
    }
    let v = raw;
    // We cannot compare raw to initialValue, for non primitive types they are not
    // === even on first call.
    if (!wasChanged) {
        const fromStorage = load<T>(key);
        if (fromStorage !== undefined) {
            v = fromStorage;
        }
    }
    return [v, a => setV(a, true), a => setV(a, false)];
}

export {
    useStateHotReloadable,
    useStatePersisted,
}