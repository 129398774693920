import { Action } from '../../actions';
import * as actions from '../../actions/admin/analysis'
import { AnalysisState } from '../../store';
import { TWINS_SORT_MODE_CHAIN_IDEAS } from '../../../model/analysis';
import { UserSex } from '../../../model/gender';
import { Question } from '../../../model/question';
import { Idea } from '../../../model/idea';

// Same on server
export type SpreadTestConfig = {
    iterations: number,
    price_min_cents: number,
    price_max_cents: number,
    age: number,
    sex: UserSex,
    proba_answer_yes: number,
    proba_answer_no: number,
}

// Same on server
export type SpreadTestResult = {
    questions: [Question, number][],
    first_ideas: [Idea, number][],
}

const defaultState: AnalysisState = {
    twinGroups: [],
    twinsFetchConfig: {
        limit: 50,
        min_chain_len: 3,
        min_ideas_count: 2,
        max_ideas_count: 999,
        allow_single_merchant: true,
        sort_mode: TWINS_SORT_MODE_CHAIN_IDEAS,
    },
    searchSessions: {
        summaries: [],
        reports: new Map(),
    },
    spreadTestConfig: {
        iterations: 1000,
        price_min_cents: 2000,
        price_max_cents: 10000,
        age: 30,
        proba_answer_no: 0.2,
        proba_answer_yes: 0.5,
        sex: 'Unknown',
    },
    spreadTestResult: {
        questions: [],
        first_ideas: [],
    },
};

const reducer = (state: AnalysisState = defaultState, action: Action = {type: ''}): AnalysisState => {
    switch (action.type) {
        case actions.SET_TWINS:
            return {...state, twinGroups: action.twins};
        case actions.SET_TWINS_FETCH_CONFIG:
            return {...state, twinsFetchConfig: action.config};
        case actions.SET_SEARCH_SESSIONS_SUMMARIES:
            return {...state, searchSessions: {...state.searchSessions, summaries: action.summaries}};
        case actions.SET_SEARCH_SESSIONS_DETAILS:
            return {...state, searchSessions: {...state.searchSessions, reports: action.detail}};
        case actions.SET_SPREAD_TEST_CONFIG:
            return {...state, spreadTestConfig: action.config};
        case actions.SET_SPREAD_TEST_RESULT:
            return {...state, spreadTestResult: action.result};
        default:
            return state;
    }
};
export default reducer;