import React, {useEffect, useState} from 'react';
import { PropsFromResponsive, PageSize, getPageSize, getDpi, getDevicePixelRatio } from '../ResponsiveHOC';

const initialPageSize = getPageSize();

function useResponsive(): PropsFromResponsive {
    const [pageSize, setPageSize] = useState<PageSize>(initialPageSize);
    const dpi = React.useMemo(getDpi, []);
    useEffect(() => {
        const pageSizeOnEffectStart = getPageSize();
        if (pageSizeOnEffectStart.x !== pageSize.x || pageSizeOnEffectStart.y !== pageSize.y) {
            setPageSize(pageSizeOnEffectStart);
        }
        function resizeListener() {
            setPageSize(getPageSize());
        }
        window.addEventListener('resize', resizeListener);
        return function cleanup() {
            window.removeEventListener('resize', resizeListener);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/* No props are used for responsive listeners. Do not cleanup on each render. We do not depend on pageSize we just change it if it is wrong on effect start. */]);

    return {
        pageSize,
        devicePixelRatio: getDevicePixelRatio(),
        dpi,
    }
}

export default useResponsive;