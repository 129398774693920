import { FullContribIdea, IdeaSearchCriteria } from "./idea";
import { i16, i64 } from "./rust";

export type Feedback = 'ClickedOnIdea' | 'GoodIdea' | 'BadIdea' | 'YouCanDoBetter' | 'YouAreClose' | 'InconsistentIdea' | 'Reset' | 'SawIdea' | 'HasAlready' | 'IDontKnow';
const serverMapping : Feedback[] = ['ClickedOnIdea', 'GoodIdea', 'BadIdea', 'YouCanDoBetter', 'YouAreClose', 'InconsistentIdea', 'Reset', 'SawIdea', 'HasAlready', 'IDontKnow'];
export const allFeedback = serverMapping;

export function mapNumberToFeedback(v: number): Feedback {
    return serverMapping[v];
}

export function mapFeedbackToNumber(v: Feedback): number {
    return serverMapping.indexOf(v);
}

export type FeedbackMap = {[F in Feedback] : string};

// The exclusive feedback are feedback that are exclusive of each other.
// The feedback 'saw' and 'clicked' can be sent in addition to them.
export const exclusiveFeedbacks: Feedback[] = ['GoodIdea', 'HasAlready', 'YouAreClose', 'YouCanDoBetter', 'BadIdea', 'InconsistentIdea', 'Reset'];
export const feedbackSaw = mapFeedbackToNumber('SawIdea');
export const feedbackClicked = mapFeedbackToNumber('ClickedOnIdea');

export const feedbackColors: FeedbackMap = {
    BadIdea: '#ffaa00',
    ClickedOnIdea: '#00e33f',
    GoodIdea: '#00a9f2',
    InconsistentIdea: '#ce0407',
    Reset: '#6d400c',
    SawIdea: '#30292a',
    YouAreClose: '#14f200',
    YouCanDoBetter: '#f2de00',
    HasAlready: '#00f2bd',
    IDontKnow: '#cccccc',
};

export type GetFeedbackStatsPayload = {
    readonly criteria: IdeaSearchCriteria[],
    readonly days: i64,
    readonly limit: i64 | null,
}

export type ServerEnrichedFeedbackStats = {
    readonly idea: FullContribIdea,
    readonly sessions: i64,
    readonly counts_per_feedback: i64[],
    readonly conversions: i64,
    readonly amount_cents: i16,
}

export function sumExclusiveFeedbacks(counts: number[]) {
    return exclusiveFeedbacks.reduce((sum, f) => sum + counts[mapFeedbackToNumber(f)], 0);
}

