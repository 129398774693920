

export type Point = [number, number];
const toD = (p: Point): string => p.join(',');
const add = (p1: Point, p2: Point): Point => [p1[0] + p2[0], p1[1] + p2[1]];
const multiply = (p: Point, n: number): Point => [p[0] * n, p[1] * n];
const toDs = function(points: Point[]): string {
	const coords = points.map(toD).join(" ");
  return `M ${coords}`;
};
const between = (p1: Point, p2: Point, t: number): Point => [p1[0] * (1 - t) + p2[0] * t, p1[1] * (1 - t) + p2[1] * t];
const polar = (angle: number, radius: number): Point => [Math.cos(angle) * radius, Math.sin(angle) * radius];

export const getPolyGonD = (center: Point, radius: (i: number) => number, sides: number, offset: number): string => {
    const points = [];
    for (let i = 0; i < sides; i++) {
        let angle = Math.PI * 2 / sides * i + offset;
        points.push(add(center, polar(angle, radius(i))));
    }
    return `${toDs(points)} z`;
};

export const getSimplePolyGonD = (center: Point, radius: number, sides: number, offset: number): string => {
    return getPolyGonD(center, i => radius, sides, offset);
};
const addPoints = add;
const multiplyPoints = multiply;
const pointsToD = toDs;
const pointsToDz = (p: Point[]) => `${pointsToD(p)}z`;

export {
    addPoints,
    multiplyPoints,
    pointsToD,
    pointsToDz,
    polar,
    between,
};