

import * as React from 'react';
import { isFailedQuery, isNotStartedQuery, isPendingQuery, isReadyQuery, QueryHookResult } from '../hooks/Query';
import { useSimpleTranslation } from '../hooks/Translator';
import If from './If';
import Refresh from './Refresh';

type Props = {
    r: QueryHookResult<any>,
    hideRefreshButton?: boolean,
}

/**
 * A component to display query hook results that are either not started, pending or failed.
 *
 * @param r The query result.
 */
const NotReadyQuery: React.FC<Props> = ({r, hideRefreshButton}) => {
    const t = useSimpleTranslation();
    const showRefreshButton = hideRefreshButton !== true;
    if (isNotStartedQuery(r)) {
        return <div><If test={showRefreshButton}><Refresh onClick={r.refresh}/></If></div>
    }
    if (isPendingQuery(r)) {
        return <div>{t.common.loading}</div>
    }
    if (isFailedQuery(r)) {
        return <div>{r.userText}<If test={showRefreshButton}><Refresh onClick={r.refresh}/></If></div>;
    }
    return null;
};

export function readyGuard<T>(r: QueryHookResult<T>, renderWhenLoaded:(r: T) => JSX.Element): JSX.Element {
    if (!isReadyQuery(r)) {
        return <NotReadyQuery r={r}/>
    } else {
        return renderWhenLoaded(r);
    }
}

export default NotReadyQuery;