import * as React from 'react';

import './Button.scss';
import { Link } from 'react-router-dom';
import { AnswerValueMap } from '../../model/answer';

export type ButtonType = 'primary' | 'negative' | 'positive' | 'neutral' | 'dangerous';

type CommonProps = {
    label: string,
    type: ButtonType,
    filled: boolean,
    className?: string,
    disabled ?: boolean,
    small ?: boolean,
    title ?: string,
}

type ClickProps = CommonProps & {
    onClick: () => void,
}

type LinkProps = CommonProps & {
    to: string,
}

function getClassName(props: CommonProps) {
    const disabled = props.disabled === true;
    const classNameFromProps = props.className === undefined ? '' : props.className;
    const filled = props.filled ? '-filled' : '';
    const mainClassName = `button-${props.type}${filled}`;
    const disabledClassName = disabled ? 'button-disabled' : 'button-enabled';
    const smallClassName = props.small === true ? `button-small` : 'button-regular-size';
    return `button-all ${mainClassName} ${disabledClassName} ${classNameFromProps} ${smallClassName}`;
}

const Button: React.FC<ClickProps> = props => {
    const disabled = props.disabled === true;
    return <div
        className={getClassName(props)}
        title={props.title}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault(); 
            if (!disabled) {
                props.onClick();
            }
            e.stopPropagation();
        }}>
        {props.children}{props.label}
    </div>;
}

const LinkButton: React.FC<LinkProps> = props => {
    // Place the link around the button so that clicking in the div padding still triggers the link.
    return <Link to={props.to} className="link-button">
        <div className={getClassName(props)}>
        {props.label}
        </div>
    </Link>;
}

export const answerButtonTypes: AnswerValueMap<ButtonType> = {
    'Yes': 'positive',
    'ProbablyYes': 'positive',
    'IDontKnow': 'neutral',
    'No': 'negative',
    'ProbablyNo': 'negative',
    'Good': 'positive',
    'Bad': 'negative',
};

export default Button;
export {LinkButton};