import React from 'react';
import FullLogo from './FullLogo';

import { useSimpleTranslation } from './hooks/Translator';

const SuspenseLoading: React.FC<{}> = () => {
    const t = useSimpleTranslation();
    return <div className="centered-full" style={{height: '80vh'}}>
        <FullLogo />
        <h2>{t.common.loading}</h2>
    </div>;
};

export default SuspenseLoading;