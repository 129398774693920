import { Action } from '../actions';
import { ModerationState } from '../store';
import { SET_GENERIC_IDEAS, SET_MERCHANT_IDEAS, SET_QUESTIONS, SET_REACTIONS_SUMMARY, SET_REACTIONS_USER } from '../actions/moderation';

const defaultState: ModerationState = {
    genericIdeas: [],
    merchantIdeas: [],
    questions: [],
    reactionsSummary: [],
    userReactions: [],
    userReactionsId: -1,
};

const reducer = (state: ModerationState = defaultState, action: Action = {type: ''}): ModerationState => {
    switch (action.type) {
        case SET_GENERIC_IDEAS:
            return {...state, genericIdeas: action.ideas};
        case SET_MERCHANT_IDEAS:
            return {...state, merchantIdeas: action.ideas};
        case SET_QUESTIONS:
            return {...state, questions: action.questions};
        case SET_REACTIONS_SUMMARY:
            return {...state, reactionsSummary: action.summary};
        case SET_REACTIONS_USER:
            return {...state, userReactions: action.reactions, userReactionsId: action.userId};
        default:
            return state;
    }
};
export default reducer;