import { Action } from '../../../actions';
import {  ContribIdeaEnrichState } from '../../../store';
import { SET_NEXT_IDEAS} from '../../../actions/contrib/ideas/enrich';

const defaultState: ContribIdeaEnrichState = {
    nextIdeas: [],
};

const reducer  = (state: ContribIdeaEnrichState = defaultState, action: Action = {type: ''}): ContribIdeaEnrichState => {
    switch (action.type) {
        case SET_NEXT_IDEAS:
            return {...state, nextIdeas: action.ideas};
        default:
            return state;
    }
};

export default reducer;