import IntlMessageFormat from 'intl-messageformat';

import frFR from './locales/fr-FR';
import { LocaleContent } from './locales/types';

type AvailableLocales = {
    [key: string]: LocaleContent
}

const availableLocales: AvailableLocales = {'fr': frFR};

const defaultLocale = 'fr';

export type TranslationListener = () => void;
export type TranslatePath = (localeData: LocaleContent) => string;

class Translator {

    public locale: string;
    private listeners: TranslationListener[];
    public localeData?: LocaleContent;

    constructor(locale: string = defaultLocale) {
        // The primary language subtag of the IETF language tag representing the locale. Like 'en' or 'fr'.
        this.locale = locale;
        this.listeners = [];
        this.updateTranslationData();
    }

    public setLocale(newLocale: string) {
        if (availableLocales[newLocale] === undefined) {
        // This locale is not supported, do not switch to it.
        return;
        }
        this.locale = newLocale;
        this.updateTranslationData();
    }

    public addListener(l: TranslationListener) {
        this.listeners.push(l);
    }

    public removeListener(l: TranslationListener) {
        const index = this.listeners.indexOf(l);
        this.listeners.splice(index, 1);
    }

    private getMessage(path: TranslatePath): string | null {
        if (this.localeData === undefined) {
            return null;
        }
        const message = path(this.localeData);
        if (message === undefined) {
            return null;
        }
        return message;
    }

    public translate(path: TranslatePath): string {
        const message = this.getMessage(path);
        if (message === null) {
            return '⚠';
        }
        return message;
    }

    public translateData(path: TranslatePath, data: any):string {
        const message = this.getMessage(path);
        if (message === null) {
            return '⚠';
        }
        return this.translateMessage(message, data);
    }

    public getData<T>(f: (localeData: LocaleContent) => T): T | undefined {
        if (this.localeData === undefined) {
            return undefined;
        }
        return f(this.localeData);
    }

    public translateMessage(message: string, data: any):string {
        return new IntlMessageFormat(message, this.locale).format(data) as string;
    }

    private updateTranslationData() {
        this.localeData = availableLocales[this.locale];
        this.listeners.forEach(l => l());
    }

    public formatDate(d: Date, options?: Intl.DateTimeFormatOptions) {
        if (this.localeData === undefined) {
            return undefined;
        }
        const dateFormatter = new Intl.DateTimeFormat(this.localeData.common.dateLocaleBcp47, options);
        return dateFormatter.format(d);
    }
}

// Singleton
const translator = new Translator();
export default translator;
