import { SearchState } from "../store";
import { Action } from "../actions";
import { SET_GENDER, SET_AGE, SET_BUDGET, SET_STEP, SET_HINTS } from "../actions/search";
import { SEARCH_START_MIN_CENTS, SEARCH_START_MAX_CENTS } from "../../model/price";
import { searchDefaultAge } from "../../model/age";

export type MultiPartStartStep = 'presentation' | 'gender' | 'age' | 'budget' | 'hints';
export const stepsOrder: MultiPartStartStep[] = ['presentation', 'gender', 'age', 'budget', 'hints'];

const defaultState: SearchState = {
    gender: 'Unknown',
    age: searchDefaultAge,
    priceMinCents: SEARCH_START_MIN_CENTS,
    priceMaxCents: SEARCH_START_MAX_CENTS,
    startStep: stepsOrder[0],
    hasChosenGender: false,
    hints: '',
};

const reducer = (state: SearchState = defaultState, action: Action = {type: ''}): SearchState => {
    switch (action.type) {
        case SET_GENDER:
            return {...state, gender: action.gender, hasChosenGender: true};
        case SET_AGE:
            return {...state, age: action.age};
        case SET_BUDGET:
            return {...state, priceMinCents: action.priceMinCents, priceMaxCents: action.priceMaxCents};
        case SET_STEP:
            return {...state, startStep: action.step};
        case SET_HINTS:
            return {...state, hints: action.hints};
        default:
            return state;
    }
};
export default reducer;