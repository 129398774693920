import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './ShareDialog.scss';
import { useSimpleTranslation } from '../../hooks/Translator';
import Button from '../../base/Button';
import closeImg from '../../../images/close_main_fg.svg';

function selectAll(input: HTMLInputElement) {
    input.select();
    input.setSelectionRange(0, 99999); /* For mobile devices */
}

const SharePopUp: React.FC<{
    url: string,
    close: () => void}> = ({url, close}) => {
    const t = useSimpleTranslation();
    const parent = document.getElementById('popup') as HTMLElement;
    const inputEl = React.useRef<HTMLInputElement>(null);
    // Stop the propagation of click events outside of the popup to avoid triggering click handlers in parent components.
    return ReactDOM.createPortal(<div id="square-share-dialog-mask">
        <div id="square-share-dialog" onClick={e => {e.stopPropagation();}}>
            <img className="square-share-close clickable" src={closeImg} alt="Close share pop-up" onClick={e => {close();e.stopPropagation()}}/>
            <h1>{t.search.share.title}</h1>
            <p>{t.search.share.square.linkHeader}</p>
            <div className="square-share-input-row">
                <input id="square-share-url" type="text" value={url} readOnly ref={inputEl} onFocus={() => {
                    if (inputEl.current !== null) {
                        selectAll(inputEl.current);
                    }
                }}/>
                <Button type="primary" filled={false} small label={t.search.share.square.copyLabel} onClick={() => {
                    if (inputEl.current !== null) {
                        selectAll(inputEl.current);
                        if (navigator.clipboard === undefined) {
                            // This feature can be disabled for security reasons. Outside https for instance when testing.
                            // https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
                            return;
                        }
                        navigator.clipboard.writeText(inputEl.current.value);
                    }
                }}/>    
            </div>
        </div>
    </div>, parent);
};

export default SharePopUp;