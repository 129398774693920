import { TranslateFn } from "../i18n/TranslatorHOC";

export const SEARCH_START_MIN_CENTS = 2000;
export const SEARCH_START_MAX_CENTS = 10000;

export const MAX_POS = 80;
export const CENTS = 100;

// Converts a position on a slicer or range for a price to the corresponding price.
// The position goes from 0 to MAX_POS included.
// The price from 0 to ? included.

// We have a step of:
// -  1€ between 0 and 20€
// -  5€ between 20 and 100€
// - 10€ afterwards
export const posToPrice = (pos: number) => {
    if (pos <= 20) {
        return pos;
    }
    if (pos <= 36) {
        return (pos - 20) * 5 + 20;
    }
    return (pos - 36) * 10 + 100;
};

// Inverse of posToPrice.
export const priceToPos = (price: number) => {
    if (price < 20) {
        return price;
    }
    if (price < 100) {
        return Math.floor((price - 20) / 5) + 20;
    }
    return Math.floor((price - 100) / 10) + 36;
};


type PriceMarks = {
    [key: number]: string
};

export const sliderPriceMarks: PriceMarks = {};
[10, 20, 50, 100, 200].forEach(price => {
    sliderPriceMarks[priceToPos(price)] = `${price}`;
});

export const priceTipFormatter = (translate: TranslateFn, pos: number) => translate(l => l.contrib.ideas.add.generics.priceTipFormatter, {price: Math.floor(posToPrice(pos))});

if (false) {
    for (let price of [0, 1, 2, 5, 10, 20, 50, 100, 150, 200, 300]) {
        const check = posToPrice(priceToPos(price));
        if (check !== price) {
            console.log('price', price, 'check', check, 'pos', priceToPos(price));
        }
    }
}
