import { UserSex } from "../../model/gender"
import { extractVisitSource } from "../admin/analytics/VisitLocation";
import { isGoogleVisitSource } from "../../model/search";
import { monthsInAYear } from "../../model/age";
import routes from "../../routes";

export type Occasion = 'christmas'

export type StartStateFromLocation = {
    age?: number,
    gender?: UserSex,
    occasion?: Occasion,
}

const keywordsFemale = ['femme', 'mere', 'maman', 'copine', 'soeur', 'amie'];
const keywordsMale = ['homme', 'mari', 'pere', 'papa', 'copain', 'frere'];
const keywordsCouple = ['couple'];
const yearTests: number[] = [18, 20, 21, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70];
const keywordsChristmas = ['noel', 'noël'];

// This logic is duplicated in square.rs, we could call the server at some point to remove this logic here
export function getStartStateFromLocation(): StartStateFromLocation {
    const location = window.location;
    const fromLandingPage = getStartStateFromLandingPage(location.pathname);
    try {
        const visitSource = extractVisitSource(location.search);
        if (isGoogleVisitSource(visitSource) && visitSource.Google.keyword !== undefined) {
            return getStartStateFromKeyword(visitSource.Google.keyword.keyword.toLowerCase());
        }
        return fromLandingPage;
    } catch (e) {
        // extractVisitSource throws on unrecognized visit source, and we
        // don't want to fail in this situation as it is easy to produce
        // as a user.
        return fromLandingPage;
    }
}

function getStartStateFromLandingPage(pathname: string): StartStateFromLocation {
    let occasion: Occasion | undefined = undefined;
    if (pathname === routes.landingPages.christmasFr) {
        occasion = 'christmas';
    } 
    return {
        age: undefined, 
        gender: undefined,
        occasion};
}

function getStartStateFromKeyword(lowerCaseKeyword: string): StartStateFromLocation {
    let age: number | undefined = undefined;
    let gender: UserSex | undefined = undefined;
    let occasion: Occasion | undefined = undefined;
    for (let k of keywordsFemale) {
        if (lowerCaseKeyword.includes(k)) {
            gender = 'Female';
            break;
        }
    }
    for (let k of keywordsMale) {
        if (lowerCaseKeyword.includes(k)) {
            gender = 'Male';
            break;
        }
    }
    for (let k of keywordsCouple) {
        if (lowerCaseKeyword.includes(k)) {
            gender = 'Couple';
            break;
        }
    }
    for (let ageTest of yearTests) {
        // Prevents %20 used in urls encoding space to be detected as a 20 years hint
        if (lowerCaseKeyword.includes(`${ageTest}`) && !lowerCaseKeyword.includes(`%${ageTest}`)) {
            age = ageTest * monthsInAYear;
        }
    }
    for (let christmasTest of keywordsChristmas) {
        if (lowerCaseKeyword.includes(christmasTest)) {
            occasion = 'christmas';
        }
    }
    return {age, gender, occasion};
}
