export const SET_PAYLOAD = 'SHARE_SET_PAYLOAD';
export type SHARE_SET_PAYLOAD = typeof SET_PAYLOAD;

// The native share payload as explained at https://web.dev/web-share/
export type SharePayload = {
    title: string,
    text: string,
    url: string,
}

export type ShareSetPayload = {
    type: SHARE_SET_PAYLOAD,
    payload: SharePayload,
}

export type ShareAction = ShareSetPayload;