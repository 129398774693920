import { ShareState } from "../store";
import { SET_PAYLOAD } from "../actions/share";
import { Action } from "../actions";


const defaultState: ShareState = {
    payload: {
        title: '',
        text: '',
        url: '',
    }
}

const reducer = (state: ShareState = defaultState, action: Action = {type: ''}): ShareState => {
    switch (action.type) {
        case SET_PAYLOAD:
            return {...state, payload: action.payload};
        default:
            return state;
    }
};
export default reducer;