import * as React from 'react';

import Account from './account/Badge';
import If from './base/If'
import routes from '../routes';

import './Header.scss';

type Props = {
    path: string,
}

function needsToDisplayAccount(path: string) {
    return path.startsWith(routes.contrib.home) || path.startsWith(routes.admin.home) || path.startsWith(routes.moderation.home) || path === routes.about.home;
}

const Header: React.FC<Props> = ({ path }) => {
    return <If test={path !== routes.home && path !== routes.search && path !== routes.admin.testSize}>
        <header>
            <If test={needsToDisplayAccount(path)}>
                <div id="header-account-holder">
                    <Account />
                </div>
            </If>
        </header>
    </If>;
}

export default Header;