import { Action } from '../../actions';
import * as ca from '../../actions/contrib/reactions'
import { ContribReactionsState } from '../../store';

const defaultState: ContribReactionsState = {
    availableQuestions: [],
    hideWithReactions: true,
    ideas: [],
    question: undefined,
    ideasSource: 'suggestions',
    ideasParentQuestion: -1,
};

const reducer = (state: ContribReactionsState = defaultState, action: Action = {type: ''}): ContribReactionsState => {
    switch (action.type) {
        case ca.SET_AVAILABLE_QUESTIONS:
            return {...state, availableQuestions: action.questions};
        case ca.SET_IDEAS:
            return {...state, ideas: action.ideas, ideasParentQuestion: action.question};
        case ca.SET_QUESTION:
            return {...state, question: action.question};
        case ca.HIDE_ALREADY_REACTED:
            return {...state, hideWithReactions: action.hide};
        case ca.SET_IDEAS_SOURCE:
            return {...state, ideasSource: action.source};
        default:
            return state;
    }
};
export default reducer;