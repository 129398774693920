import * as React from 'react';
import { colors } from '../../identity';
import { Point } from '../../util/geometry';
import {sizes as cardSizes} from './Card';
import logo from '../../images/logo.svg';
import favicon from '../../images/favicon.svg';
import useResponsive from '../hooks/Responsive';

type DrawType = 'verso' | 'recto-text' | 'recto-image';

type CanvasProps = {
    cardWidth: number,
    cardHeight: number,
    // Avoid unmounting-remounting often this component that is heavy to render.
    visible: boolean,
    drawType: DrawType,
}

type ToggableCanvasProps = {
    cardWidth: number,
    cardHeight: number,
    // Avoid unmounting-remounting often this component that is heavy to render.
    visible: boolean,
}

type CircleCoord = [number, number, number]; // x,y,r

type Wave = {
    center: CircleCoord,
    color: string,
    max: number,
}

type DrawInput = {
    cardWidth: number,
    cardHeight: number,
    dpr: number,
    drawType: DrawType,
}

function areSameDrawInput(l: DrawInput, r: DrawInput) {
    return l.cardHeight === r.cardHeight && l.cardWidth === r.cardWidth && l.dpr === r.dpr && l.drawType === r.drawType;
}

const waveColor = colors.palette.c1;


function addWaves(ctx: CanvasRenderingContext2D, waves: Wave[], waveStep: number) {
    for (let i = 40; i >= 0; i--) {
        for (let j = 0; j < waves.length; j++) {
            const {center, max} = waves[j];
            if (i > max || i === 0) {
                continue;
            }
            let r = center[2] + i * waveStep;
            ctx.beginPath();
            ctx.fillStyle = waveColor;
            ctx.arc(center[0], center[1], r, 0, Math.PI * 2);
            ctx.fill();
        }
        for (let j = 0; j < waves.length; j++) {
            const {center, max} = waves[j];
            if (i > max) {
                continue;
            }
            let r = center[2] + i * waveStep - 0.5;
            ctx.beginPath();
            ctx.fillStyle = "#fff";
            ctx.arc(center[0], center[1], r, 0, Math.PI * 2);
            ctx.fill();
        }
    }
}

function addImage(ctx: CanvasRenderingContext2D, src: string, x: number, y: number, width: number, height: number, reverse: boolean): Promise<void> {
    const img = new Image();
    return new Promise((resolve) => {
        img.onload = () => {
            if (reverse) {
                ctx.rotate(Math.PI);
                ctx.drawImage(img, -Math.floor(x + width), -Math.floor(y + height), Math.floor(width), Math.floor(height));
                ctx.rotate(Math.PI);
            } else {
                ctx.drawImage(img, Math.floor(x), Math.floor(y), Math.floor(width), Math.floor(height));
            }
            resolve();
        };
        img.src = src;
    });
}

function addRectWithBorderRadius(ctx: CanvasRenderingContext2D, topLeft: Point, size: Point, borderRadius: number) {
    const xLeft = topLeft[0] + borderRadius;
    const xRight = topLeft[0] + size[0] - borderRadius;
    const yTop = topLeft[1] + borderRadius;
    const yBottom = topLeft[1] + size[1] - borderRadius;
    ctx.moveTo(xLeft, topLeft[1]);
    ctx.arc(xRight, yTop, borderRadius, Math.PI * 3/2, 0);
    ctx.arc(xRight, yBottom, borderRadius, 0, Math.PI / 2);
    ctx.arc(xLeft, yBottom, borderRadius, Math.PI / 2, Math.PI);
    ctx.arc(xLeft, yTop, borderRadius, Math.PI, Math.PI * 3 / 2);
}

function addBorders(ctx: CanvasRenderingContext2D, cardWidth: number, cardHeight: number, dpr: number, addPrintBorder: boolean, clip: boolean) {
    const shadowMargin = cardSizes.shadowMargin * dpr;
    const borderMargin = cardSizes.borderMargin * dpr;
    const innerMargin = (cardSizes.shadowMargin + cardSizes.borderMargin) * dpr;
    const borderRadius = cardSizes.borderRadius * dpr;
    const printBorderWidth = 1;
    const clipMargin = innerMargin + printBorderWidth;
    // Physical border
    ctx.beginPath();
    ctx.shadowColor = 'rgba(0, 0, 0, 0.1)';
    ctx.shadowBlur = 15;
    ctx.shadowOffsetX = -5;
    ctx.shadowOffsetY = 5;
    ctx.strokeStyle = 'rgb(224, 224, 224)';
    ctx.fillStyle = '#ffffff';
    addRectWithBorderRadius(ctx, [shadowMargin, shadowMargin], [cardWidth - shadowMargin * 2, cardHeight - shadowMargin * 2], borderRadius + borderMargin);
    ctx.fill();
    ctx.stroke();

    ctx.shadowBlur = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowOffsetX = 0;

    if (addPrintBorder) {
        ctx.beginPath();
        ctx.strokeStyle = '#91d5fc';
        addRectWithBorderRadius(ctx, [innerMargin, innerMargin], [cardWidth - innerMargin * 2, cardHeight - innerMargin * 2], borderRadius);
        ctx.stroke();

        if (clip) {
            ctx.beginPath();
            addRectWithBorderRadius(ctx, [clipMargin, clipMargin], [cardWidth - clipMargin * 2, cardHeight - clipMargin * 2], borderRadius - printBorderWidth);
            ctx.clip();
        }
    }
}

async function addFavicon(ctx: CanvasRenderingContext2D, x: number, y: number, reverse: boolean, dpr: number) {
    const r = cardSizes.circleRadius * dpr;
    ctx.beginPath();
    ctx.fillStyle = '#ffffff';
    ctx.arc(x, y, r + 6 * dpr, 0, Math.PI * 2);
    ctx.fill();
    await addImage(ctx, favicon, x - r, y - r, r * 2, r * 2, reverse);
}

async function drawInCanvas(canvas: HTMLCanvasElement | null, input: DrawInput): Promise<void> {
    if (canvas === null) {
        return;
    }
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
        return;
    }
    const {cardWidth: cardWithFromInput, cardHeight: cardHeightFromInput, dpr, drawType} = input;
    const cardWidth = cardWithFromInput * dpr;
    const cardHeight = cardHeightFromInput * dpr;
    const waveStep = 8 * dpr;
    const defaultMaxWave = 40;
    const logoWidth = cardWidth / 2.5;
    const logoHeight =logoWidth / 2;
    const logoX = cardWidth / 2 - logoWidth / 2;
    const logoY = cardHeight / 2 - logoHeight / 2;
    const circleCenter = ((x: number, y: number, r: number) => { const p: CircleCoord = [logoX + logoWidth * x / 135.4, logoY + logoHeight * y / 67.7, r * logoWidth / 135.4]; return p;});
    const waves: Wave[] = [
        {center: circleCenter(40.6, 26.8, 18.9), color: colors.palette.c2, max: defaultMaxWave},
        {center: circleCenter(22.84, 268.8 - 229, 13), color: colors.palette.c3, max: defaultMaxWave},
        {center: circleCenter(62.9, 263.8 - 229, 15.3), color: colors.palette.c4, max: defaultMaxWave},
        {center: circleCenter(79.3, 274.2 - 229, 8), color: colors.fg.main, max: defaultMaxWave}, // b
        {center: circleCenter(116, 280.3 - 229, 8), color: colors.fg.main, max: defaultMaxWave}, // y
        {center: [cardWidth * 0.6, cardHeight * 0.32, waveStep], color: colors.fg.main, max: defaultMaxWave},
        {center: [cardWidth * 0.45, cardHeight * 0.7, waveStep], color: colors.fg.main, max: defaultMaxWave},
        {center: [cardWidth * 0.1, cardHeight * 0.2, waveStep], color: colors.fg.main, max: defaultMaxWave / 2},
        {center: [cardWidth * 0.9, cardHeight * 0.9, waveStep], color: colors.fg.main, max: defaultMaxWave / 2},
    ];

    const drawBorder = drawType === 'recto-text' || drawType === 'verso';
    const drawWaves = drawType === 'verso';
    addBorders(ctx, cardWidth, cardHeight, dpr, drawBorder, drawWaves);
    if (drawWaves) {
        addWaves(ctx, waves, waveStep);
        await addImage(ctx, logo, logoX, logoY, logoWidth, logoHeight, false);
    }
    if (drawType === 'recto-text') {
        const innerMargin = (cardSizes.shadowMargin + cardSizes.borderMargin) * dpr;
        const circleMargin = cardSizes.circleMargin * dpr;

        await addFavicon(ctx, innerMargin + circleMargin, innerMargin + circleMargin, false, dpr);
        await addFavicon(ctx, cardWidth - innerMargin - circleMargin, cardHeight - innerMargin - circleMargin, true, dpr);
    }
}

type OffscreenCache = {
    canvas: HTMLCanvasElement | null,
    input: DrawInput,
}

type CacheListener = {
    target: HTMLCanvasElement,
    input: DrawInput,
    listener: (c: HTMLCanvasElement | null) => void,
}

const canvasCache: Map<DrawType, OffscreenCache> = new Map();
const cacheListeners: CacheListener[] = [];

function getAndRemoveListenersForInput(input: DrawInput): CacheListener[] {
    const result: CacheListener[] = [];
    for (let i = cacheListeners.length - 1; i >= 0; i--) {
        const listener = cacheListeners[i];
        if (areSameDrawInput(listener.input, input)) {
            result.push(listener);
            cacheListeners.splice(i, 1);
        }
    }
    return result;
}

/**
 * The same component can be rendered multiple times quickly.
 * The drawing being asynchronous, we can have the drawing for an old size that finishes after the drawing for a newer size.
 * This could lead to incorrect rendered images if we used the latest finished drawing in these situation as the old size is incorrect.
 * We clear here all listeners for the same component so that old draw orders do not notify their listeners when they finish.
 * This way a card has always the latest rendered background, even if it was not the last one that finished.
 *
 * @param target The canvas where we will copy the rendered canvas.
 */
function removeListenersWithSameTarget(target: HTMLCanvasElement) {
    for (let i = cacheListeners.length - 1; i >= 0; i--) {
        const listener = cacheListeners[i];
        if (listener.target === target) {
            listener.listener(null);
            cacheListeners.splice(i, 1);
        }
    }
}

function redrawCache(input: DrawInput, target: HTMLCanvasElement): Promise<HTMLCanvasElement | null> {
    for (let cacheListener of cacheListeners) {
        if (areSameDrawInput(cacheListener.input, input)) {
            return new Promise((resolve) => {
                cacheListeners.push({input, listener: resolve, target});
            });
        }
    }
    const {cardWidth, cardHeight, dpr, drawType} = input;
    // Recreate each time, async images could pollute next canvas otherwise.
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = cardWidth * dpr;
    offscreenCanvas.height = cardHeight * dpr;
    return new Promise((resolve) => {
        cacheListeners.push({input, listener: resolve, target});
        drawInCanvas(offscreenCanvas, input).then(() => {
            getAndRemoveListenersForInput(input).forEach(l => l.listener(offscreenCanvas));
            canvasCache.set(drawType, {canvas: offscreenCanvas, input});
        });
    });
}

async function fillBackgroundCanvasFromCache(target: HTMLCanvasElement | null, input: DrawInput) {
    if (target === null) {
        return;
    }
    removeListenersWithSameTarget(target);
    const ctx = target.getContext('2d');
    if (ctx === null) {
        return;
    }
    const {drawType} = input;
    const canvasCacheForType = canvasCache.get(drawType);
    let correctCanvas = null;
    if (canvasCacheForType === undefined) {
        correctCanvas = await redrawCache(input, target);
    } else {
        let {canvas: cacheCanvas, input: inputOfCached} = canvasCacheForType;
        if (cacheCanvas === null || !areSameDrawInput(input, inputOfCached)) {
            correctCanvas = await redrawCache(input, target);
        } else {
            correctCanvas = cacheCanvas;
        }
    }
    if (correctCanvas !== null) {
        ctx.clearRect(0, 0, input.cardWidth * input.dpr, input.cardHeight * input.dpr);
        ctx.drawImage(correctCanvas, 0, 0);
    }
}

const BackgroundCanvas: React.FC<CanvasProps> = ({cardWidth, cardHeight, visible, drawType}) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const {devicePixelRatio: dpr} = useResponsive();
    React.useEffect(() => {
        fillBackgroundCanvasFromCache(canvasRef.current, {cardWidth, cardHeight, dpr, drawType});
    }, [canvasRef, cardWidth, cardHeight, dpr, drawType]);
    return <canvas ref={canvasRef} width={cardWidth * dpr} height={cardHeight * dpr} style={{position: 'absolute', width: cardWidth, height: cardHeight, visibility: visible ? 'visible' : 'hidden'}}></canvas>;
}

const VersoNonMemo: React.FC<ToggableCanvasProps> = ({cardWidth, cardHeight, visible}) => {
    return <BackgroundCanvas cardWidth={cardWidth} cardHeight={cardHeight} visible={visible} drawType="verso" />;
}

const RectoTextBackgroundNonMemo: React.FC<ToggableCanvasProps> = ({cardWidth, cardHeight, visible}) => {
    return <BackgroundCanvas cardWidth={cardWidth} cardHeight={cardHeight} visible={visible} drawType="recto-text" />;
}

const RectoImageBackgroundNonMemo: React.FC<ToggableCanvasProps> = ({cardWidth, cardHeight, visible}) => {
    return <BackgroundCanvas cardWidth={cardWidth} cardHeight={cardHeight} visible={visible} drawType="recto-image" />;
}

export const Verso = React.memo(VersoNonMemo, (prev, curr) => prev.cardWidth === curr.cardWidth && prev.cardHeight === curr.cardHeight && prev.visible === curr.visible);
export const RectoTextBackground = React.memo(RectoTextBackgroundNonMemo, (prev, curr) => prev.cardWidth === curr.cardWidth && prev.cardHeight === curr.cardHeight && prev.visible === curr.visible);
export const RectoImageBackground = React.memo(RectoImageBackgroundNonMemo, (prev, curr) => prev.cardWidth === curr.cardWidth && prev.cardHeight === curr.cardHeight && prev.visible === curr.visible);