import { ServerSearchSessionSummary, ServerSearchSessionReport } from "../../../model/search";
import { ServerTwinsGroup, ServerTwinsFetchConfig } from "../../../model/analysis";
import { SpreadTestConfig, SpreadTestResult } from "../../reducers/admin/analysis";

export const SET_TWINS = 'ANALYSIS_SET_TWINS';
export type ANALYSIS_SET_TWINS = typeof SET_TWINS;
export const SET_TWINS_FETCH_CONFIG = 'ANALYSIS_SET_TWINS_FETCH_CONFIG';
export type ANALYSIS_SET_TWINS_FETCH_CONFIG = typeof SET_TWINS_FETCH_CONFIG;
export const SET_SEARCH_SESSIONS_SUMMARIES = 'ANALYSIS_SET_SEARCH_SESSIONS_SUMMARIES';
export type ANALYSIS_SET_SEARCH_SESSIONS_SUMMARIES = typeof SET_SEARCH_SESSIONS_SUMMARIES;
export const SET_SEARCH_SESSIONS_DETAILS = 'ANALYSIS_SET_SEARCH_SESSIONS_DETAILS';
export type ANALYSIS_SET_SEARCH_SESSIONS_DETAILS = typeof SET_SEARCH_SESSIONS_DETAILS;
export const SET_SEARCH_SESSIONS_DAY_STATS = 'ANALYSIS_SET_SEARCH_SESSIONS_DAY_STATS';
export type ANALYSIS_SET_SEARCH_SESSIONS_DAY_STATS = typeof SET_SEARCH_SESSIONS_DAY_STATS;

export const SET_SPREAD_TEST_CONFIG = 'ANALYSIS_SET_SPREAD_TEST_CONFIG';
export type ANALYSIS_SET_SPREAD_TEST_CONFIG = typeof SET_SPREAD_TEST_CONFIG;
export const SET_SPREAD_TEST_RESULT = 'ANALYSIS_SET_SPREAD_TEST_RESULT';
export type ANALYSIS_SET_SPREAD_TEST_RESULT = typeof SET_SPREAD_TEST_RESULT;

export type AnalysisSetTwins = {
    type: ANALYSIS_SET_TWINS,
    twins: ServerTwinsGroup[],
}

export type AnalysisSetTwinsFetchConfig = {
    type: ANALYSIS_SET_TWINS_FETCH_CONFIG,
    config: ServerTwinsFetchConfig,
}

export type AnalysisSetSearchSessionsSummaries = {
    type: ANALYSIS_SET_SEARCH_SESSIONS_SUMMARIES,
    summaries: ServerSearchSessionSummary[],
}

export type AnalysisSetSearchSessionsDetails = {
    type: ANALYSIS_SET_SEARCH_SESSIONS_DETAILS,
    detail: Map<string, ServerSearchSessionReport>,
}

export type AnalysisSetSpreadTestConfig = {
    type: ANALYSIS_SET_SPREAD_TEST_CONFIG,
    config: SpreadTestConfig,
}

export type AnalysisSetSpreadTestResult = {
    type: ANALYSIS_SET_SPREAD_TEST_RESULT,
    result: SpreadTestResult,
}

export type AnalysisAction = AnalysisSetTwins
| AnalysisSetTwinsFetchConfig
| AnalysisSetSearchSessionsSummaries
| AnalysisSetSearchSessionsDetails
| AnalysisSetSpreadTestConfig
| AnalysisSetSpreadTestResult
;
