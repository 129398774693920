import * as React from 'react';
import classNames from 'classnames';

import './AnswerPicker.scss';
import { AnswerOrFeedbackValue, AnswerValueMap } from '../../model/answer';
import { uppercaseFirstLetter } from '../../util/strings';
import { PageSize } from '../ResponsiveHOC';
import Button, { answerButtonTypes } from '../base/Button';
import { LocaleContent } from '../../i18n/locales/types';
import { useSimpleTranslation } from '../hooks/Translator';
import KeyboardShortcuts from '../base/KeyboardShortcuts';
import { CardContent, isQuestion } from './Card';
import { AskableQuestion } from '../../model/question';

export const dxThresholdAnswer = 50;

type Props = {
    allowDoubt: boolean,
    onPickAnswer: (value: AnswerOrFeedbackValue) => void,
    disabled: boolean,
    directionalHighlight: number | undefined
}

type GuardProps = {
    // The content currently displayed
    content: CardContent,
    // The question in the search flow state
    question?: AskableQuestion,
    loading: boolean,
    onPickAnswer: (value: AnswerOrFeedbackValue) => void,
    directionalHighlight: number | undefined
}

type AnswerProps = {
    a: AnswerOrFeedbackValue,
}

function getAnswerText(t: LocaleContent, a: AnswerOrFeedbackValue) {
    return uppercaseFirstLetter(t.question.answers[a]);
}

const AnswerComponent: React.FC<AnswerProps> = ({a}) => {
    const t = useSimpleTranslation();
    return <>{getAnswerText(t, a)}</>;
}

const isFilled: AnswerValueMap<boolean> = {
    'Yes': true,
    'ProbablyYes': false,
    'IDontKnow': true,
    'No': true,
    'ProbablyNo': false,
    // Should not be called
    'Good': false,
    'Bad': false,
};

const compactLimit = 800;

// Read from CSS
const buttonPaddingV = 7;
const mainFontSize = 22;
const secondaryFontSize = 19;
const marginTop = 20;

export const getAnswerPickerHeight = (pageSize: PageSize): number => {
    const compact = pageSize.x < compactLimit;
    if (compact) {
        return mainFontSize + marginTop + buttonPaddingV * 2;
    }
    return mainFontSize + secondaryFontSize + marginTop * 2 + buttonPaddingV * 4;
}

function createDirectionalHighlightFilter(directionalHighlight: undefined | number, isYes: boolean, isNo: boolean) {
    if (directionalHighlight === undefined) {
        return undefined;
    }
    const percent = Math.floor(100 * Math.min(1, Math.abs(directionalHighlight) / dxThresholdAnswer));
    const highlightPositive = (directionalHighlight > 0 && isYes) || (directionalHighlight < 0 && isNo);
    if (highlightPositive) {
        return `contrast(${percent + 100}%)`;
    } else {
        return `grayscale(${percent}%)`;
    }
}

function renderChoice(
    t: LocaleContent,
    allowDoubt: boolean,
    disabled: boolean,
    onPickAnswer: (value: AnswerOrFeedbackValue) => void,
    k: AnswerOrFeedbackValue,
    directionalHighlight: undefined | number) {
    const isMain = k === 'Yes' || k === 'No' || k === 'IDontKnow';
    const className = classNames({
        answer: true,
        'answer-main': isMain,
        'answer-secondary': !isMain,
    })
    const label = getAnswerText(t, k);
    const type = answerButtonTypes[k];
    const filled = isFilled[k];
    const visible = allowDoubt ? true : k !== 'IDontKnow' && k !== 'ProbablyNo' && k !== 'ProbablyYes';
    const filter = createDirectionalHighlightFilter(directionalHighlight, k === 'Yes', k === 'No')
    return <div key={k} className={visible ? "answer-button-visible" : "answer-button-hidden"} style={{filter}}><Button
        disabled={disabled}
        type={type}
        filled={filled}
        className={className}
        onClick={() => {onPickAnswer(k);}}
        label={label}/>
    </div>;
}

function onKeyPress(t: LocaleContent, ev: KeyboardEvent, onPickAnswer: (value: AnswerOrFeedbackValue) => void) {
    const c = ev.key;
    if (c === t.question.keyMapping.yes) {
        onPickAnswer('Yes');
    }
    if (c === t.question.keyMapping.no) {
        onPickAnswer('No');
    }
    if (c === t.question.keyMapping.dontKnow) {
        onPickAnswer('IDontKnow');
    }
}


const answers: AnswerOrFeedbackValue[] = ['No', 'IDontKnow', 'Yes'];

const AnswerPicker: React.FC<Props> = ({onPickAnswer, disabled, allowDoubt, directionalHighlight}) => {
    const t = useSimpleTranslation();
    return <div id="search-answers">
        <KeyboardShortcuts onKeyPress={e => onKeyPress(t, e, onPickAnswer)} />
        <div className="search-answers-line">
            {answers.map(a => renderChoice(t, allowDoubt, disabled, onPickAnswer, a, directionalHighlight))}
        </div>
    </div>;
}

const AnswerPickerGuard: React.FC<GuardProps> = ({question, content, loading, directionalHighlight, onPickAnswer}) => {
    if (!isQuestion(content)) {
        return null;
    }
    let allowDoubt = question === undefined ? true : question.allow_doubt;
    if (question === undefined && !loading) {
        return null;
    }
    return <>
        <AnswerPicker
            directionalHighlight={directionalHighlight}
            onPickAnswer={onPickAnswer}
            disabled={loading}
            allowDoubt={allowDoubt}
        />
    </>;
}

export default AnswerPickerGuard;
export {
    AnswerComponent
};