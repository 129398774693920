import { Idea } from "../../../../model/idea";

export const SET_IDEAS = 'CONTRIB_IDEAS_COHORT_SET_IDEAS';
export type CONTRIB_IDEAS_COHORT_SET_IDEAS = typeof SET_IDEAS;

export type ContribIdeasCohortSetIdeas = {
    type: CONTRIB_IDEAS_COHORT_SET_IDEAS,
    ideas: Idea[],
}

export type ContribIdeasCohortAction =
    ContribIdeasCohortSetIdeas
;
