import * as React from 'react';
import { useSelector } from 'react-redux';
import H from 'history';

import Button, { LinkButton } from '../../base/Button';
import { UserSex } from '../../../model/gender';
import { StoreState } from '../../../redux/store';
import routes from '../../../routes';
import DraggableAnswer, { DND_TYPE } from './DraggableAnswer';
import { useQueryJson, isReadyQuery } from '../../hooks/Query';
import useTranslation, { useSimpleTranslation } from '../../hooks/Translator';

import './AlertCreator.scss';
import IgnorePosLimit, { ignorePosLimitHeight } from './IgnorePosLimit';
import useResponsive from '../../hooks/Responsive';
import { useDrop, DragElementWrapper } from 'react-dnd';
import querier from '../../../server/querier';
import { ServerAlertCreation, ServerAlertCreationAnswer, ServerAlertCreationInit, ServerAlertCreationInitAnswer } from '../../../model/alert';
import { RouteComponentProps } from 'react-router-dom';
import { isValidEmailLight } from '../../../util/strings';
import { useStateHotReloadable } from '../../hooks/Persist';
import { PageSize } from '../../ResponsiveHOC';
import { LocaleContent } from '../../../i18n/locales/types';
import NotReadyQuery from '../../base/Query';

type PropsFromRouter = {
    sid: string // the search session id
}

type TopIndexAndAnswer = [number, number, ServerAlertCreationInitAnswer];
type AnswerOrLimit = number | null;
type SubmissionState = 'not-submitted' | 'submitting' | 'success-validation-needed' | 'success-validation-not-needed' | 'error';

type Props = RouteComponentProps<PropsFromRouter>;

type SubmitProps = {
    email: string,
    sessionId: string,
    init: ServerAlertCreationInit | undefined,
    userOrder: AnswerOrLimit[] | undefined,
    submissionState: SubmissionState,
    setSubmissionState: (s: SubmissionState) => void,
    history: H.History,
}

type StepperProps = {
    sessionId: string,
    history: H.History,
    posOver: number,
    setPosOver: (pos: number) => void,
    submissionState: SubmissionState,
    setSubmissionState: (s: SubmissionState) => void,
    email: string,
    setEmail: (s: string) => void,
    order: AnswerOrLimit[] | undefined,
    setOrder: (o: AnswerOrLimit[] | undefined) => void,
    init: ServerAlertCreationInit | undefined,
    setInit: (i: ServerAlertCreationInit | undefined) => void,
    dropOutside: DragElementWrapper<any>,
}

type AnswersSorterProps = {
    sessionId: string,
    posOver: number,
    setPosOver: (pos: number) => void,
    order: AnswerOrLimit[] | undefined,
    setOrder: (o: AnswerOrLimit[] | undefined) => void,
    setInit: (i: ServerAlertCreationInit) => void,
}

const transitionDurationMs = 100;
const qidIgnoreLimit = -2;
const qidsInTransitions = new Set<number>();
const importanceIgnored = -1;

const SubmitButton: React.FC<SubmitProps> = ({email, sessionId, setSubmissionState, submissionState, init, userOrder, history}) => {
    const {translate} = useTranslation();

    async function onSubmit() {
        if (userOrder === undefined || init === undefined) {
            // The button should be disabled.
            return;
        }
        setSubmissionState('submitting');
        const userIgnorePos = userOrder.indexOf(null);
        const answers = init.answers.map((a, index) => {
            const indexUser = userOrder.indexOf(a.question_id);
            const toSend: ServerAlertCreationAnswer = {
                question_id: a.question_id,
                answer: a.a,
                server_importance: a.ignore ? importanceIgnored : init.answers.length - index - 1,
                user_importance: indexUser > userIgnorePos ? importanceIgnored : userIgnorePos - index - 1,
            };
            return toSend;
        });
        const r = await querier.queryJsonJson<ServerAlertCreation, boolean>('public/alert/create', {
            session_id: sessionId,
            email,
            answers,
        });
        const success = r !== null;
        setSubmissionState(success ? r ? 'success-validation-not-needed' : 'success-validation-needed' : 'error');
        const timeout = success ? 3000 : 2000;
        setTimeout(() => {
            // Redirect if success
            // Clear submission state in all cases, if this was an error this will clear the error message, if this
            // was a success this lets the user reopen the AlertCreator to create another alert.
            setSubmissionState('not-submitted')
            if (success) {
                history.goBack();
            }
        }, timeout);
    };
    const disabled = userOrder === undefined || init === undefined || submissionState === 'submitting' || !isValidEmailLight(email);

    return <Button type="primary" disabled={disabled} filled={true} label={translate(l => l.search.alert.submitLabel)} onClick={() => onSubmit()}/>
}

const AnswersSorter: React.FC<AnswersSorterProps> = ({sessionId, posOver, setPosOver, order, setOrder, setInit}: AnswersSorterProps) => {
    const {result: creationInit} = useQueryJson<ServerAlertCreationInit>(`public/alert/creation-init/${sessionId}`, {
        callback: init => {
            setInit(init);
            const order: AnswerOrLimit[] = init.answers.map(a => a.question_id);
            const limitPos = init.answers.findIndex(a => a.ignore);
            order.splice(limitPos, 0, null);
            setOrder(order);
    }});
    const [qidDrag, setQidDrag] = React.useState<number>(-1);
    const gender = useSelector<StoreState, UserSex>(state => state.search.gender);
    const {translate} = useTranslation();
    const responsive = useResponsive();


    // valid is true when called from a drop target, false when called from the drag item drop event.
    // in case the user drops above a drop target this function is called twice in a row, first with valid true then valid false.
    const onDrop = (valid: boolean) => {
        setPosOver(-1);
        setQidDrag(-1);
        if (valid) {
            setOrder(renderOrder);
        }
    };

    // Create a drop target so that the monitor.isOver shallow test of the dropOutside target
    // only matches droping outside of the answer list.
    const dropInside = useDrop({accept: DND_TYPE,})[1];

    let renderOrder: undefined | AnswerOrLimit[] = undefined;
    let dragIsIgnored = false;
    if (order !== undefined) {
        renderOrder = [...order];
    }
    if (renderOrder !== undefined) {
        if (qidDrag !== -1 && posOver !== -1) {
            const posDrag = renderOrder.indexOf(qidDrag);
            dragIsIgnored = posDrag > renderOrder.indexOf(null);
            const [dragged] = renderOrder.splice(posDrag, 1);
            renderOrder.splice(posOver, 0, dragged);
        }
    }

    if (!isReadyQuery(creationInit) || renderOrder === undefined) {
        return <NotReadyQuery r={creationInit} />;
    }

    const setQidOverProtectDrag = (qid: number, pos: number) => {
        if (qid === qidDrag || qidsInTransitions.has(qid)) {
            return;
        }
        qidsInTransitions.add(qid);
        setTimeout(() => {
            qidsInTransitions.delete(qid);
        }, transitionDurationMs);
        setPosOver(pos);
    };

    const ignorePos = renderOrder.indexOf(null);
    const displayIgnoreHeader = ignorePos >= 0;
    const itemHeight = responsive.devicePixelRatio > 150 ? 40 : 30;
    const contentHeight = creationInit.answers.length * itemHeight + (displayIgnoreHeader ? ignorePosLimitHeight : 0);
    const maxHeight = responsive.pageSize.y - 200;
    const holderHeight = Math.min(maxHeight, contentHeight);
    const holderWidth = Math.min(responsive.pageSize.x - 40, 600);
    const ignoreTop = displayIgnoreHeader ? ignorePos * itemHeight : maxHeight * 2;
    const topAndAnswerPerQid = new Map<number, TopIndexAndAnswer>();
    let top = 0;
    for (let i = 0; i < renderOrder.length; i++) {
        const qid = renderOrder[i];
        if (qid === null) {
            top += ignorePosLimitHeight;
        } else {
            topAndAnswerPerQid.set(qid, [top, i, creationInit.answers.find(a => a.question_id === qid) as ServerAlertCreationInitAnswer]);
            top += itemHeight;
        }
    }
    const items = [
        <IgnorePosLimit key="il" y={ignoreTop} onDrop={() => onDrop(true)} onIsOver={() => setQidOverProtectDrag(qidIgnoreLimit, ignorePos)} isLast={ignorePos === renderOrder.length - 1}/>
    ];
    const qids: number[] = renderOrder.filter(q => q !== null) as number[];
    qids.sort();
    qids.forEach(qid => {
        const [top, i, answer] = topAndAnswerPerQid.get(qid) as TopIndexAndAnswer;
        items.push(<DraggableAnswer
            key={qid}
            answer={answer}
            gender={gender}
            onDragStart={() => setQidDrag(qid)}
            onIsOver={() => setQidOverProtectDrag(qid, i)}
            onDrop={onDrop}
            ignored={qid === qidDrag ? dragIsIgnored : i >= ignorePos}
            y={top}
            height={itemHeight}
        />);
    });
    return <div className="answers-sorter-and-instructions" ref={dropInside}>
        <div className="header-line">{translate(l => l.search.alert.importantQuestionsHeader)}</div>
        <div className="answers-sorter" style={{height: holderHeight, width: holderWidth}}>
            {items}
        </div>
    </div>
}

type Step = 'presentation' | 'sorter' | 'submit';

const MonoPartAlertCreator: React.FC<StepperProps> = (props: StepperProps) => {
    const {email, setEmail, init, sessionId, order, submissionState, setSubmissionState, history} = props;
    const [step, setStep] = React.useState<Step>('presentation');
    const {translate} = useTranslation();
    const submitProps: SubmitProps = {email, sessionId, init, userOrder: order, submissionState, setSubmissionState, history};

    switch (step) {
        case 'presentation':
            return <div className="alert-presentation-mono">
                <p className="p-wrap">{translate(l => l.search.alert.presentation)}</p>
                <div className="alert-buttons-line">
                    <LinkButton type="negative" filled={true} label={translate(l => l.search.alert.cancelLabel)} to={routes.search}/>
                    <Button type="positive" filled={true} onClick={() => setStep('sorter')} label={translate(l => l.search.alert.moveToSorterLabel)}/>
                </div>
            </div>
        case 'sorter':
            return <div className="alert-sorter-mono">
                <AnswersSorter {...props}  />
                <div className="alert-buttons-line">
                    <LinkButton type="negative" filled={true} label={translate(l => l.search.alert.cancelLabel)} to={routes.search}/>
                    <Button type="positive" filled={true} onClick={() => setStep('submit')} label={translate(l => l.search.alert.moveToSubmitLabel)}/>
                </div>
            </div>;
        case 'submit':
            return <div className="alert-submit-mono">
                <p className="p-wrap">{translate(l => l.search.alert.emailExplanationMono)}</p>
                <div className="alert-email-line">
                    <label>{translate(l => l.search.alert.emailLabel)}<input type="text" placeholder={translate(l => l.search.alert.emailPlaceholder)} value={email} onChange={e => setEmail(e.target.value)}/></label>
                </div>
                <div className="alert-buttons-line">
                    <LinkButton type="negative" filled={true} label={translate(l => l.search.alert.cancelLabel)} to={routes.search}/>
                    <SubmitButton {...submitProps} />
                </div>
            </div>;
    };
}

const MultiPartAlertCreator: React.FC<StepperProps> = (props: StepperProps) => {
    const {email, setEmail, init, sessionId, order, submissionState, setSubmissionState, history} = props;
    const {translate} = useTranslation();
    const submitProps: SubmitProps = {email, sessionId, init, userOrder: order, submissionState, setSubmissionState, history};
    return <>
        <p className="p-wrap">{translate(l => l.search.alert.presentation)}</p>
        <AnswersSorter {...props}  />
    <div className="alert-email-line">
        <label>{translate(l => l.search.alert.emailLabel)}<input type="text" placeholder={translate(l => l.search.alert.emailPlaceholder)} value={email} onChange={e => setEmail(e.target.value)}/></label>
    </div>
    <div className="alert-buttons-line">
        <SubmitButton {...submitProps}/>
        <LinkButton type="negative" filled={true} label={translate(l => l.search.alert.cancelLabel)} to={routes.search}/>
    </div>
</>;
}

function getInsideAsText(text: string) {
    return <div className="centered-column"><p className="p-wrap">{text}</p></div>
}

function getInside(submissionState: SubmissionState, pageSize: PageSize, t: LocaleContent, stepperProps: StepperProps) {
    switch (submissionState) {
        case 'not-submitted':
            return pageSize.y > 800 ? <MonoPartAlertCreator {...stepperProps} /> : <MultiPartAlertCreator {...stepperProps} />;
        case 'submitting':
            return getInsideAsText(t.common.loading);
        case 'success-validation-needed':
            return getInsideAsText(t.search.alert.emailValidation.needed);
        case 'success-validation-not-needed':
            return getInsideAsText(t.search.alert.emailValidation.notNeeded);
    }

}

const AlertCreator: React.FC<Props> = (props: Props) => {
    const t = useSimpleTranslation();
    const {pageSize} = useResponsive();
    const [posOver, setPosOver] = React.useState<number>(-1);
    const [submissionState, setSubmissionState] = useStateHotReloadable<SubmissionState>('not-submitted', 'alert-submission');
    const [email, setEmail] = useStateHotReloadable<string>('', 'alert-email');
    const [order, setOrder] = React.useState<AnswerOrLimit[] | undefined>(undefined);
    const [init, setInit] = React.useState<ServerAlertCreationInit | undefined>(undefined);
    const history = props.history;
    const dropOutside = useDrop({
        accept: DND_TYPE,
        hover: (item, monitor) => {
            if (monitor.isOver({shallow: true})) {
                setPosOver(-1);
            }
        }
      })[1];
    const stepperProps: StepperProps = {
        history,
        posOver,
        setPosOver,
        dropOutside,
        sessionId: props.match.params.sid,
        submissionState,
        setSubmissionState,
        email,
        setEmail,
        order,
        setOrder,
        init,
        setInit,
    }

    const errorPopupClass = submissionState === 'error' ? 'alert-error-visible' : 'alert-error-hidden';

    return <div className="alert-creator" ref={dropOutside} style={{height: pageSize.y}}>
        <h1>{t.search.alert.title}</h1>
        {getInside(submissionState, pageSize, t, stepperProps)}
        <div className={`alert-error ${errorPopupClass}`}>{t.common.errors.genericUserError}</div>
    </div>;
}

export default AlertCreator;