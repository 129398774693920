import * as React from 'react';

import "./Card.scss";
import { Point } from '../../util/geometry';
import { Idea, NO_IMAGE, isSame, shortenIdeaName } from '../../model/idea';
import QuestionMark from './QuestionMark';
import { LocaleContent } from '../../i18n/locales/types';
import { useSimpleTranslation } from '../hooks/Translator';
import LargeIdeaImage from './LargeIdeaImage';
import querier from '../../server/querier';
import {Verso, RectoTextBackground, RectoImageBackground} from './CardBackground';

export type IdeaInCard = {
    idea: Idea,
    asAQuestion: boolean,
    onClick: () => void,
}

export type CardContent = string | IdeaInCard | undefined;

type Props = {
    recto: CardContent,
    showVerso: boolean,
    size: Point,
}

export const sizes = {
    shadowMargin: 15, // The margin we leave around the image to draw the shadow.
    borderMargin: 10, // The margin between the card border and the print border.
    circleMargin: 15, // The margin between the circles center and the card border.
    borderRadius:  6, // The radius of the print border. The outer physical card radius is based on this one.
    circleRadius: 15, // The radius of the small circles used as decoration around text content.
    imageMargin:  5, // The margin between the image as content and the card border. There is no print margin around images.
    ideaTextBelowHeight: 80, // The height of the text below an idea image in a card (as a question false)
}
const outerRadius = sizes.borderRadius + sizes.borderMargin;

const contentPadding = sizes.shadowMargin + sizes.borderMargin * 2;

export function isQuestion(content: CardContent): content is string {
    return typeof content === 'string';
}

export function isIdea(content: CardContent): content is IdeaInCard {
    return content !== undefined && (content as IdeaInCard).idea !== undefined;
}

export function isSameContent(l: CardContent, r: CardContent) {
    if (l === undefined && r === undefined) {
        return true;
    }
    if (l === undefined || r === undefined) {
        return false;
    }
    if (isQuestion(l) && isQuestion(r)) {
        return l === r;
    }
    if (isIdea(l) && isIdea(r)) {
        return isSame(l.idea.key, r.idea.key);
    }
    return false;
}

function isTextIdea(idea: Idea) {
    return idea.image === NO_IMAGE;
}

export function renderFoundIdea(
    t: LocaleContent,
    imgParentSize: Point,
    onClickToIdea: () => void,
    idea: Idea,
    ) {
    if (isTextIdea(idea)) {
        return <div className="found-idea-content found-idea-text-content centered-full">
            <p>{idea.content}</p>
        </div>
    } else {
        const imageBorderRadius = outerRadius - sizes.imageMargin;
        return <div className="found-idea-content clickable" onClick={() => onClickToIdea()}>
            <LargeIdeaImage src={`${querier.httpUrl}/img/${idea.image}`} maxWidth={imgParentSize[0]} maxHeight={imgParentSize[1] - sizes.ideaTextBelowHeight} style={{borderTopLeftRadius: imageBorderRadius, borderTopRightRadius: imageBorderRadius}}/>
            <div style={{flex: 1}} className="centered-full">
                <div className="found-idea-title">{shortenIdeaName(idea.title)}</div>
                <div className="found-idea-link-button">{t.search.foundIdea.linkButtonLabel}</div>
            </div>
        </div>
    }
}

function renderContent(t: LocaleContent, content: CardContent, cardSize: Point) {
    if (content === undefined) {
        return null;
    }
    if (isQuestion(content)) {
        return <div className="card-text" style={{paddingLeft: contentPadding, paddingRight: contentPadding}}>
            <span><span>{content}</span><QuestionMark/></span>
        </div>;
    } else {
        const {idea, onClick} = content;
        const padding = sizes.shadowMargin + (isTextIdea(content.idea) ? sizes.borderMargin * 2 : sizes.imageMargin);
        const imageParentSize: Point = [cardSize[0] - 2 * padding, cardSize[1] - 2 * padding];
        return <div className="card-idea" style={{paddingTop: padding, paddingLeft: padding, paddingRight: padding}}>
            {renderFoundIdea(t, imageParentSize, onClick, idea)}
        </div>;
    }
}

const Card : React.FC<Props> = ({recto, size, showVerso}) => {
    const [width, height] = size;
    const t = useSimpleTranslation();
    return <div className="card">
            <Verso cardWidth={width} cardHeight={height} visible={showVerso}/>
            <RectoTextBackground cardWidth={width} cardHeight={height} visible={!showVerso && isQuestion(recto)}/>
            <RectoImageBackground cardWidth={width} cardHeight={height} visible={!showVerso && isIdea(recto)}/>
            {renderContent(t, recto, size)}
        </div>
}

export default React.memo(Card, (prev, curr) => isSameContent(prev.recto, curr.recto) && prev.size[0] === curr.size[0] && prev.size[1] === curr.size[1] && prev.showVerso === curr.showVerso);