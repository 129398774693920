import { ServerContributor } from "./user";
import { ServerReactionCriteria, ServerWeightsCriteria } from "./reaction";
import { i32, i64 } from "./rust";

export const GENERIC_MERCHANT_ID = 0;
export const GIVEN_UP_IDEA_ID = 'given-up';
export const NO_IMAGE = '';

export type IdeaKey = { // Same on server.
    readonly merchant_id: number,
    readonly id_within_merchant: string,
}

export type Idea = { // UserFacingIdea on server.
    readonly key: IdeaKey,
    readonly title: string,
    readonly content: string,
    readonly image: string,
    readonly description: string,
    readonly available: boolean,
}

export type FullContribIdea = Idea & {
    readonly price_min_cents: number,
    readonly price_max_cents: number,
    readonly rating: number,
    readonly age_min: number,
    readonly age_max: number,
    readonly available: boolean,
}

export type ContribGenericIdea = {
    readonly content: string,
    readonly priceMinCents: number,
    readonly priceMaxCents: number,
    readonly ageMin: number,
    readonly ageMax: number,
}

export type ServerContribGenericIdea = {
    readonly content: string,
    readonly price_min_cents: number,
    readonly price_max_cents: number,
    readonly age_min: number,
    readonly age_max: number,
}

export type ServerContribMerchantIdea = {
    readonly url: string,
}

export type ServerChangeIdeaAge = {
    id: IdeaKey,
    age_min: number,
    age_max: number,
}

export type ServerChangeIdeaRating = {
    id: IdeaKey,
    rating: number
}

export const isSame = (key1: IdeaKey, key2: IdeaKey): boolean => {
    return key1.merchant_id === key2.merchant_id && key1.id_within_merchant === key2.id_within_merchant;
};

export const titleComparator = (l: Idea,r: Idea) => {
    const titleOrder = l.title.localeCompare(r.title);
    if (titleOrder !== 0) {
        return titleOrder;
    }
    const merchantOrder = l.key.merchant_id - r.key.merchant_id;
    if (merchantOrder !== 0) {
        return merchantOrder;
    }
    return l.key.id_within_merchant.localeCompare(r.key.id_within_merchant);
};

export const sortIdeasByTitle = function(ideas: Idea[]) {
    ideas.sort(titleComparator);
}

// Toggles an idea key inside an array.
// Adds it if non included and removes it if included.
export const toggleIdeaKey = (a: IdeaKey[], v: IdeaKey): IdeaKey[] => {
    const contains = a.find(t => isSame(v, t));
    if (contains) {
        return a.filter(t => !isSame(v, t));
    } else {
        return a.concat([v]);
    }
}

// Creates a string representing this key to store in Set or Maps so that hash and equals
// work. The merchant ids are numbers so we are guaranteed they dont contain : and thus
// this function is injective.
export type HashedIdeaKey = string;
export const toHash = (k: IdeaKey): HashedIdeaKey => `${k.merchant_id}:${k.id_within_merchant}`;
export const fromHash = (hash: HashedIdeaKey): IdeaKey | undefined => {
    const sepPos = hash.indexOf(':');
    if (sepPos === -1) {
        return undefined;
    }
    const merchantId = Number.parseInt(hash.substring(0, sepPos), 10);
    if (Number.isNaN(merchantId)) {
        return undefined;
    }
    return {
        merchant_id: merchantId,
        id_within_merchant: hash.substr(sepPos + 1)
    }
};


export type ServerIdeaRelation = {
    readonly id: i32,
    readonly subject: Idea,
    readonly target: Idea,
    readonly relation: IdeaRelation,
}

export type ServerSuggestionBase = {
    readonly idea: Idea,
    readonly weight: number,
}

export type ModerationContribGenericIdea = {
    readonly id: number,
    readonly contributor: ServerContributor,
    readonly content: string,
    readonly price_min_cents: number,
    readonly price_max_cents: number,
    readonly age_min: number,
    readonly age_max: number,
}

export type ModerationContribMerchantIdea = {
    readonly id: number,
    readonly contributor: ServerContributor,
    readonly url: string
}

export type InsertableMerchantIdea = {
    readonly merchant: string,
    readonly id: string,
    readonly title: string,
    readonly image: string,
    readonly description: string,
    readonly url: string,
    readonly price_min_cents: number,
    readonly price_max_cents: number,
    readonly age_min: number,
    readonly age_max: number,
}

// The type of feed providing the ideas to enrich
// lcr Least Contrib Reactions
// wf  Worst Feedback
// nrr NoRequiredReaction
// trr Triage ordered by rating
// trt Triage ordered by title
// trta Triage ordered by title, affiliated first
export type IdeasFeedType = 'lcr' | 'wf' | 'nrr' | 'trr' | 'trt' | 'trta';

// Warning this type is used as string in some places (AddRelation for instance). If one day we add object types we will need to add serialization.
export type IdeaRelation = 'Identical' | 'OnlySizeDifference' | 'OnlyQualityDifference' | 'CouldBeDistinguishedLater' | 'OnlyColorDifference' | 'Distinct' | 'SameSpectacle';
export const allIdeasRelations: IdeaRelation[] = ['Identical', 'OnlySizeDifference', 'OnlyQualityDifference', 'CouldBeDistinguishedLater', 'OnlyColorDifference', 'Distinct', 'SameSpectacle'];

export type IdeaRelationMap = {[IR in IdeaRelation] : string};

export type ServerAddIdeasRelations = {
    readonly ideas: IdeaKey[],
    readonly relation: IdeaRelation,
    readonly update_reactions: boolean,
}

export type ServerRelationIdeaSearchCriteria = 'None' | 'Any' | { OfType: {r: IdeaRelation, only_subject: boolean}}

export type MerchantIdeaSearchCriteria = {Merchant: number};
export type IdIdeaSearchCriteria = {Id: string};
export type AvailableIdeaSearchCriteria = {Available: boolean};
export type RatingIdeaSearchCriteria = {Rating: [number, number]};
export type PriceIdeaSearchCriteria = {Price: [number, number]};
export type TextIdeaSearchCriteria = {Text: [string, boolean, boolean]};
export type HasReactionIdeaSearchCriteria = {HasReaction: [number, ServerReactionCriteria]};
export type ReactionsCountIdeaSearchCriteria = {ReactionsCount: [number, number]};
export type HasWeightsIdeaSearchCriteria = {HasWeights: [number, ServerWeightsCriteria]};
export type DoesntHaveNeededWeightIdeaSearchCriteria = 'DoesntHaveNeededWeight';
export type AffiliatedIdeaSearchCriteria = {Affiliated: boolean};
export type AddedLastNSecondsIdeaSearchCriteria = {AddedLastNSeconds: [i64, boolean]};
export type HasCloseIdeaIdeaSearchCriteria = {HasCloseIdea: {distance: i64, count_min: i64, count_max: i64, pre_filter: string}};
export type RelationIdeaSearchCriteria = {Relation: ServerRelationIdeaSearchCriteria}
export type AppearedInSessionsIdeaSearchCriteria = {AppearedInSessions: {days: i64, sessions: i64}};

export type IdeaSearchCriteria = MerchantIdeaSearchCriteria
| IdIdeaSearchCriteria
| AvailableIdeaSearchCriteria
| RatingIdeaSearchCriteria
| PriceIdeaSearchCriteria
| TextIdeaSearchCriteria
| HasReactionIdeaSearchCriteria
| ReactionsCountIdeaSearchCriteria
| HasWeightsIdeaSearchCriteria
| DoesntHaveNeededWeightIdeaSearchCriteria
| AffiliatedIdeaSearchCriteria
| AddedLastNSecondsIdeaSearchCriteria
| HasCloseIdeaIdeaSearchCriteria
| RelationIdeaSearchCriteria
| AppearedInSessionsIdeaSearchCriteria

export function isTextIdeaSearchCriteria(c: IdeaSearchCriteria): c is TextIdeaSearchCriteria {
    return (c as TextIdeaSearchCriteria).Text !== undefined;
}

export function isAvailabilityIdeaSearchCriteria(c: IdeaSearchCriteria): c is AvailableIdeaSearchCriteria {
    return (c as AvailableIdeaSearchCriteria).Available !== undefined;
}

export function isReactionIdeaSearchCriteria(c: IdeaSearchCriteria): c is HasReactionIdeaSearchCriteria {
    return (c as HasReactionIdeaSearchCriteria).HasReaction !== undefined;
}

export function isRatingIdeaSearchCriteria(c: IdeaSearchCriteria): c is RatingIdeaSearchCriteria {
    return (c as RatingIdeaSearchCriteria).Rating !== undefined;
}

export function isPriceIdeaSearchCriteria(c: IdeaSearchCriteria): c is PriceIdeaSearchCriteria {
    return (c as PriceIdeaSearchCriteria).Price !== undefined;
}

export function isSameType(c1: IdeaSearchCriteria, c2: IdeaSearchCriteria) {
    return Object.keys(c1)[0] === Object.keys(c2)[0];
}

export function isReactionsCountIdeaSearchCriteria(c: IdeaSearchCriteria): c is ReactionsCountIdeaSearchCriteria {
    return (c as ReactionsCountIdeaSearchCriteria).ReactionsCount !== undefined;
}

export function isMerchantIdeaSearchCriteria(c: IdeaSearchCriteria): c is MerchantIdeaSearchCriteria {
    return (c as MerchantIdeaSearchCriteria).Merchant !== undefined;
}

export function isIdIdeaSearchCriteria(c: IdeaSearchCriteria): c is IdIdeaSearchCriteria {
    return (c as IdIdeaSearchCriteria).Id !== undefined;
}

export function isWeightsIdeaSearchCriteria(c: IdeaSearchCriteria): c is HasWeightsIdeaSearchCriteria {
    return (c as HasWeightsIdeaSearchCriteria).HasWeights !== undefined;
}

export function isAffiliatedIdeaSearchCriteria(c: IdeaSearchCriteria): c is AffiliatedIdeaSearchCriteria {
    return (c as AffiliatedIdeaSearchCriteria).Affiliated !== undefined;
}

export function isDoesntHaveNeededWeightIdeaSearchCriteria(c: IdeaSearchCriteria): c is DoesntHaveNeededWeightIdeaSearchCriteria {
    return c === 'DoesntHaveNeededWeight';
}

export function isAddedLastNSecondsIdeaSearchCriteria(c: IdeaSearchCriteria): c is AddedLastNSecondsIdeaSearchCriteria {
    return (c as AddedLastNSecondsIdeaSearchCriteria).AddedLastNSeconds !== undefined;
}

export function isHasCloseIdeaIdeaSearchCriteria(c: IdeaSearchCriteria): c is HasCloseIdeaIdeaSearchCriteria {
    return (c as HasCloseIdeaIdeaSearchCriteria).HasCloseIdea !== undefined;
}

export function isRelationIdeaSearchCriteria(c: IdeaSearchCriteria): c is RelationIdeaSearchCriteria {
    return (c as RelationIdeaSearchCriteria).Relation !== undefined;
}

export function isAppearedInSessionsIdeaSearchCriteria(c: IdeaSearchCriteria): c is AppearedInSessionsIdeaSearchCriteria {
    return (c as AppearedInSessionsIdeaSearchCriteria).AppearedInSessions !== undefined;
}

const removedSuffixes = ['- Les Raffineurs', '| Cadeau Maestro', '- Achat Accessoires de Sport'];

export function shortenIdeaName(original: string): string {
    let simplified = original;
    for (let suffix of removedSuffixes) {
        if (simplified.endsWith(suffix)) {
            simplified = simplified.substring(0, simplified.length - suffix.length).trim();
        }
    }
    return simplified.trim();
}