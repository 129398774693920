import { StoreState } from "../redux/store";
import { MapMap } from "./collections";

const STATE_KEY = 'redux-state';

const MAP_INDICATOR = '$$MAP$$';
const MAP_MAP_INDICATOR = '$$MAPMAP$$';
const SET_INDICATOR = '$$SET$$';
const isMap = (o: any): boolean => Object.prototype.toString.call(o) === '[object Map]';
const isMapMap = (o: any): boolean => o && o.constructor && o.constructor.name === 'MapMap';
const isSet = (o: any): boolean => Object.prototype.toString.call(o) === '[object Set]';

// Based on http://2ality.com/2015/08/es6-map-json.html

// Exported for tests
export const replacer = (key: string, o: any): any => {
    if (isMap(o)) {
        return {[MAP_INDICATOR]: true, content: [...o]};
    }
    if (isMapMap(o)) {
        return {[MAP_MAP_INDICATOR]: true, content: o.toContent()};
    }
    if (isSet(o)) {
        return {[SET_INDICATOR]: true, content: Array.from(o)};
    }
    return o;
};

// Exported for tests
export const reviver = (key: string, o: any): any => {
    if (o === null || o === undefined) {
        return o;
    }
    if (o[MAP_INDICATOR] === true) {
        return new Map(o.content);
    }
    if (o[MAP_MAP_INDICATOR] === true) {
        return MapMap.fromContent(o.content);
    }
    if (o[SET_INDICATOR] === true) {
        return new Set(o.content);
    }
    return o;
};

// State helpers

export const loadState = (expectedVersion: string) => {
    try {
        const serializedState = sessionStorage.getItem(STATE_KEY);
        if (serializedState === null) {
            return undefined;
        }
        const parsed = JSON.parse(serializedState, reviver);
        if (parsed.version !== expectedVersion) {
            return undefined;
        }
        // Delete the version information before passing back to redux
        delete parsed.version;
        return parsed;
    } catch (err) {
        console.error("Could not restore state.", err);
        return undefined;
    }
};

export const saveState = (state: StoreState, version: string) => {
    try {
        const serializedState = JSON.stringify({...state, version}, replacer);
        sessionStorage.setItem(STATE_KEY, serializedState);
    } catch (err) {
        console.error("Could not save state.", err);
        return;
    }
}

// Hooks helpers

export function load<T>(key: string): T | undefined {
    const serializedState = sessionStorage.getItem(key);
    try {
        if (serializedState === null) {
            return undefined;
        }
        // JSON.parse('undefined') throws instead of returning undefined
        if (serializedState === 'undefined') {
            return undefined;
        }
        return JSON.parse(serializedState, reviver);
    } catch (err) {
        console.error("Could not restore state.", err, serializedState);
        return undefined;
    }
};

export function save(v: any, key: string) {
    try {
        sessionStorage.setItem(key, JSON.stringify(v, replacer));
    } catch (err) {
        console.error("Could not save state.", err);
        return;
    }
}