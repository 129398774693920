import * as React from 'react';
import {Link} from 'react-router-dom';

import querier from '../../server/querier';
import { AccountType } from '../../model/user';
import routes from '../../routes';

import './Badge.scss';
import exit from '../../images/exit.svg';
import star from '../../images/star.svg';
import search from '../../images/search.svg';
import store from '../../redux/store';
import { CLEAR } from '../../redux/actions';
import Refresh from '../base/Refresh';
import { useAccount } from '../hooks/Account';
import { useSimpleTranslation } from '../hooks/Translator';

async function onClickSignOut(logOut: () => void) {
    const logoutResult = await querier.query('account/signout');
    if (logoutResult === null) {
        return;
    }
    logOut();
}

// Event Handling

function onResetReduxState() {
    store.dispatch({type: CLEAR});
    sessionStorage.clear();
}

const BadgeDecoration: React.FC<{}> = () => {
    const {accountInfo} = useAccount();

    switch (accountInfo.type) {
        case AccountType.Admin: return <span>
            <Link style={{color: 'black'}} to={routes.contrib.ideas.search.noCriteria}><img src={search} title="Search" alt="Search"/></Link>
            <Refresh onClick={onResetReduxState}/>
            <Link to={routes.admin.home} className="account-type-link">
                <img src={star} title="star1" alt="star"/>
                <img src={star} title="star2" alt="star"/>
            </Link>
        </span>;
        case AccountType.Moderator: return <Link to={routes.moderation.home} className="account-type-link">
            <img src={star} title="star2" alt="star"/>
        </Link>;
        default: return null
    }
}

const Badge: React.FC<{}> = () => {
    const {accountInfo, logOut} = useAccount();
    const t = useSimpleTranslation();

    if (accountInfo.email === '') {
        return <div id="account-line">
            <Link to={routes.account.signin}>{t.home.logInLabel}</Link>
            &nbsp;•&nbsp;
            <Link to={routes.account.signup}>{t.home.createAccountLabel}</Link>
        </div>
    } else {
        const exitLabel = t.account.exitLabel;
        return <div id="account-line">
            <BadgeDecoration />
            <span>{accountInfo.email.split('@')[0]}</span>
            <img id="account-logout" className="clickable" src={exit} alt={exitLabel} title={exitLabel} onClick={() => onClickSignOut(logOut)}/>
        </div>;
    }
}

export default Badge;