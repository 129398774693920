import * as React from 'react';
import { SharePayload } from '../../../redux/actions/share';

const NativeShare: React.FC<{payload: SharePayload}> = ({payload, children}) => {
    const [canShare, setCanShare] = React.useState<boolean>(false);
    const untypedNavigator = navigator as any; // navigator.share is unknown to TS
    React.useEffect(() => {
        const newCanShare = untypedNavigator.share !== undefined;
        if (newCanShare !== canShare) {
            setCanShare(newCanShare);
        }
        // untypedNavigator.share cannot change so we don't need it as dependency, and we don't rely on canShare even if we read it.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!canShare) {
        // Hide this component if native sharing is not enabled
        return null;
    }
    return <div onClick={() => untypedNavigator.share(payload)}>{children}</div>;
};

export default NativeShare;