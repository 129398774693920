import * as React from 'react';
import {Redirect} from 'react-router';
import { RouteComponentProps } from 'react-router';

import wrapI18N, { PropsFromI18N } from '../../i18n/TranslatorHOC';
import { resumeOrStartFlow, OutputState, SearchFlow, SetSearchState } from '../../server/SearchFlow';
import { SearchState, StoreState } from '../../redux/store';
import { connect } from 'react-redux';
import {dxThresholdAnswer} from './AnswerPicker';
import { AnswerOrFeedbackValue } from '../../model/answer';
import {onClickToIdea} from './FoundIdeaBelowDeck';
import { uppercaseFirstLetter } from '../../util/strings';

import './Home.scss';
import routes from '../../routes';
import { GENERIC_MERCHANT_ID, GIVEN_UP_IDEA_ID } from '../../model/idea';
import wrapResponsive, { PropsFromResponsive } from '../ResponsiveHOC';
import { format } from '../Question';
import Deck, {Animation as DeckAnimation, slow} from './Deck';
import { CardContent, isIdea } from './Card';
import { animateEaseOut } from '../AnimationHOC';
import FoundIdeaBelowDeck from './FoundIdeaBelowDeck';
import { sizes, getLayout } from './Layout';
import IdeasHighlighterGuard from './IdeasHighlighter';
import AnswerPickerGuard from './AnswerPicker';
import GivenUp from './GivenUp';
import DebugInfoGuard from './DebugInfoGuard';
import { coalesce } from '../../util/common';
import { createEnvironment, extractMetadataFromLocation } from './common';

type PropsFromState = {
    readonly search: SearchState
}

type PropsFromParent = RouteComponentProps<{}>

type Props = PropsFromI18N & PropsFromState & PropsFromParent & PropsFromResponsive

type InternalState = {
    touchStartX: number | undefined,
    touchCurrentX: number | undefined,
    deckAnimation: DeckAnimation | undefined,
    makeSpaceForActions: boolean,
    directionHighlightTransition: number | undefined,
}

type State = OutputState & InternalState;


type SetDirectionHighlightTransitionFunction = (dht: number | undefined) => void;

const directionHighlightAnimationDuration = 1000;
const fullSwipeDuration = 600 * slow;

function directionHighlightTick(f: SetDirectionHighlightTransitionFunction, start: number, sign: number, halfPageWidth: number) {
    const now = Date.now();
    if (now > start + directionHighlightAnimationDuration) {
        f(undefined);
    } else {
        const t = (now - start) / directionHighlightAnimationDuration;
        const v = animateEaseOut(t, 0, 1, halfPageWidth * sign, 0);
        f(v);
        requestAnimationFrame(() => directionHighlightTick(f, start, sign, halfPageWidth));
    }
}

class Home extends React.Component<Props, State> {

    private flow? : SearchFlow;

    // Business Logic

    animateHideAnswered(halfPageWidth: number) {
        this.setState({deckAnimation: {effect: 'swipe', duration: 400, startDx: 0, toLeft: false, contentBelow: undefined, halfPageWidth}});
    }

    private getTouchDx() {
        return this.state.touchCurrentX === undefined || this.state.touchStartX === undefined ? 0 : this.state.touchCurrentX - this.state.touchStartX;
    }

    private getDeckContent(): CardContent {
        const flow = this.flow;
        let {idea} = this.state;
        let ideaToRender = idea;
        let actOnClick = true;
        if (flow !== undefined) {
            if (this.state.hasGivenUp) {
                ideaToRender =  {
                    key: {merchant_id: GENERIC_MERCHANT_ID, id_within_merchant: GIVEN_UP_IDEA_ID},
                    content: this.props.translate(l => l.search.givenUpContent),
                    description: '',
                    image: '',
                    title: '',
                    available: true,
                };
                actOnClick = false;
            }
            if (ideaToRender !== undefined) {
                let definedIdea = ideaToRender;
                let onClickToRenderedIdea = actOnClick ? () => onClickToIdea(flow, definedIdea, this.state.ideaAsAQuestion) : () => {};
                return {
                    idea: ideaToRender,
                    asAQuestion: this.state.ideaAsAQuestion,
                    onClick: onClickToRenderedIdea,
                }
            }
            if (this.state.question !== undefined) {
                const questionText = uppercaseFirstLetter(format(this.state.question.content, this.props.getShortcuts(), this.props.search.gender, 'interrogative'));
                return questionText;
            }
            if (this.state.loading) {
                return undefined;
            }
        }
    }

    startDirectionHighlightEaseAnimation(sign: number, halfPageWidth: number) {
        const start = Date.now();
        requestAnimationFrame(() => directionHighlightTick(v => this.setState({directionHighlightTransition: v}), start, sign, halfPageWidth));
    }


    // LifeCycle

    constructor(props: Props) {
        super(props);
        this.flow = undefined;
        this.state = {
            hasFoundGoodIdea: false,
            needsRestart: false,
            hasGivenUp: false,
            loading: true,
            touchStartX: undefined,
            touchCurrentX: undefined,
            ideaAsAQuestion: false,
            deckAnimation: undefined,
            makeSpaceForActions: false,
            directionHighlightTransition: undefined,
            ideasHighlight: [],
            features: [],
        };
        this.onPickAnswer = this.onPickAnswer.bind(this);
        this.onTouchCancel = this.onTouchCancel.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
    }

    public async componentDidMount() {
        const {search, pageSize, dpi} = this.props;
        const flow = await resumeOrStartFlow(
            state => this.setState(current => ({...current, ...state})), {
            age: search.age,
            gender: search.gender,
            price_max_cents: Math.floor(search.priceMaxCents),
            price_min_cents: Math.floor(search.priceMinCents),
            from_location: extractMetadataFromLocation(),
            hints: search.hints,
            env: createEnvironment(pageSize, dpi),
        });
        flow.getCurrentStep();
        this.flow = flow;
    }

    public componentWillUnmount() {
        this.flow = undefined;
    }

    // Event Handling

    private onPickAnswer(a: AnswerOrFeedbackValue, startDx: number, halfPageWidth: number) {
        let shouldUpdateState = false;
        if (this.flow !== undefined && this.state.question !== undefined) {
            shouldUpdateState = this.flow.onPickAnswer(this.state.question.id, a);
        }
        if (!shouldUpdateState) {
            return;
        }
        const toLeft = a === 'No' || a === 'ProbablyNo';
        this.setState({touchCurrentX: undefined, touchStartX: undefined, deckAnimation: {duration: fullSwipeDuration, toLeft, effect: 'swipe', startDx, halfPageWidth, contentBelow: undefined}});
    }

    private isTouchAllowed() {
        if (this.state.loading) {
            return false;
        }
        const content = this.getDeckContent();
        if (isIdea(content) && !content.asAQuestion) {
            return false;
        }
        return true;
    }

    private onTouchStart(ev: React.TouchEvent<HTMLDivElement>) {
        const x = ev.touches.item(0).screenX;
        if (this.isTouchAllowed()) {
            this.setState({touchStartX: x});
        }
    }

    private onTouchMove(ev: React.TouchEvent<HTMLDivElement>) {
        const x = ev.touches.item(0).screenX;
        this.setState({touchCurrentX: x});
    }

    private onTouchEnd(ev: React.TouchEvent<HTMLDivElement>, halfPageWidth: number) {
        let cancelledTouch = false;
        const d = this.getTouchDx();
        // Any of them can be undefined if the loading state changes while loading.
        if (this.state.touchCurrentX !== undefined && this.state.touchStartX !== undefined) {
            if (Math.abs(d) > dxThresholdAnswer) {
                cancelledTouch = true;
                this.onPickAnswer(d > 0 ? 'Yes' : 'No', d, halfPageWidth);
                this.startDirectionHighlightEaseAnimation(Math.sign(d), halfPageWidth);
            }
        }
        if (!cancelledTouch) {
            this.onTouchCancel();
        }
    }

    private onTouchCancel() {
        // Trigger a cancel animation only if a swipe was ongoing. This callback is called (via onTouchEnd) also when
        // the user clicks on the button. We don't want to trigger a 'cancel' animation in this case.
        let deckAnimation: DeckAnimation | undefined = undefined;
        if (this.state.touchCurrentX !== undefined) {
            deckAnimation = {duration: 400, effect: 'cancel', startDx: this.getTouchDx()};
        }
        this.setState({touchCurrentX: undefined, touchStartX: undefined, deckAnimation});
    }

    // Rendering

    private renderFoundIdeaControls(content: CardContent, halfPageWidth: number) {
        if (this.flow === undefined || !isIdea(content) || this.state.idea === undefined) {
            return null;
        }
        return <FoundIdeaBelowDeck
        idea={this.state.idea}
        flow={this.flow}
        asQuestion={this.state.ideaAsAQuestion}
        isAcceptedByUser={this.state.hasFoundGoodIdea}
        animateHideAnswered={() => this.animateHideAnswered(halfPageWidth)}
        searchSex={this.props.search.gender}
        history={this.props.history}
        onRequiresLargeHeight={largeHeight => this.setState({makeSpaceForActions: largeHeight})}/>;
    }

    private getAnswerPickerDirectionalHighlight(): number | undefined {
        if (this.state.directionHighlightTransition !== undefined) {
            return this.state.directionHighlightTransition;
        }
        if (this.state.touchCurrentX === undefined || this.state.touchStartX === undefined) {
            return undefined;
        }
        return this.state.touchCurrentX - this.state.touchStartX;
    }

    public render() {
        const halfPageWidth = this.props.pageSize.x / 2;
        const layout = getLayout(this.props.pageSize, this.props.dpi, this.state.makeSpaceForActions);
        const largeClassName = this.state.makeSpaceForActions ? 'search-home-large' : '';
        const content = this.getDeckContent();
        const updateState: SetSearchState = state => this.setState(current => ({...current, ...state}));
        const onPickAnswer = (v:AnswerOrFeedbackValue) => this.onPickAnswer(v, 0, halfPageWidth);
        if (this.state.needsRestart) {
            return <Redirect to={routes.home}/>
        }
        return <div className="search-home" data-question-id={coalesce(this.state.question?.id, -1)} data-merchant-id={this.state.idea?.key.merchant_id} data-idea-url={this.state.idea?.content}>
            <div className={`search-center ${largeClassName}`}
                style={{height: layout.main[1], width: layout.main[0]}}
                onTouchStart={this.onTouchStart}
                onTouchCancel={this.onTouchCancel}
                onTouchEnd={e => this.onTouchEnd(e, halfPageWidth)}
                onTouchMove={this.onTouchMove}>
                <Deck
                    content={content}
                    animation={this.state.deckAnimation}
                    touchStartX={this.state.touchStartX}
                    touchCurrentX={this.state.touchCurrentX}
                    onAnimationEnd={() => this.setState({deckAnimation: undefined})}
                    />
                <div className="centered-column search-below-deck" style={{marginTop: -sizes.overflowBottom3d}}>
                    {this.renderFoundIdeaControls(content, halfPageWidth)}
                    <AnswerPickerGuard content={content} directionalHighlight={this.getAnswerPickerDirectionalHighlight()} onPickAnswer={onPickAnswer} question={this.state.question} loading={this.state.loading} />
                    <IdeasHighlighterGuard layout={layout} centerContent={content} flow={this.flow} ideas={this.state.ideasHighlight} />
                    <GivenUp hasGivenUp={this.state.hasGivenUp} />
                </div>
            </div>
            <DebugInfoGuard history={this.props.history} updateState={updateState} debugInfo={this.state.debugInfo} flow={this.flow}/>
        </div>
    }
}

const mapStateToProps = (state: StoreState) => ({
    search: state.search
})

export default connect(mapStateToProps, null)(wrapResponsive(wrapI18N(Home)));