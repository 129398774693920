// A Map of Map, simplifying insertion.
export class MapMap<K1, K2, V> {

    private m: Map<K1, Map<K2, V>>;

    constructor(original?: MapMap<K1, K2, V>) {
        if (original !== undefined) {
            this.m = new Map(original.m);
        } else {
            this.m = new Map();
        }
    }

    public set(k1: K1, k2: K2, v: V) {
        let mm = this.m.get(k1);
        if (mm === undefined) {
            mm = new Map();
            this.m.set(k1, mm);
        }
        mm.set(k2, v);
    }

    public get(k1: K1): Map<K2, V> | undefined {
        return this.m.get(k1);
    }

    public get2(k1: K1, k2: K2): V | undefined {
        const mm = this.m.get(k1);
        if (mm === undefined) {
            return undefined;
        }
        return mm.get(k2);
    }

    // Serialization

    public toContent(): Map<K1, Map<K2, V>> {
        return this.m;
    }

    static fromContent<K1, K2, V>(c: Map<K1, Map<K2, V>>): MapMap<K1, K2, V> {
        const mapMap = new MapMap<K1, K2, V>();
        mapMap.m = c;
        return mapMap
    }
}

// http://2ality.com/2015/01/es6-set-operations.html
export function intersect<T>(l: Set<T>, r: Set<T>): Set<T> {
    return new Set([...l].filter(x => r.has(x)));
}