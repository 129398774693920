import photoBonCadeau from './images/pexels-mustafa-ezz-10976200.jpg'
import photoNoel2021 from './images/pexels-lisa-fotios-1666513.jpg'
import photoFemme202208 from './images/pexels-dziana-hasanbekava-6401660.jpg'
import photoCollegue202208 from './images/pexels-rodnae-productions-6518869.jpg'
import photoHomme202207 from './images/pexels-carlos-jairo-7989742.jpg'
import photoCagnottes202207 from './images/pexels-oleksandr-pidvalnyi-12969343.jpg'
import photoMariage202209 from './images/pexels-asad-photo-maldives-1024970.jpg'
import photoDiner202209 from './images/pexels-cottonbro-6554673.jpg'
import photoMeilleurAmi202210 from './images/pexels-armin-rimoldi-5554237.jpg'
import photoUnAn202210 from './images/pexels-budgeron-bach-6533172.jpg'
import photoNoelTop7202211 from './images/pexels-markus-spiske-298826.jpg'
import photoNoelFemme202211 from './images/pexels-olia-danilevich-5911444.jpg'
import photoNoelHomme202211 from './images/pexels-antoni-shkraba-6057075.jpg'
import photoNoelCouple202211 from './images/pexels-gustavo-fring-5522970.jpg'
import photoNoelParents202212 from './images/pexels-ivan-samkov-7910659.jpg'
import photoNoelPasCher202212 from './images/pexels-laura-james-6102398.jpg'
import photoNoelDemarquer202310 from './images/pexels-laura-james-6102100.jpg'
import photoNoelPapa202310 from './images/pexels-gustavo-fring-6050387.jpg'
import photoNoelMama202311 from './images/pexels-cottonbro-studio-6667034.jpg'
import photoNoelFrere202311 from './images/pexels-cottonbro-studio-6831709.jpg'
import photoNoelAmis202311 from './images/pexels-nicole-michalou-5774938.jpg'
import photoSecretSanta202311 from './images/pexels-rdne-stock-project-6519138.jpg'
import photoPereNoelSecret202312 from './images/pexels-tofroscom-257855.jpg'
import photoNoelCopine202312 from './images/pexels-thirdman-6533914.jpg'
import photoNoelSoeur202312 from './images/pexels-julia-larson-6113418.jpg'
import photoNoelNounou202312 from './images/pexels-antoni-shkraba-5493207.jpg'

import React, { lazy } from 'react'

type Article = {
    route: string,
    title: string,
    description: string,
    date: Date,
    photo: string,
    photoAlt: string,
    component: React.LazyExoticComponent<React.FC<{}>>,
}

export const bonCadeau: Article = {
    title: 'Comment offrir le bon cadeau',
    description: 'Le guide Nebuly pour offrir des cadeaux qui plaisent',
    date: new Date('2022-10-27'),
    photo: photoBonCadeau,
    photoAlt: 'Homme pensif regarde une ampoule',
    route: 'comment-offrir-le-bon-cadeau',
    component: lazy(() => import('./BonCadeau')),
};

export const noel2021: Article = {
    title: 'Noël 2021',
    description: 'Le guide Nebuly pour trouver des cadeaux ce Noël',
    date: new Date('2021-10-30'),
    photo: photoNoel2021,
    photoAlt: 'Paquet cadeau avec lumières en arrière-plan',
    route: 'guide-noel-2021',
    component: lazy(() => import('./2021Noel')),
};

export const cagnottes202207: Article = {
    title: 'Site de cagnotte en ligne\u00A0: le top 8',
    description: 'Vous souhaitez offrir un cadeau à plusieurs ? Découvrez les meilleurs sites de cagnotte en ligne pour que tout le monde puisse participer !',
    date: new Date('2022-07-09'),
    photo: photoCagnottes202207,
    photoAlt: 'Cagnotte dorée avec pièces',
    route: 'site-de-cagnotte-en-ligne-top-8',
    component: lazy(() => import('./202207Cagnottes')),
};

export const homme202207: Article = {
    title: 'Idée cadeau high-tech pour homme\u00A0: ne cherchez plus\u00A0!',
    description: 'Nous vous dévoilons les meilleures idées cadeaux high tech pour homme. Une certitude : il va adorer son nouveau joujou technologique !',
    date: new Date('2022-07-11'),
    photo: photoHomme202207,
    photoAlt: 'Gadgets noirs',
    route: 'idee-cadeau-high-tech-pour-homme-ne-cherchez-plus',
    component: lazy(() => import('./202207Homme')),
};

export const collegue202208: Article = {
    title: 'Idée cadeau collègue\u00A0: on vous aide à vous décider\u00A0!',
    description: 'Vous êtes en panne d\'idée cadeau pour votre collègue ? Pas de panique, Nebuly vous donne un coup de pouce. Objectif : trouver le cadeau parfait !',
    date: new Date('2022-08-05'),
    photo: photoCollegue202208,
    photoAlt: '"Collègue recevant un cadeau',
    route: 'idee-cadeau-collegue-on-vous-aide-a-vous-decider',
    component: lazy(() => import('./202208Collegue')),
};

export const femme202208: Article = {
    title: 'Déclarer son amour à sa femme\u00A0: 7 idées qu\'elle va adorer',
    description: 'Comment déclarer son amour à sa femme ? Nous vous aidons à faire de ce moment un souvenir inoubliable empli de romantisme.',
    date: new Date('2022-08-07'),
    photo: photoFemme202208,
    photoAlt: 'Dîner romantique dans le sable',
    route: 'déclarer-son-amour-a-sa-femme-7-idees-qu\'elle-va-adorer',
    component: lazy(() => import('./202208Femme')),
};

export const mariage202209: Article ={
    title: 'Quel cadeau pour un anniversaire de mariage\u00A0? 5 idées originales',
    description: 'Comment déclarer son amour à sa femme\u00A0? Nous vous aidons à faire de ce moment un souvenir inoubliable empli de romantisme.',
    date: new Date('2022-09-22'),
    photo: photoMariage202209,
    photoAlt: 'Couple à vélo s\'embrassant',
    route: 'quel-cadeau-pour-un-anniversaire-de-mariage-5-idees-originales',
    component: lazy(() => import('./202209AnniversaireMariage')),
};

export const diner202209: Article ={
    title: 'Quoi apporter quand on est invité\u00A0? On vous dit tout !',
    description: 'Vous êtes convié à un repas chez des amis ? Quoi offrir à ses hôtes quand on est invité ? Découvrez nos meilleurs conseils pour leur faire plaisir.',
    date: new Date('2022-09-26'),
    photo: photoDiner202209,
    photoAlt: 'Table diner 3 amis bougies',
    route: 'quoi-apporter-quand-on-est-invite-on-vous-dit-tout',
    component: lazy(() => import('./202209Diner')),
};

export const meilleurAmi202210: Article = {
    title: 'Offrir un cadeau à son meilleur ami\u00A0: 14 idées originales',
    description: 'Vous voulez offrir un cadeau à votre meilleur ami\u00A0? Découvrez nos meilleures idées pour lui faire plaisir à coup s\u00fbr.',
    date: new Date('2022-10-27'),
    photo: photoMeilleurAmi202210,
    photoAlt: 'Deux amis célèbrent un anniversaire',
    route: 'offrir-un-cadeau-a-son-meilleur-ami-14-idees-originales',
    component: lazy(() => import('./202210MeilleurAmi')),
};

export const coupleUnAn202210: Article = {
    title: 'Idée cadeau 1 an couple\u00A0: nos incontournables',
    description: 'Vous souhaitez faire plaisir à votre moitié en célébrant votre amour\u00A0? Découvrez nos idées cadeaux pour votre première année de votre couple.',
    date: new Date('2022-10-28'),
    photo: photoUnAn202210,
    photoAlt: 'Un couple regardant un cadeau',
    route: 'idee-cadeau-1-an-couple-nos-incontournables',
    component: lazy(() => import('./202210UnAn')),
};

export const noelTop7202211: Article = {
    title: 'Cadeaux de Noël 2022\u00A0: notre top 7',
    description: 'Besoin d\'inspiration pour vos cadeaux de Noël 2022\u00A0? Découvrez les meilleurs cadeaux à offrir pour surprendre vos proches\u00A0!',
    date: new Date('2022-11-05'),
    photo: photoNoelTop7202211,
    photoAlt: 'Des cadeaux empilés',
    route: 'cadeau-de-noel-2022-top-7',
    component: lazy(() => import('./202211NoelTop7')),
};

export const noelFemme202211: Article = {
    title: 'Cadeau de Noël femme\u00A0: 18 idées originales',
    description: 'Vous cherchez une idée cadeau de Noël pour une femme\u00A0? Découvrez notre sélection de cadeaux aux petits oignons\u00A0!',
    date: new Date('2022-11-04'),
    photo: photoNoelFemme202211,
    photoAlt: 'Une femme ouvrant ses cadeaux à Noël',
    route: 'cadeau-noel-femme-18-idees-originales',
    component: lazy(() => import('./202211NoelFemme')),
};

export const noelHomme202211: Article = {
    title: 'Cadeau de Noël homme\u00A0: 15 idées pour lui faire plaisir',
    description: 'Vous souhaitez offrir un cadeau de Noël à un homme ? Vous êtes à court d\'imagination\u00A0? Découvrez nos 15 idées pour le combler.',
    date: new Date('2022-11-10'),
    photo: photoNoelHomme202211,
    photoAlt: 'Une femme offre un cadeau a son partenaire devant un sapin',
    route: 'cadeau-noel-homme-15-idees-pour-lui-faire-plaisir',
    component: lazy(() => import('./202211NoelHomme')),
};

export const noelCouple202211: Article = {
    title: 'Quel cadeau pour un couple à Noël\u00A0? 15 idées originales',
    description: 'Quel cadeau offrir à un couple à Noël\u00A0? Nous vous présentons 15 idées pour faire plaisir à des amoureux et les combler de bonheur.',
    date: new Date('2022-11-09'),
    photo: photoNoelCouple202211,
    photoAlt: 'Un couple ouvrant un cadeau dans son salon',
    route: 'cadeau-noel-couple-15-idees-originales',
    component: lazy(() => import('./202211NoelCouple')),
};

export const noelParents202212: Article = {
    title: 'Idée cadeau de Noël pour parents\u00A0: 17 suggestions originales',
    description: 'Besoin d\'une idée cadeau pour vos parents à Noël ? Vous pouvez compter sur nous ! Découvrez notre sélection pour faire le bon choix',
    date: new Date('2022-12-02'),
    photo: photoNoelParents202212,
    photoAlt: 'Une mère recevant un cadeau',
    route: 'cadeau-noel-parents-17-suggestions-originales',
    component: lazy(() => import('./202212NoelParents'))
};

export const noelPasCher202212: Article = {
    title: 'Idée cadeau de Noël pas cher\u00A0: 16 inspirations à petits prix',
    description: 'À la recherche d\'une idée cadeau de Noël pas cher ? Découvrez notre sélection pour faire plaisir sans vous ruiner.',
    date: new Date('2022-12-03'),
    photo: photoNoelPasCher202212,
    photoAlt: 'Tas de cadeaux colorés',
    route: 'cadeau-noel-pas-cher-16-inspirations',
    component: lazy(() => import('./202212NoelPasCher'))
};

export const noelPapa202310: Article = {
    title: 'Cadeau de Noël Pour Papa\u00A0: Les 15 Meilleures Idées',
    description: 'Trouvez le cadeau de Noël  parfait pour votre papa avec notre sélection originale et adaptée à tous les budgets\u00A0!',
    date: new Date('2023-10-08'),
    photo: photoNoelPapa202310,
    photoAlt: 'Pere et fils souriants',
    route: 'cadeau-noel-papa-15-idees',
    component: lazy(() => import('./202310NoelPapa'))
};

export const noelUtile202310: Article = {
    title: 'Idée Cadeau de Noël Utile\u00A0: Démarquez-Vous Sous Le Sapin',
    description: 'Fini les cadeaux qui finissent au fond des placards\u00A0! Découvrez des idées de cadeaux de Noël vraiment utiles.',
    date: new Date('2023-10-09'),
    photo: photoNoelDemarquer202310,
    photoAlt: 'Pile de cadeaux sous le sapin',
    route: 'cadeau-noel-noel-utile-demarquez-vous-sous-le-sapin',
    component: lazy(() => import('./202310NoelUtile'))
};

export const noelAmis202311: Article = {
    title: ' Cadeau De Noël Entre Amis\u00A0: 16 Idées Pour Marquer Le Coup',
    description: 'À la recherche d\'un cadeau pour un Noël entre amis\u00A0? Découvrez nos inspirations les plus originales pour marquer le coup\u00A0!',
    date: new Date('2023-11-11'),
    photo: photoNoelAmis202311,
    photoAlt: '5 amis s\'échangent des cadeaux',
    route: 'cadeau-noel-entre-amis-16-idees-pour-marquer-le-coup',
    component: lazy(() => import('./202311NoelAmis'))
};

export const noelMaman202311: Article = {
    title: 'Cadeau De Noël Pour Maman\u00A0: 18 Idées Extraordinaires',
    description: 'Parce qu\'elle mérite le meilleur, explorez nos idées cadeaux de Noël pour votre maman. De quoi lui montrer tout votre amour !',
    date: new Date('2023-11-12'),
    photo: photoNoelMama202311,
    photoAlt: 'Une maman ouvre le cadeau offert par ses 2 filles',
    route: 'cadeau-noel-pour-mamam-18-idees-extraordinaires',
    component: lazy(() => import('./202311NoelMaman'))
};

export const noelFrere202311: Article = {
    title: 'Cadeau Frère Noël\u00A0: 15 Idées Inédites',
    description: 'Quel cadeau pour votre frère à Noël\u00A0? Ne cherchez plus et parcourez notre sélection de cadeaux insolites pour le surprendre à coup sûr.',
    date: new Date('2023-11-18'),
    photo: photoNoelFrere202311,
    photoAlt: 'Un homme ouvre ses cadeaux de Noël',
    route: 'cadeau-noel-frere-15-idees-inedites',
    component: lazy(() => import('./202311NoelFrere'))
};

export const secretSanta202311: Article = {
    title: 'Secret Santa\u00A0: 15 Idées Cadeaux À Moins De 10 euros',
    description: 'Découvrez nos meilleures idées cadeaux à moins de 10 euros pour Secret Santa. De quoi faire plaisir à vos collègues sans vous ruiner',
    date: new Date('2023-11-19'),
    photo: photoSecretSanta202311,
    photoAlt: 'Des collègues s\'échangent des cadeaux',
    route: 'secret-santa-15-idees-cadeaux-a-moins-de-10-euros',
    component: lazy(() => import('./202311SecretSanta'))
};

export const noelSecret202312: Article = {
    title: 'Idée Cadeau Père Noël Secret\u00A0: 15 Inspirations',
    description: 'Découvrez notre sélection d\'idées cadeaux de père Noël secret. Objectif\u00A0: faire plaisir et surprendre votre destinataire\u00A0!',
    date: new Date('2023-12-01'),
    photo: photoPereNoelSecret202312,
    photoAlt: 'Une pile de cadeaux emballés sous le sapin',
    route: 'idee-cadeau-pere-noel-secret-15-inspirations',
    component: lazy(() => import('./202312PereNoelSecret'))
};

export const noelCopine202312: Article = {
    title: 'Idée Cadeau De Noël Pour Sa Copine\u00A0: Tout Pour La Combler',
    description: 'Découvrez notre sélection irrésistible d\'idées cadeaux de Noël pour votre copine. Un seul objectif\u00A0: la surprendre et la combler de bonheur\u00A0!',
    date: new Date('2023-12-02'),
    photo: photoNoelCopine202312, 
    photoAlt: 'Une femme apprécie le cadeau de Noël qu\'elle vient de recevoir',
    route: 'idee-cadeau-noel-copine-tout-pour-la-combler',
    component: lazy(() => import('./202312NoelCopine'))
};

export const noelSoeur202312: Article = {
    title: 'Cadeau de Noël Soeur\u00A0: Les Meilleures Idées Sont Ici\u00A0!',
    description: 'Avec notre sélection de cadeaux de Noël pour votre sœur, impossible de vous tromper. Place à la surprise et au plaisir de recevoir\u00A0! ',
    date: new Date('2023-12-11'),
    photo: photoNoelSoeur202312, 
    photoAlt: 'Deux femmes sourient et tiennent chacun un ruban de cadeau',
    route: 'cadeau-noel-soeur-les-meilleures-idees-sont-ici',
    component: lazy(() => import('./202312NoelSoeur'))
};

export const noelNounou202312: Article = {
    title: 'Cadeau Nounou Noël\u00A0: La Sélection Ultime',
    description: 'Découvrez les meilleurs cadeaux de Noël à offrir à votre nounou pour lui montrer toute votre affection\u00A0!',
    date: new Date('2023-12-11'),
    photo: photoNoelNounou202312, 
    photoAlt: 'Un paquet cadeau change de mains.',
    route: 'cadeau-nounou-noel-la-selection-ultime',
    component: lazy(() => import('./202312NoelNounou'))
};

const articles: Article[] = [
    noelNounou202312,
    noelSoeur202312,
    noelCopine202312,
    noelSecret202312,
    secretSanta202311,
    noelFrere202311,
    noelMaman202311,
    noelAmis202311,
    noelUtile202310,
    noelPapa202310,
    noelPasCher202212,
    noelParents202212,
    noelHomme202211,
    noelCouple202211,
    noelTop7202211,
    noelFemme202211,
    coupleUnAn202210,
    meilleurAmi202210,
    diner202209,
    mariage202209,
    femme202208,
    collegue202208,
    homme202207,
    cagnottes202207,
    noel2021,
    bonCadeau,
];

export function getRoute(article: Article) {
    return `/articles/${article.route}.html`;
}

export default articles;