import { Action } from '../../../actions';
import * as ci from '../../../actions/contrib/ideas/add'
import { ContribMerchantIdeasState, ContribGenericIdeasState } from '../../../store';

const defaultGenericState: ContribGenericIdeasState = {
    idea: {
        ageMax: 71,
        ageMin: 18,
        content: '',
        priceMinCents: 2000,
        priceMaxCents: 5000,
    }
};

const contribGenericIdeasReducer = (state: ContribGenericIdeasState = defaultGenericState, action: Action = {type: ''}): ContribGenericIdeasState => {
    switch (action.type) {
        case ci.SET_CONTENT:
            return {...state, idea: {...state.idea, content: action.content}};
        case ci.SET_AGE:
            const key = action.min ? 'ageMin' : 'ageMax';
            return {...state, idea: {...state.idea, [key]: action.age}};
        case ci.SET_PRICE:
            return {...state, idea: {...state.idea, priceMinCents: action.priceMinCents, priceMaxCents: action.priceMaxCents}};
        default:
            return state;
    }
};

const defaultMerchantState = {
    url: ''
};

const contribMerchantIdeasReducer = (state: ContribMerchantIdeasState = defaultMerchantState, action: Action = {type: ''}): ContribMerchantIdeasState => {
    switch (action.type) {
        case ci.SET_URL:
            return {...state, url: action.url};
        default:
            return state;
    }
};

export {contribGenericIdeasReducer, contribMerchantIdeasReducer};