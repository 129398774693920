import * as React from 'react';
import {ComponentType, ComponentSpec} from 'react';
import createReactClass from 'create-react-class';
import { Subtract, getDisplayName } from '../util/HOC';

export type PageSize = {
    x: number,
    y: number,
}

export type State = {
    pageSize: PageSize,
    devicePixelRatio: number,
    dpi: number,
}

export type PropsFromResponsive = {
    pageSize: PageSize,
    devicePixelRatio: number,
    dpi: number,
}

// Render the gathered device information for debug
const showDebug = false;

export const getPageSize = (): PageSize => {
    return {x: window.innerWidth, y: window.innerHeight};
};

export const getDevicePixelRatio = (): number => {
    return window.devicePixelRatio;
};

export const getDpi = (): number => {
    let highestMatching = 0;
    let lowestNotMatching = 2000;
    while (true) {
        let test = Math.floor((highestMatching + lowestNotMatching) / 2);
        if (matchMedia(`(min-resolution: ${test}dpi)`).matches) {
            highestMatching = test;
        } else {
            lowestNotMatching = test;
        }
        if (highestMatching === lowestNotMatching - 1) {
            return highestMatching;
        }
    }
}

const noopResizeListener = () => {};

function wrapResponsive<P extends object>(Component: ComponentType<P>) : ComponentType<Subtract<P, PropsFromResponsive>>{
    let resizeListener = noopResizeListener;
    const displayName = getDisplayName(Component);
    const spec: ComponentSpec<Subtract<P, PropsFromResponsive>, State> = {
        displayName: `ResponsiveHOC(${displayName})`,

        // Business Logic

        // LifeCycle

        getInitialState() {
            if (resizeListener === noopResizeListener) {
                resizeListener = spec.resizeListener.bind(this);
            }
            const state = {pageSize: getPageSize(), devicePixelRatio: getDevicePixelRatio(), dpi: getDpi()};
            return state;
        },

        componentDidMount() {
            this._isMounted = true;
            if (resizeListener === noopResizeListener) {
                resizeListener = spec.resizeListener.bind(this);
            }
            window.addEventListener('resize', resizeListener);
        },
        componentWillUnmount() {
            this._isMounted = false;
            window.removeEventListener('resize', resizeListener);
            resizeListener = noopResizeListener;
        },

        // Event handling

        resizeListener() {
            this.setState({pageSize: getPageSize()});
        },

        // Rendering
        render() {
            const child = <Component key="main" {...this.props} pageSize={this.state.pageSize} devicePixelRatio={this.state.devicePixelRatio} dpi={this.state.dpi}/>;
            if (showDebug) {
                const debugInfo = <div key="debug-info" id="responsive-debug-info" style={{position: 'absolute', top: 15, left: 15, opacity: 0.8, backgroundColor: '#000', fontFamily: 'monospace', padding: 10}}>
                Responsive
                <ul style={{listStyleType: 'none', color: '#555'}}>
                    <li>Width: {this.state.pageSize.x}</li>
                    <li>Height: {this.state.pageSize.y}</li>
                    <li>Pixel Ratio * 100: {Math.floor(100 * this.state.devicePixelRatio)}</li>
                    <li>DPI: {this.state.dpi}</li>
                </ul>
                </div>
                return [debugInfo, child]
            } else {
                return child;
            }
        }
    };
    return createReactClass<Subtract<P, PropsFromResponsive>, State>(spec);
};

export default wrapResponsive;