import { SearchEnvironment } from "../../model/search";
import routes from "../../routes";
import { PageSize } from "../ResponsiveHOC";

export function extractMetadataFromLocation() {
    const location = window.location;
    // Indicate in the location we are in a square search, even in the squares in testui
    const pathPart = window.location.pathname === routes.square.full || window.location.pathname === routes.admin.testUi ? routes.square.full : '';
    return pathPart + location.search + location.hash;
}

export function createEnvironment(pageSize: PageSize, dpi: number): SearchEnvironment {
    return {
        pw: pageSize.x,
        ph: pageSize.y,
        dpi,
        ua: navigator.userAgent,
        uv: 'acf03d98',
    }
}