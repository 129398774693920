import * as React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import { French } from '../base/Locale';
import { getStartStateFromLocation } from './KeywordLink';

const FooterContent: React.FC<{}> = () => {
    const state = getStartStateFromLocation();
    const squareP = <p>
        Si tu as déjà une bonne idée des goûts et loisirs de cette personne tu peux chercher un cadeau directement <Link to={routes.square.empty}>ici</Link>.
        Sinon laisse-toi guider par mes questions, elles peuvent t'inspirer et explorer des pistes que tu n'avais pas envisagées.
    </p>;
    const seoP = <p>
        Nebuly connait des milliers d'idées de cadeaux provenant de nombreux marchands. Si la personne à qui tu dois offrir ce cadeau a déjà tout, 
        est difficile, ou même s'il te faut ce cadeau pour dans une heure, Nebuly va t'aider à trouver le bon cadeau. 
        Pour les sportifs, les fans de bière, d'Harry Potter, les maniaques, les paresseux, les fans de chat, Nebuly trouve chaussure à tout pied!
    </p>;
    if (state.occasion === 'christmas') {
        return <French>
        <h2>Nebuly, ton moteur de recherche de cadeaux de Noël</h2>
        <p>
            Besoin d'offrir un cadeau de Noël&nbsp;? Nebuly va t'aider en te posant des questions sur cette personne et votre relation.
            Avec tes réponses Nebuly te proposera le <strong>cadeau idéal</strong> à mettre sous le sapin.
        </p>
        <br />
        <p>
            Que ce soit pour ton copain, ta femme, ton père ou un lointain cousin, Nebuly te posera les bonnes questions pour mieux le ou la 
            cerner et proposer le cadeau adéquat.
        </p>
        <br/>
        {squareP}
        <br />
        {seoP}
    </French>
    }
    return <French>
        <h2>Nebuly, ton moteur de recherche de cadeaux</h2>
        <p>
            Besoin d'offrir un cadeau à quelqu'un ? Nebuly va t'aider en te posant des questions sur cette personne et votre relation.
            Avec tes réponses Nebuly te proposera le <strong>cadeau idéal</strong>.
        </p>
        <br />
        <p>
            Que ce soit pour ton copain, ta femme, ton père, un lointain cousin ou un collègue, Nebuly te posera les bonnes questions pour mieux le ou la 
            cerner et proposer le cadeau adéquat.
        </p>
        <br/>
        {squareP}
        <br />
        {seoP}
    </French>;
}

export default FooterContent;