import * as React from 'react';
import {Link} from 'react-router-dom';

import routes from '../../routes';
import { French } from '../base/Locale';
import FullLogo from '../FullLogo';
import { useSimpleTranslation } from '../hooks/Translator';

import "./Home.scss";

const About: React.FC<{}> = () => {
    const t = useSimpleTranslation();
    return <div id="about-root" className="centered-column">
        <Link to={routes.home}><FullLogo/></Link>
        <h1>{t.about.header}</h1>
        <French>
            <p>
                Nebuly, c'est l'histoire d'un ingénieur et d'un nuage (oui, vous avez bien lu)
            </p>    

            <h2>Tout commence dans la tête d'un ingénieur</h2>
            <p>
                Formé à Centrale et à l'Imperial College de Londres, Olivier est expert en "big data" (traitement de gros volumes de données) et en intelligence artificielle. 
                Sa particularité ? Il adore scruter son environnement et chercher des solutions aux problèmes qu'il rencontre, mais aussi à ceux des autres. Ça, c'est bien un ingénieur nous direz-vous !
            </p>

            <h2>À chaque Noël, son lot de chaussettes et d'écharpes</h2>
            <p>
                Olivier est paré contre le froid grâce aux chaussettes et aux écharpes que ses grands-parents lui offrent à chaque Noël. 
                Malgré la magie des moments passés avec son entourage, cette période est synonyme de stress et d'indécision pour lui : quels cadeaux offrir à sa (grande) famille ? Quoi offrir à des personnes qui ne savent pas elles-mêmes ce qui leur ferait plaisir ?
            </p>

            <h2>Trouver LE cadeau parfait, un véritable casse-tête !</h2>
            <p>
                Que celui qui n'a jamais ressenti de l'angoisse (et autres sentiments négatifs) dans une recherche de cadeau nous jette la première pierre. 
                Eh oui, dénicher le cadeau adapté pour ses amis, sa famille, son mari ou sa femme peut être un exercice périlleux. Et pas seulement à Noël ! Il y a aussi la Saint-Valentin, la fête des Pères, la fête des Mères, les anniversaires et autres occasions spéciales. 
            </p>
            <p>
                À chaque fois, il faut faire preuve de créativité et d'imagination. Et puis, il faut savoir se renouveler et anticiper les besoins de l'autre. Un vrai casse-tête qui transforme le plaisir d'offrir en un défi redouté. Car, selon Olivier, on ne se pose pas toujours les bonnes questions quand on cherche un cadeau. Du point de vue de l'ingénieur, il y avait-là un problème à résoudre et une solution technique devait être trouvée.
            </p>

            <h2>L'intelligence artificielle pour retrouver le goût d'offrir : Nebuly voit le jour</h2>
            <p>
                Olivier souhaite « rendre la vie plus simple et aider celui qui offre et celui qui reçoit ». Exit les complications, les doutes et le stress ! 
                Il faut redonner à l'acte d'offrir toute sa dimension de plaisir.
            </p>
            <p>
                Alors, comment faire ? Il décide d'utiliser ce qu'il maîtrise le mieux : l'intelligence artificielle et le “big data” pour créer un générateur de cadeaux à la fois intuitif et sophistiqué. Mais il ne s'agit pas de concevoir un automate qui propose toujours les mêmes idées cadeaux. Vous savez, celles que l'on voit partout et qui plaisent à tout le monde. Non, cette fois-ci, il allait développer un outil pour trouver le cadeau parfait avec l'aide de celui qui offre. Un cadeau unique et totalement personnalisé, basé sur une succession intelligente de questions précises.
            </p>
            <p>
                Olivier analyse et croise des données, il met au point des algorithmes qui répondent à une liste (presque) infinie de critères précis. Il crée une interface intuitive pour une expérience axée à 100&nbsp;% sur le plaisir d'offrir. En parallèle, il s'associe avec des marques qui proposent des cadeaux de qualité, aussi variés qu'originaux. Après plusieurs années de travail, Nebuly est prêt à accompagner ceux qui sont à la recherche d'idées cadeaux uniques pour leurs proches.
            </p>

            <h2>Et Nebuly, ça vient d'où ?</h2>
            <p>
                Nebuly vient des nébuleuses. C'est le nom que l'on donnait à l'époque aux objets célestes qui étaient tellement flous qu'on ne pouvait pas les identifier. 
                Il aura fallu les observer avec des instruments adaptés pour découvrir qu'il s'agissait de nuages composés de poussière interstellaire et de gaz. 
                Au début, l'idée cadeau est tout aussi floue qu'une nébuleuse. À la manière d'un télescope très précis, Nebuly permet d'y voir plus clair et d'identifier le cadeau parfait. 
            </p>
        </French>
        <p className="p-wrap"></p>
        <Link to={routes.contrib.home}><h2>{t.about.contributeHeader}</h2></Link>
        <p className="p-wrap">{t.about.contributePresentation}</p>
        <div className="about-footer">
            <div><Link to={routes.about.legal}>{t.about.legal.linkLabel}</Link></div>
            <div><Link to={routes.about.privacyPolicy}>{t.about.privacyPolicy.linkLabel}</Link></div>
        </div>
    </div>;
};

export default About;