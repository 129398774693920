import * as React from 'react';
import Button from '../../base/Button';
import share from '../../../images/share/native.svg';
import SharePopUp from '../square/ShareDialog';
import If from '../../base/If';
import { useSimpleTranslation } from '../../hooks/Translator';
import { SharePayload } from '../../../redux/actions/share';

const untypedNavigator = navigator as any; // navigator.share is unknown to TS

// Shares an URL, either with the native UI on a mobile phone or a popup on desktop.
const ResponsiveShare: React.FC<{
    title: string,
    text: string,
    url: string,
    disabled?: boolean,
}> = ({url, title, text, disabled}) => {
    const [useNativeShare, setUseNativeShare] = React.useState<boolean>(false);
    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    const t = useSimpleTranslation();
    React.useEffect(() => {
        const canUseNativeShare = untypedNavigator.share !== undefined;
        setUseNativeShare(canUseNativeShare);
    }, [setUseNativeShare]);
    if (useNativeShare) {
        const payload: SharePayload = {
            title,
            text,
            url,
        };
        return <div onClick={() => untypedNavigator.share(payload)} className="share">
            <img src={share} className="share" alt={t.search.share.linkToLabel}/>
        </div>;

    } else {
        return <Button type="primary" filled small label="" className="share" onClick={() => {
            setShowDialog(true)
            }} disabled={disabled}>
            <img src={share} className="share" alt={t.search.share.linkToLabel}/>&nbsp;{t.search.share.linkToLabel}
            <If test={showDialog}>
                <SharePopUp url={url} close={() => setShowDialog(false)}/>
            </If>
        </Button>
    }
};

export default ResponsiveShare;